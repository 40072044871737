import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Cleave from 'cleave.js/react';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { OverlayPanel } from 'primereact/overlaypanel';

export class FormReglSortie extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            idSortie: null,
            ridCli: null,
            ridBoutiq: null,
            rnum_sortie: '',
            idrglSortie: '',
            liste_data: [],
            liste_Sortie_for_reglement: [],
            liste_client: [],
            lboutique_rech: [],
            selectedData: null,
            modeRgl: { label: 'Espèce', value: 'Espèce' },
            refRgl: '',
            daterglSortie: new Date(),
            showindicator: false ,
            mtrglSortie: 0,
            montant_total: '0',
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
                { label: 'Compte', value: 'Compte' },
                { label: 'Orange Money', value: 'Orange Money' },
                { label: 'Momo', value: 'Momo' },
            ],
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            rfiltreClient: 'COMPTOIR'
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.xulaservice = new Xulaservice();
    }

    sortieItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label>{option.nomCli}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ fontWeight: 'bold', color: 'red' }}>{option.mt_restant_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        this.get_liste_boutique();
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            } else {
                this.setState({ rfiltreClient: 'COMPTOIR' }, () => {
                    this.get_liste_client();
                });
            }
        }
        this.xulaservice.get_liste_sortie_by_criteria('Tous', 'Tous', 'Tous', 'Tous', 'Non regle', 'Tous', 'Tous', 'Tous', 'Tous', 'Tous', 'Facture').then(data => {
            this.setState({ liste_Sortie_for_reglement: data });
        });
        this.get_liste_data();
    }

    get_liste_client() {
        this.setState({ showindicator: true });
        this.xulaservice.getliste_client_light(this.state.rfiltreClient).then(data => {
            this.setState({ liste_client: data, showindicator: false });
        });
    }

    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => {
            if (data !== null && data !== undefined) {
                let lboutique = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                    lboutique_rech = lboutique_rech.concat(lboutique);
                } else {
                    let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                    if (lboutique.length > 0) {
                        let liste = lboutique.filter(function (el) {
                            return el.value === id;
                        });
                        lboutique_rech = lboutique_rech.concat(liste);
                    }
                }
            }
            this.setState({ lboutique_rech: lboutique_rech });
        });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_data() {
        let rid_cli = 'Tous';
        let rid_boutiq = 'Tous';
        this.setState({ showindicator: true });
        let rnum = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');

        if (this.state.ridBoutiq !== undefined && this.state.ridBoutiq !== null) {
            rid_boutiq = this.state.ridBoutiq.value;
        }
        if (this.state.ridCli !== undefined && this.state.ridCli !== null) {
            rid_cli = this.state.ridCli.value;
        }
        if (this.state.rnum_sortie !== undefined && this.state.rnum_sortie !== null && this.state.rnum_sortie !== '') {
            rnum = this.state.rnum_sortie;
        }
        this.xulaservice.get_liste_regler_sortie(spd1, spd2, rid_cli, 'Tous', rnum, rid_boutiq).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtrglSortie
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ idrglSortie: data.idrglSortie });
            this.setState({ daterglSortie: data.daterglSortie });
            this.setState({ mtrglSortie: data.mtrglSortie });
            this.setState({ modif: true });
            this.setState({ refRgl: data.refRgl });
            this.setState({ modeRgl: { value: data.modeRgl, label: data.modeRgl } });
            this.setState({ selectedData: data });
        }
    }

    annule_data() {
        this.setState({ refRgl: '' });
        this.setState({ daterglSortie: new Date() });
        this.setState({ modif: false });
        this.setState({ mtrglSortie: 0 });
        this.forceUpdate();
    }

    enreg_data() {
        if (this.state.mtrglSortie === '' || this.state.mtrglSortie === undefined || this.state.mtrglSortie === 0 || this.state.daterglSortie === '' || this.state.daterglSortie === undefined || this.state.daterglSortie === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.idSortie === null || this.state.idSortie === undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir la facture à regler' });
                    return;
                }
                if (this.state.idSortie.mt_restant < parseFloat(this.state.mtrglSortie)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                    return;
                }
                let date = moment(this.state.daterglSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (this.state.userconnected.profilUser != 'PDG') {
                    date = moment(new Date()).format('DD/MM/YYYY');
                }
                let data = { idrglSortie: '0', refRgl: this.state.refRgl, mtrglSortie: this.state.mtrglSortie, modeRgl: this.state.modeRgl.value, daterglSortie: date, statutSyncRegl: this.xulaservice.get_statut_sync(), idSortie: { idSortie: this.state.idSortie.value } };
                this.xulaservice.enreg_regl_sortie(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                if (this.state.selectedData.mtrglSortie < parseFloat(this.state.mtrglSortie)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi ne peut etre supérieur à celui déja enregistré' });
                    return;
                }
                let date = moment(this.state.daterglSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedData;
                data.daterglSortie = date;
                data.mtrglSortie = this.state.mtrglSortie;
                data.modeRgl = this.state.modeRgl.value;
                data.refRgl = this.state.refRgl;
                this.xulaservice.enreg_regl_sortie(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    conf_print_recu(data) {
        this.xulaservice.generer_edition_recu(data.idrglSortie);
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 120 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_print_recu(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" />
            </div>
        </div>
    }


    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_regl_sortie(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_data();
        }
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let lclient_rech = [{ value: 'Tous', label: 'Client', soldeCli: 0, label_rech: 'Tous 0' }];

        if (this.state.liste_client != null && this.state.liste_client != undefined) {
            let lcient = this.state.liste_client.map(x => {
                return { value: x.idCli, label: x.nomCli, soldeCli: x.soldeCli, label_rech: x.nomCli + ' ' + x.idCli }
            });
            lclient_rech = lclient_rech.concat(lcient);
        }

        let lSortie = null;
        if (this.state.liste_Sortie_for_reglement != null && this.state.liste_Sortie_for_reglement != undefined) {
            lSortie = this.state.liste_Sortie_for_reglement.map(x => {
                return { value: x.idSortie, label: x.numFactureSortie, nomCli: x.idCli.nomCli, mt_restant: x.mt_restant, mt_restant_format: x.mt_restant_format }
            });
        }
        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '250px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warn" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '30px' }} />
                        </div>
                    </div>
                </OverlayPanel>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomCli">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar disabled={this.state.userconnected.profilUser != 'PDG'} value={this.state.daterglSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglSortie: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Facture *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lSortie} placeholder="Sélectionner" value={this.state.idSortie} panelStyle={{ width: '500px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.sortieItemTemplate} filterMatchMode='contains'
                                        onChange={(e) => {
                                            this.setState({ daterglSortie: new Date() });
                                            this.setState({ idSortie: e.value }, () => {
                                                this.setState({ mtrglSortie: this.state.idSortie.mt_restant });
                                            })
                                        }
                                        } autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.mtrglSortie} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtrglSortie: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Mode reglement </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ modeRgl: e.value }) }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Référence reglement</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" value={this.state.refRgl} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>


                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_data()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rnum_sortie} placeholder="N° facture" onChange={(e) => this.setState({ rnum_sortie: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid">
                                                <div className="p-col-11">
                                                    <Dropdown id="new_dp_client"
                                                        options={lclient_rech}
                                                        value={this.state.ridCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridCli: e.value }, () => {
                                                                this.get_liste_data();
                                                            })
                                                        }}
                                                        style={{ width: '100%' }} panelStyle={{ width: '400px' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data}
                                responsive={true} paginatorPosition="top" paginator={true} rows={15} selectionMode="single"
                                onRowClick={e => this.setState({ selectedData: e.data })} paginatorRight={ptotal}
                                 selection={this.state.selectedData}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="daterglSortie" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtrglSortie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtrglSortie)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idSortie.numFactureSortie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.idSortie.numFactureSortie}</span>
                                        <br />
                                        <span style={{ color: '#000000' }}>{rowData.idSortie.dateSortie}</span>
                                    </div>
                                }} header="Facture" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idSortie.idCli.nomCli" header="Client" style={{ width: '21%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idSortie.idCli.typeCli" header="Type" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="modeRgl" header="Mode" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="refRgl" header="Référence" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idSortie.idBoutiq.nomBoutiq" header="Boutique" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}