import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import Numeral, { nullFormat } from 'numeral';
import Cleave from 'cleave.js/react';
import { FormOptArticle } from './FormOptArticle';
import { components, createFilter } from 'react-windowed-select';
import WindowedSelect from "react-windowed-select";
import { OverlayPanel } from 'primereact/overlaypanel';

export class FormCommande extends Component {
    constructor() {
        super();
        this.state = {
            idCmde: 0,
            libArticle: 'Séléctionner un article',
            numCmde: '',
            dateCmde: new Date(),
            dateReceptionCmde: new Date(),
            delaiRglCmde: new Date(),
            cmtCmde: '',
            idLcmde: 0,
            remiseCmde: 0,
            tvaCmde: 0,
            qteReapLcmde: 1,
            qteLcmde: 0,
            remiseLcmde: 0,
            puvMajLcmde: 0,
            purMajLcmde: 0,
            puLcmde: 0,
            tvaLcmde: 0,
            statutLcmde: { value: 'Recevable', label: 'Recevable' },
            idFour: null,
            num_ligne: 0,
            mtrglCmde: 0,
            selectedReglCmde: null,
            montant_total: 0,
            montant_total_format: '0',
            mtapayer_format: '0',
            remise_total_format: '0',
            mtTVA: '0',
            montant_brut_format: '0',
            mtapayer: 0,
            fraisCharge: 0,
            fraisPerte: 0,
            total_restant: '0',
            total_reglement_format: '0',
            modeRgl: { label: 'Espèce', value: 'Espèce' },
            refRgl: '',

            daterglCmde: new Date(),
            liste_regler_cmde: [],
            liste_regler_cmde_bloc: [],
            liste_cmde_for_reglement_selecteds: [],
            liste_cmde_for_reglement: [],
            liste_fournisseur: [],

            lfournisseur_rech: [],
            lfournisseur: [],
            lprod: [],
            lboutique: null,
            lboutique_rech: [{ value: 'Tous', label: 'Boutique' }],

            pd1: new Date(),
            pd2: new Date(),
            dateExpLcmde: null,
            rnum_cmde: '',
            statutCmde: null,
            etatCmde: null,
            idArticle: null,
            idArticle_opt: null,
            idBoutiq: null,
            typeCmde: null,
            rtypeCmde: null,
            rtypeFour: null,
            ridBoutiq: null,
            ridFour: null,
            rstatutCmde: null,
            liste_cmde: [],
            liste_lcmde: [],
            liste_boutique: [],
            liste_article: [],
            liste_ligne: [],
            liste_ligne_validation: [],
            liste_trace: [],
            selectedCmde: null,
            selectedLcmde: null,
            selectedLigne: null,
            selectedLigne_validation: null,
            msg: '',
            modif: false,
            modif_lcmde: false,
            visible: false,
            visible_lcmde: false,
            visible_dlg_article: false,
            visible_new_cmde_dlg: false,
            visible_dlg_cmde: false,
            visible_dlg_lcmde: false,
            visible_dlg_validation: false,
            visible_dlg_reglCmde: false,
            visible_dlg_trace: false,
            modif_regl_cmde: false,
            showindicator: false,
            disable_new_cmde: true,
            disable_add_prod: true,
            disable_val_cmde: true,
            disable_prod: true,
            visible_dlg_reglement: false,
            liste_type_fournisseur: [
                { label: 'Type fournisseur', value: 'Tous' },
                { label: 'INTERNE', value: 'INTERNE' },
                { label: 'EXTERNE ', value: 'EXTERNE' },
            ],
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' }
            ],
            liste_statut_lcmde: [
                { label: 'Recevable', value: 'Recevable' },
                { label: 'Non commandé', value: 'Non commandé' },
                { label: 'Perimé', value: 'Perimé' },
                { label: 'Défectueux', value: 'Défectueux' }
            ],
            liste_statut_reglement: [
                { label: 'Reglement', value: 'Tous' },
                { label: 'Reglé', value: 'Regle' },
                { label: 'Non reglé', value: 'Non regle' }
            ],
            liste_type_cmde: [
                { label: 'Type', value: 'Tous' },
                { label: 'Facture', value: 'Facture' },
                { label: 'Transfert', value: 'Transfert' },
                { label: 'Retour', value: 'Retour' }
            ],
            rfiltreArticle: '',
            montant_paye_format: '0',
            montant_restant_format: '0',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.state.delaiRglCmde.setDate(this.state.dateCmde.getDate() + 45);

        this.show_dlg_reglement = this.show_dlg_reglement.bind(this);

        this.edit_cmde = this.edit_cmde.bind(this);
        this.conf_delete_cmde = this.conf_delete_cmde.bind(this);

        this.edit_lcmde = this.edit_lcmde.bind(this);
        this.conf_delete_lcmde = this.conf_delete_lcmde.bind(this);

        this.show_new_cmde_dlg = this.show_new_cmde_dlg.bind(this);
        this.show_lcmde_dlg = this.show_lcmde_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.conf_delete_reglCmde = this.conf_delete_reglCmde.bind(this);
        this.edit_reglCmde = this.edit_reglCmde.bind(this);

        this.qteReapLcmdeEditor = this.qteReapLcmdeEditor.bind(this);
        this.statutLcmdeEditor = this.statutLcmdeEditor.bind(this);

        this.puLcmdeEditor = this.puLcmdeEditor.bind(this);
        this.qteLcmdeEditor = this.qteLcmdeEditor.bind(this);
        this.puvMajLcmdeEditor = this.puvMajLcmdeEditor.bind(this);

        this.show_dlg_article = this.show_dlg_article.bind(this);

        this.conf_print_bc = this.conf_print_bc.bind(this);

        this.articleItemTemplate = this.articleItemTemplate.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);

        this.myservice = new Xulaservice();
    }

    get_type_cmde() {
        let val = 'Facture';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            if (this.props.match.params.typeOp === 'Nouvelle commande' || this.props.match.params.typeOp === 'Gestion commande') {
                val = 'Facture';
            } else {
                val = 'Retour';
            }
        }
        return val;
    }

    show_dlg_article() {
        if (this.state.idBoutiq === null || this.state.idBoutiq === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une boutique' });
            return;
        }
        if (this.state.idArticle !== null && this.state.idArticle !== undefined) {
            let art = this.state.idArticle;
            if (art === null || art === undefined) {
                art = { value: '0', label: '', cbArt: '', qte_stock_format: '0', prix_ref_vente_format: '0', prix_ref_vente: 0, qte_stock: 0, prix_ref_achat: 0, prix_ref_achat_format: '0', idstype: '0', libsoustypearticle: '0' }
            }
            art.idBoutiq = this.state.idBoutiq.value;
            let param_opt = { idArticle: art, lprod: [this.state.idArticle] };
            this.setState({ idArticle_opt: param_opt }, () => {
                this.setState({ visible_dlg_article: true });
            });
        } else {
            this.setState({ visible_dlg_article: true });
        }

    }

    get_liste_regler_cmde(spd1, spd2, rid_four, rid_cmde) {
        this.myservice.get_liste_regler_cmde(spd1, spd2, rid_four, rid_cmde, 'Tous').then(data => this.setState({ liste_regler_cmde: data }));
    }


    articleTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-9">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.ref}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label style={{ fontWeight: 'bold' }}>{option.stock}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    qteLcmdeEditor(props) {
        return this.inputTextEditor(props, 'qteLcmde');
    }
    puLcmdeEditor(props) {
        return this.inputTextEditor(props, 'puLcmde');
    }

    statutLcmdeEditor(props) {
        return this.inputTextEditor(props, 'statutLcmde');
    }

    qteReapLcmdeEditor(props) {
        return this.inputTextEditor(props, 'qteReapLcmde');
    }

    puvMajLcmdeEditor(props) {
        return this.inputTextEditor(props, 'puvMajLcmde');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds }, () => {
            this.calculer_total(updateds);
        });
    }

    inputTextEditor(props, field) {
        let disabled = false;
        if (this.get_type_cmde() === 'Retour') {
            if (field === 'puvMajLcmde' || field === 'qteReapLcmde') {
                disabled = true;
            }
        }
        return <Cleave disabled={disabled} value={props.rowData[field]} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.onEditorValueChange(props, e.target.rawValue)} className="p-inputtext" />
    }


    resultat_reglCmde(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reglCmde();
            this.get_liste_cmde();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    annule_reglCmde() {
        this.setState({ idrglCmde: '0' });
        this.setState({ daterglCmde: '' });
        this.setState({ refRgl: '' });
        this.setState({ modif_regl_cmde: false });
        this.forceUpdate();
    }

    show_dlg_reglement() {
        let liste = [];
        if (this.state.liste_cmde.length > 0) {
            liste = this.state.liste_cmde.filter(function (el) {
                return el.mt_restant > 0;
            })
        }
        this.setState({ liste_cmde_for_reglement: liste }, () => {
            this.setState({ visible_dlg_reglement: true });
        });
    }

    get_liste_cmde_for_reglement() {
        let rid_four = 'Tous';
        let rid_boutique = 'Tous';
        let rtype_four = 'Tous';
        let rtype_cmde = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.rtypeFour != undefined && this.state.rtypeFour != null) {
            rtype_four = this.state.rtypeFour.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }
        if (this.state.rtypeCmde != undefined && this.state.rtypeCmde != null) {
            rtype_cmde = this.state.rtypeCmde.value;
        }
        this.myservice.get_liste_cmde_by_criteria(spd1, spd2, 'Tous', rid_boutique, 'Non regle', rid_four, rtype_cmde, rtype_four, 'DATE_CMDE').then(data => this.setState({ liste_cmde_for_reglement: data, showindicator: false }));
    }

    resultat_rgl_bloc(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_cmde_for_reglement();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    annule_reglCmde_bloc() {
        this.setState({ daterglCmde: '' });
        this.setState({ visible_dlg_reglement: false });
        this.setState({ mtrglCmde: 0 });
        this.setState({ montant_total: '0' });
    }

    enreg_reglCmde_bloc() {
        if (this.state.liste_cmde_for_reglement_selecteds === null || this.state.liste_cmde_for_reglement_selecteds === undefined || this.state.daterglCmde === '' || this.state.daterglCmde === undefined || this.state.daterglCmde === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner les factures à régler' });
        } else {
            if (this.state.liste_cmde_for_reglement_selecteds.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures à régler' });
                return;
            }
            this.setState({ showindicator: true });
            let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            this.myservice.regler_cmde_bloc(this.state.liste_cmde_for_reglement_selecteds, this.state.modeRgl.value, this.state.refRgl, date, this.myservice.get_statut_sync()).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    if (reponse.code === 200) {
                        this.setState({ total_reglement_format: '0' });
                        this.setState({ liste_cmde_for_reglement_selecteds: [] });
                        this.get_liste_cmde_for_reglement();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reponse.contenu });
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: reponse.contenu });

                    }
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                }
                this.setState({ showindicator: false });
            });
        }
    }

    enreg_reglCmde() {
        if (this.state.selectedCmde === null || this.state.selectedCmde === undefined || this.state.daterglCmde === '' || this.state.daterglCmde === undefined || this.state.daterglCmde === null || this.state.mtrglCmde === undefined || this.state.mtrglCmde === null || this.state.mtrglCmde === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        }
        else {

            if (this.state.modif_regl_cmde === false) {
                if (this.state.selectedCmde.mtrest < parseFloat(this.state.mtrglCmde)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                    return;
                }
                let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idrglCmde: '0', statutSyncRgl: this.myservice.get_statut_sync(), mtrglCmde: this.state.mtrglCmde, daterglCmde: date, modeRgl: this.state.modeRgl.value, refRgl: this.state.refRgl, idCmde: { idCmde: this.state.selectedCmde.idCmde, idFour: { idFour: this.state.selectedCmde.idFour.idFour } } };

                this.myservice.enreg_regler_cmde(data).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        this.resultat_reglCmde(reponse.code, reponse.contenu);
                        this.get_liste_regler_cmde('Tous', 'Tous', 'Tous', this.state.selectedCmde.idCmde);
                    }
                });
            } else {
                if (this.state.selectedReglCmde.mtrglCmde < parseFloat(this.state.mtrglCmde)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                } else if (this.state.selectedCmde.mtrest < (parseFloat(this.state.mtrglCmde) - this.state.selectedReglCmde.mtrglCmde)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                } else {
                    let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                    let data = this.state.selectedReglCmde;
                    data.mtrglCmde = this.state.mtrglCmde;
                    data.daterglCmde = date;
                    data.refRgl = this.state.refRgl
                    data.modeRgl = this.state.modeRgl.value;
                    data.statutSyncRgl = this.myservice.get_statut_sync();
                    this.myservice.enreg_regler_cmde(data).then(reponse => {
                        if (reponse != null && reponse != undefined) {
                            this.resultat_reglCmde(reponse.code, reponse.contenu);
                            this.get_liste_regler_cmde('Tous', 'Tous', 'Tous', this.state.selectedCmde.idCmde);
                        }
                    });
                }
            }
        }
    }

    conf_delete_reglCmde(rowData) {
        let data = { idrglCmde: rowData.idrglCmde };
        this.myservice.delete_regler_cmde(data).then(data => {
            this.resultat_reglCmde(data.code, data.contenu);
            this.get_liste_regler_cmde('Tous', 'Tous', 'Tous', rowData.idCmde.idCmde);
        });
    }

    edit_reglCmde(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_regl_cmde: true }, () => {
                this.setState({ daterglCmde: data.daterglCmde });
                this.setState({ refRgl: data.refRgl });
                this.setState({ mtrglCmde: data.mtrglCmde });
                this.setState({ modeRgl: { value: data.modeRgl, label: data.modeRgl } });
            });
        }
    }

    show_dlg_reglCmde(rowData) {
        this.myservice.get_liste_regler_cmde('Tous', 'Tous', 'Tous', rowData.idCmde).then(data => {
            this.setState({ liste_regler_cmde: data }, () => {
                this.setState({ visible_dlg_reglCmde: true }, () => {
                    this.setState({ mtrglCmde: this.state.selectedCmde.mtrest });
                });
            });
        });
    }

    actionTemplate_reglCmde(rowData, column) {
        let disable_button_del = false;
        if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncRgl == 'Envoyé') {
            disable_button_del = true;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.edit_reglCmde(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button_del} onClick={(e) => this.conf_delete_reglCmde(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }


    componentDidUpdate(prevProps) {
        if (prevProps.match.params.typeOp !== this.props.match.params.typeOp) {
            this.get_liste_cmde();
            this.setState({ liste_lcmde: [] });
        }
    }


    componentDidMount() {
        if (this.props.match.params.typeOp.includes('Nouvelle') == false) {
            this.get_liste_cmde();
        }
        this.get_liste_boutique();
        this.get_liste_fournisseur();
    }

    get_liste_cmde() {
        let rstatut_cmde = 'Tous';
        let rid_four = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let rnum = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }
        if (this.state.rstatutCmde != undefined && this.state.rstatutCmde != null) {
            rstatut_cmde = this.state.rstatutCmde.value;
        }
        if (this.state.rnum_cmde != undefined && this.state.rnum_cmde != null) {
            rnum = this.state.rnum_cmde;
        }
        this.setState({ montant_total_format: '0' });
        this.myservice.get_liste_cmde_by_criteria(spd1, spd2, rnum, rid_boutique, rstatut_cmde, rid_four, this.get_type_cmde(), 'Tous', 'DATE_CMDE').then(data => this.setState({ liste_cmde: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtcmde
                }, initialValue);

                let tr = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtrest
                }, initialValue);

                let tp = tt - tr;
                this.setState({
                    montant_total_format: this.formaterMontant(tt),
                    montant_paye_format: this.formaterMontant(tp),
                    montant_restant_format: this.formaterMontant(tr),
                });
            }
        }));
    }

    get_liste_lcmde(idCmde) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_lcmde(idCmde).then(data => this.setState({ liste_lcmde: data, showindicator: false }));
    }

    get_liste_boutique() {
        let lboutique = {};
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        if (this.state.userconnected.profilUser == 'Directeur') {
            this.myservice.getListe_boutique_gerer().then(data2 => {
                if (data2 != null && data2 != undefined) {
                    let liste = data2.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    lboutique_rech = lboutique_rech.concat(liste);
                    this.setState({ lboutique_rech: lboutique_rech })
                    this.setState({ lboutique: liste });
                }
            });
        } else {
            this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
                if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                    if (this.get_type_cmde() === 'Retour') {
                        if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur') {
                            let idBoutiq = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                            lboutique = this.state.liste_boutique.filter(function (el) {
                                return el.idBoutiq === idBoutiq;
                            }).map(x => {
                                return { value: x.idBoutiq, label: x.nomBoutiq }
                            });
                        } else {
                            lboutique = this.state.liste_boutique.map(x => {
                                return { value: x.idBoutiq, label: x.nomBoutiq }
                            });
                        }

                    } else {
                        lboutique = this.state.liste_boutique.map(x => {
                            return { value: x.idBoutiq, label: x.nomBoutiq }
                        });
                    }
                    lboutique_rech = lboutique_rech.concat(lboutique);

                    this.setState({ lboutique: lboutique });
                    this.setState({ lboutique_rech: lboutique_rech });
                    this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } }, () => {

                        if (this.myservice.wsurl().indexOf(":9077") > 0) {
                            this.get_liste_article(this.state.idBoutiq.value);
                        } else {
                            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                                this.get_liste_article(this.state.idBoutiq.value);
                            }
                        }

                    });
                }
            }));
        }
    }

    get_liste_fournisseur() {
        let lfournisseur = [];
        let lfournisseur_rech = [{ value: 'Tous', label: 'Fournisseur', typeFour: 'NA', tvaFour: 0 }];
        this.myservice.getListe_fournisseur().then(data => this.setState({ liste_fournisseur: data }, () => {
            if (this.state.liste_fournisseur != null && this.state.liste_fournisseur != undefined) {
                if (this.get_type_cmde() === 'Retour') {
                    let idBoutiq = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                    lfournisseur = this.state.liste_fournisseur.filter(function (el) {
                        return el.refBoutiqFour === idBoutiq;
                    }).map(x => {
                        return { value: x.idFour, label: x.nomFour, typeFour: x.typeFour, tvaFour: x.tvaFour }
                    });
                } else {
                    lfournisseur = this.state.liste_fournisseur.map(x => {
                        return { value: x.idFour, label: x.nomFour, typeFour: x.typeFour, tvaFour: x.tvaFour }
                    });
                }
                lfournisseur_rech = lfournisseur_rech.concat(lfournisseur);
                this.setState({ lfournisseur: lfournisseur });
                this.setState({ lfournisseur_rech: lfournisseur_rech });
                this.setState({ idFour: lfournisseur[0] });
            }
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_cmde();
            this.setState({ liste_lcmde: [] });
            this.get_liste_cmde();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_lcmde(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_lcmde();
            if (this.state.selectedCmde != null && this.state.selectedCmde != undefined) {
                this.get_liste_lcmde(this.state.selectedCmde.idCmde);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    conf_print_bc(data) {
        this.myservice.generer_edition_bc(data.idCmde);
    }

    edit_cmde(data) {
        if (data != null && data != undefined) {
            if (data.typeCmde === 'Transfert' || data.typeCmde === 'Retour') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention vous ne pouvez pas modifier cette commande' });
                return;
            }
            this.setState({ idCmde: data.idCmde });

            this.setState({ fraisCharge: data.fraisCharge });
            this.setState({ tvaCmde: data.tvaCmde });
            this.setState({ dateCmde: data.dateCmde });
            this.setState({ fraisPerte: data.fraisPerte });
            this.setState({ delaiRglCmde: data.delaiRglCmde });
            this.setState({ dateReceptionCmde: data.dateReceptionCmde });

            this.setState({ remiseCmde: data.remiseCmde });
            this.setState({ numCmde: data.numCmde });
            this.setState({ idFour: { value: data.idFour.idFour, label: data.idFour.nomFour, typeFour: data.idFour.typeFour, tvaFour: data.idFour.tvaFour } });
            this.setState({ idBoutiq: { value: data.idBoutiq.idBoutiq, label: data.idBoutiq.nomBoutiq } });
            this.setState({ modif: true });
            this.setState({ visible_dlg_cmde: true });
            this.setState({ selectedCmde: data });
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }


    annule_cmde() {
        this.setState({ idCmde: 0 });
        this.setState({ dateCmde: '' });
        this.setState({ numCmde: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_cmde: false });
        this.setState({ mtrglCmde: 0 });
        this.setState({ fraisCharge: 0 });
        this.setState({ fraisPerte: 0 });

        this.setState({ montant_total: '0' });
        this.setState({ montant_total_format: '0' });
        this.setState({ mtapayer_format: '0' });
        this.setState({ remise_total_format: '0' });
        this.setState({ mtTVA: '0' });
        this.setState({ montant_brut_format: '0' });
        this.setState({ mtapayer: '0' });
        this.setState({ total_restant: '0' });
        this.forceUpdate();
    }

    annule_cmde_dlg() {
        this.annule_cmde();
        this.setState({ liste_ligne: [] });
    }

    annule_lcmde() {
        this.setState({ dateExpLcmde: '' });
        this.setState({ idLcmde: '0' });
        this.setState({ total_restant: '0' });
        this.setState({ montant_brut_format: '0' });
        this.setState({ mtapayer_format: '0' });
        this.setState({ qteReapLcmde: 0 });
        this.setState({ puLcmde: 0 });
        this.setState({ qteLcmde: 0 });
        this.setState({ modif_lcmde: false });
        this.setState({ visible_dlg_lcmde: false });
        this.setState({ liste_ligne: [] });
        window.location.reload();
        this.forceUpdate();
    }


    handleKeyPress = e => {
        if (e.keyCode === 13 && e.ctrlKey) {
            this.enreg_cmde(this.get_type_cmde());
        } else if (e.key === 'u' && e.ctrlKey) {
            this.annule_cmde();
        } else if (e.key === 'a' && e.ctrlKey) {
            this.add_article();
        } else if (e.key === 'k' && e.ctrlKey) {
            document.getElementById("new_dp_prod").click();
        }
    }


    enreg_cmde() {
        if (this.state.dateCmde == "" || this.state.dateCmde == null || this.state.dateReceptionCmde == "" || this.state.dateReceptionCmde == null || this.state.dateReceptionCmde == undefined || this.state.delaiRglCmde == "" || this.state.delaiRglCmde == null || this.state.delaiRglCmde == undefined || this.state.idBoutiq == null || this.state.idBoutiq == undefined || this.state.idFour == null || this.state.idFour == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let typeCmde = this.get_type_cmde();
            let date_cmde = moment(this.state.dateCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let date_reception = moment(this.state.dateReceptionCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let delai = moment(this.state.delaiRglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (this.state.modif == false) {
                let data = { dateCmde: date_cmde, statutSyncCmde: this.myservice.get_statut_sync(), dateReceptionCmde: date_reception, delaiRglCmde: delai, fraisCharge: this.state.fraisCharge, fraisPerte: this.state.fraisPerte, typeCmde: typeCmde, numCmde: this.state.numCmde, idBoutiq: { idBoutiq: this.state.idBoutiq.value }, idFour: { idFour: this.state.idFour.value } };
                data.idCmde = '0';
                if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                }
                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idCmde = data;
                    this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                    this.state.liste_ligne[i].statutSyncLcmde = this.myservice.get_statut_sync();
                    if (parseFloat(this.state.liste_ligne[i].qteReapLcmde) > parseFloat(this.state.liste_ligne[i].qteLcmde)) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à réapprovisionner' });
                        return;
                    }
                    if (parseFloat(this.state.liste_ligne[i].remiseLcmde) > parseFloat(this.state.liste_ligne[i].puLcmde)) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les remises' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                let data_regl = {};
                let md = { liste_ligne_cmde: this.state.liste_ligne };
                if (parseFloat(this.state.mtrglCmde) > 0) {
                    let refRgl = this.state.refRgl;
                    if (this.get_type_cmde() === 'Retour') {
                        refRgl = "Retour article " + refRgl;
                    }
                    data_regl = { idrglCmde: '0', statutSyncRgl: this.myservice.get_statut_sync(), mtrglCmde: this.state.mtrglCmde, modeRgl: this.state.modeRgl.value, refRgl: refRgl }
                    md.reglCmde = data_regl;
                }

                this.myservice.enreg_lcmde_batch(md).then(data => {
                    if (data != null && data != undefined) {
                        this.resultat_lcmde(data.code, data.contenu);
                    }
                    if (this.state.visible_new_cmde_dlg === true) {
                        window.location.reload();
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let date_cmde = moment(this.state.dateCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedCmde;
                data.dateCmde = date_cmde;
                data.statutSyncCmde = this.myservice.get_statut_sync();
                data.dateReceptionCmde = date_reception;
                data.delaiRglCmde = delai;
                data.fraisPerte = this.state.fraisPerte;
                data.numCmde = this.state.numCmde;
                data.fraisCharge = this.state.fraisCharge;
                data.idFour = { idFour: this.state.idFour.value }
                data.idUser = this.state.selectedCmde.idUser;
                this.myservice.enreg_cmde(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    enreg_lcmde() {
        if (this.state.selectedCmde == null || this.state.selectedCmde == undefined || this.state.qteLcmde == '' || this.state.qteLcmde == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else {
            if (this.state.modif_lcmde === false) {
                let date = null;
                try {
                    date = moment(this.state.dateExpLcmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                } catch (error) {
                    console.error(error);
                }
                if (date === 'Invalid date') {
                    date = null;
                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la date de péremption' });
                        return;
                    }
                }
                let data = { idLcmde: '0', statutSyncLcmde: this.myservice.get_statut_sync(), tvaLcmde: this.state.tvaLcmde, qteLcmde: this.state.qteLcmde, puLcmde: this.state.puLcmde, puvMajLcmde: this.state.puvMajLcmde, qteReapLcmde: this.state.qteReapLcmde, dateExpLcmde: date, qteReapLcmde: this.state.qteReapLcmde, statutLcmde: this.state.statutLcmde.value, idEtabl: this.state.selectedCmde.idEtabl, idCmde: { idCmde: this.state.selectedCmde.idCmde, numCmde: this.state.selectedCmde.numCmde, typeCmde: this.state.selectedCmde.typeCmde, idFour: { idFour: this.state.selectedCmde.idFour.idFour, nomFour: this.state.selectedCmde.idFour.nomFour }, idBoutiq: { idBoutiq: this.state.selectedCmde.idBoutiq.idBoutiq } }, idArticle: { idArticle: this.state.idArticle.value } };
                for (var i = this.state.liste_lcmde.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_lcmde[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Cet article existe déja dans la commande sélectionnée, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_lcmde(data).then(rep => {
                    if (rep.code == 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                        this.setState({ modif_lcmde: false });
                        this.setState({ visible_dlg_lcmde: false });
                        //this.get_liste_cmde();
                        if (this.state.selectedCmde != null && this.state.selectedCmde != undefined) {
                            this.get_liste_lcmde(this.state.selectedCmde.idCmde);
                        }
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                    }
                });
            } else {
                let date = null;
                try {
                    date = moment(this.state.dateExpLcmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                } catch (error) {
                    console.error(error);
                }
                if (date === 'Invalid date') {
                    date = null;
                }

                this.setState({ showindicator: true });
                let data = this.state.selectedLcmde;
                if (this.state.qteLcmde > this.state.selectedLcmde.qteLcmde) {
                    data.qteReapLcmde = this.state.qteLcmde - this.state.selectedLcmde.qteLcmde;
                }
                data.qteLcmde = this.state.qteLcmde;
                data.puLcmde = this.state.puLcmde;
                data.tvaLcmde = this.state.tvaLcmde;
                data.puvMajLcmde = this.state.puvMajLcmde;
                data.dateExpLcmde = date;
                data.statutSyncLcmde = this.myservice.get_statut_sync();
                data.statutLcmde = this.state.statutLcmde.value;
                this.myservice.enreg_lcmde(data).then(rep => {
                    if (rep.code == 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                        this.setState({ modif_lcmde: false });
                        this.setState({ visible_dlg_lcmde: false });
                        //this.get_liste_cmde();
                        if (this.state.selectedCmde != null && this.state.selectedCmde != undefined) {
                            this.get_liste_lcmde(this.state.selectedCmde.idCmde);
                        }
                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                    }
                });
            }
        }
    }

    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.calculer_total(this.state.liste_ligne);
        this.forceUpdate();
    }

    add_article() {
        if (this.state.idBoutiq == null || this.state.idBoutiq == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir une boutique' });
            return;
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
        } else if (this.state.qteLcmde == null || this.state.qteLcmde == '' || this.state.qteLcmde == 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        } else if (parseFloat(this.state.qteLcmde) < parseFloat(this.state.qteReapLcmde)) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier la quantité à réapprovisionner' });
        } else if (this.state.remiseLcmde > this.state.puLcmde) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier la remise' });
        }
        else {
            let qts = this.state.qteLcmde;
            let qtc = this.state.qteReapLcmde;
            if (this.state.qteLcmde === null || this.state.qteLcmde === '') {
                qts = 1;
            }
            if (this.state.qteReapLcmde === null || this.state.qteReapLcmde === '') {
                qtc = 1;
            }
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            let date = null;
            try {
                date = moment(this.state.dateExpLcmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
            } catch (error) {
                console.error(error);
            }
            if (date === 'Invalid date') {
                date = null;
                if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la date de péremption' });
                    return;
                }
            }

            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLcmde: '0', qteReapLcmde: qtc, statutLcmde: this.state.statutLcmde.value, remiseLcmde: this.state.remiseLcmde, qteLcmde: qts, puLcmde: this.state.puLcmde, tvaLcmde: this.state.tvaLcmde, puvMajLcmde: this.state.puvMajLcmde, dateExpLcmde: date, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteLcmde = parseFloat(this.state.liste_ligne[i].qteLcmde) + parseFloat(qts);
                        this.state.liste_ligne[i].qteReapLcmde = parseFloat(this.state.liste_ligne[i].qteReapLcmde) + parseFloat(qtc);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLcmde: '0', qteReapLcmde: qtc, statutLcmde: this.state.statutLcmde.value, remiseLcmde: this.state.remiseLcmde, qteLcmde: qts, puLcmde: this.state.puLcmde, tvaLcmde: this.state.tvaLcmde, puvMajLcmde: this.state.puvMajLcmde, dateExpLcmde: date, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.forceUpdate();
        }
        this.calculer_total(this.state.liste_ligne);
        document.getElementById("new_dp_prod").click();
    }

    calculer_total(liste) {
        let mtpaye = this.state.mtrglCmde;
        let _total_restant = 0;
        let _montant_brut = 0;
        let _remise_total = 0;
        let _tt_tva_lcmde = 0;
        let _montant_total = 0;
        let _montant_total_format = "0";
        let _remise_total_format = "0";
        let _mtapayer = 0;
        let _remise_Cmde = this.state.remiseCmde;
        let _mtTVA = 0;
        for (var i = liste.length; i--;) {
            if (this.get_type_cmde() === 'Retour') {
                liste[i].qteReapLcmde = liste[i].qteLcmde;
            }
            _montant_brut += liste[i].puLcmde * liste[i].qteLcmde;
            _remise_total += liste[i].remiseLcmde * liste[i].qteLcmde;
            _tt_tva_lcmde += 0.01 * liste[i].tvaLcmde * (liste[i].puLcmde * liste[i].qteLcmde - liste[i].puLcmde * liste[i].remiseLcmde);
        }
        _montant_total = _montant_brut - _remise_Cmde;
        _mtTVA = this.formaterMontant(0.01 * this.state.tvaCmde * _montant_total + _tt_tva_lcmde);
        _montant_total_format = this.formaterMontant(_montant_total);
        _mtapayer = _montant_total + 0.01 * this.state.tvaCmde * _montant_total + _tt_tva_lcmde;
        _remise_total_format = this.formaterMontant(_remise_total + _remise_Cmde);

        if (this.get_type_cmde() === 'Retour') {
            mtpaye = _mtapayer;
        }
        _total_restant = this.formaterMontant(_mtapayer - mtpaye);

        this.setState({ montant_total: _montant_total });
        this.setState({ montant_total_format: _montant_total_format });
        this.setState({ mtapayer_format: this.formaterMontant(_mtapayer) });
        this.setState({ remise_total_format: _remise_total_format });
        this.setState({ mtTVA: _mtTVA });
        this.setState({ montant_brut_format: this.formaterMontant(_montant_brut) });
        this.setState({ mtapayer: _mtapayer });
        this.setState({ mtrglCmde: mtpaye });
        this.setState({ total_restant: _total_restant });
    }

    calculer_restant() {
        this.setState({ total_restant: this.formaterMontant(this.state.mtapayer - this.state.mtrglCmde) });
    }


    actionTemplate(rowData, column) {
        let items = [];
        let disabled = false;
        if (rowData.typeCmde === 'Transfert' || rowData.typeCmde === 'Retour') {
            disabled = true;
        }
        if (this.state.userconnected.profilUser == 'PDG') {
            items = [
                {
                    label: 'Bon de commande',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_bc(rowData);
                    }
                }
            ];
            if (rowData.typeCmde != 'Transfert') {
                items.push(
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        disabled: disabled,
                        command: (e) => {
                            if (disabled === true) {
                                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention vous ne pouvez pas modifier cette commande' });
                            } else {
                                this.edit_cmde(rowData);
                            }
                        }
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        disabled: disabled,
                        command: (e) => {
                            if (disabled === true) {
                                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention vous ne pouvez pas supprimer cette commande' });
                            } else {
                                this.conf_delete_cmde(rowData);
                            }
                        }
                    },
                    {
                        label: 'Règlement',
                        icon: 'pi pi-briefcase',
                        command: (e) => {
                            this.show_dlg_reglCmde(rowData);
                        }
                    }
                )
            }
        } else {
            items = [
                {
                    label: 'Bon de commande',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_bc(rowData);
                    }
                }
            ];
            if (rowData.typeCmde != 'Transfert') {
                items.push(
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        disabled: disabled,
                        command: (e) => {
                            if (disabled === true) {
                                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention vous ne pouvez pas modifier cette commande' });
                            } else {
                                this.edit_cmde(rowData);
                            }
                        }
                    }
                )
            }
        };

        if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncCmde == 'Envoyé') {
            for (var i = items.length; i--;) {
                if (items[i].label === 'Supprimer') {
                    items.splice(i, 1);
                }
            }
        }
        let cl = '';
        if (rowData.statutSyncCmde == 'Envoyé') {
            cl = "p-button-success";
        } else {
            cl = "p-button-warning";
        }

        return <div>
            <SplitButton className={cl} onClick={(e) => this.edit_cmde(rowData)} icon="pi pi-pencil" model={items} style={{width: 45}}/>
        </div>

    }

    actionTemplate_lcmde(rowData, column) {
        let disable_delete_button = false;
        let disable_edit_button = false;
        if (rowData.idCmde.typeCmde === 'Transfert') {
            disable_delete_button = true;
            disable_edit_button = true;
        } else if (rowData.idCmde.typeCmde === 'Retour') {
            disable_edit_button = true;
            if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncLcmdeé == 'Envoyé') {
                disable_delete_button = true;
            }
        } else {
            if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.selectedCmde.dateCmde !== moment(new Date()).format('DD/MM/YYYY')) {
                disable_delete_button = true;
                disable_edit_button = true;
            }
        }

        let cl = '';
        if (rowData.statutSyncLcmde == 'Envoyé') {
            cl = "p-button-success";
        } else {
            cl = "p-button-warning";
        }

        return <div className="p-grid" >
            <div className="p-col"><Button className={cl} disabled={disable_edit_button} onClick={(e) => this.edit_lcmde(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_delete_button} onClick={(e) => this.conf_delete_lcmde(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_cmde() {
        if (this.state.selectedCmde == null || this.state.selectedCmde == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_cmde(this.state.selectedCmde).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_lcmde() {
        if (this.state.selectedLcmde == null || this.state.selectedLcmde == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_lcmde(this.state.selectedLcmde).then(data => this.resultat_lcmde(data.code, data.contenu));
        }
    }


    conf_delete_cmde(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCmde: data });
            this.setState({ msg: data.numCmde });
            this.setState({ visible: true });
        }
    }

    conf_delete_lcmde(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLcmde: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_lcmde: true });
        }
    }


    edit_lcmde(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_lcmde: true }, () => {
                let lprod = null;
                this.myservice.get_inventaire_mins('Tous', 'Tous', 'Tous', 'Tous', this.state.selectedCmde.idBoutiq.idBoutiq).then(rep => {
                    this.setState({ liste_article: rep }, () => {
                        if (this.state.liste_article != null && this.state.liste_article != undefined) {
                            lprod = this.state.liste_article.map(x => {
                                return { value: x.idArt, label: x.libArt + ' - ' + x.cbArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, label_rech: x.libArt + ' ' + x.cbArt, prix_ref_revient: x.prix_ref_revient, tvaArticle: x.tvaArticle }
                            });
                        }
                        let art = lprod.filter(function (el) {
                            return el.value == data.idArticle.idArticle;
                        })[0];
                        this.setState({ idLcmde: data.idLcmde });
                        this.setState({ puLcmde: data.puLcmde });
                        this.setState({ tvaLcmde: data.tvaLcmde });
                        this.setState({ puvMajLcmde: data.puvMajLcmde });
                        this.setState({ purMajLcmde: data.purMajLcmde });
                        this.setState({ statutLcmde: { value: data.statutLcmde, label: data.statutLcmde } });
                        this.setState({ dateExpLcmde: data.dateExpLcmde });
                        this.setState({ qteLcmde: data.qteLcmde });
                        this.setState({ idArticle: art });
                        this.setState({ visible_dlg_lcmde: true });
                        this.setState({ disable_prod: true });
                        this.setState({ selectedLcmde: data });
                        this.setState({ libArticle: data.idArticle.libArticle });
                        window.scrollTo(0, 0);
                        this.forceUpdate();
                    });
                });
            });

        }
    }


    show_new_cmde_dlg() {
        this.setState({ modif: false });
        if (this.get_type_cmde() === 'Retour') {
            this.setState({ visible_new_cmde_dlg: true });
        } else {
            window.location = '#/commandes/Nouvelle commande';
        }

    }

    show_lcmde_dlg() {
        this.setState({ modif_lcmde: false }, () => {
            this.setState({ visible_dlg_lcmde: true });
            this.setState({ disable_prod: false });
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                this.get_liste_article(this.state.selectedCmde.idBoutiq.idBoutiq);
                this.setState({ rfiltreArticle: '' });
            }


        })
    }

    get_liste_article(idBoutiq) {
        let lprod = null;
        this.setState({ showindicator: true });
        this.myservice.get_inventaire_mins(this.state.rfiltreArticle, idBoutiq).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            if (this.state.liste_article != null && this.state.liste_article != undefined) {
                lprod = this.state.liste_article.map(x => {
                    return { value: x.idArt, label: x.libArt + ' - ' + x.cbArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, label_rech: x.libArt + ' ' + x.cbArt, prix_ref_revient: x.prix_ref_revient, tvaArticle: x.tvaArticle }
                });
                this.setState({ lprod: lprod });
            }
        }));
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>

                        {this.state.userconnected.profilUser === 'PDG' ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_achat_format}</label>
                            </div>
                            : null}

                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>

                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    option_recherche() {
        if (this.get_type_cmde() === 'Retour') {
            return <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                </div>
                <div className="p-col-12 p-md-2">
                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                </div>
                <div className="p-col-12 p-md-2">
                    <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                </div>
                <div className="p-col-12 p-md-3">
                    <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                </div>
                <div className="p-col-12 p-md-2">
                    <div className="p-inputgroup">
                        <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_cmde()} />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>
            </div>
        } else {
            return <div className="p-grid">
                <div className="p-col-12 p-md-1">
                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                </div>
                <div className="p-col-12 p-md-1">
                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                </div>
                <div className="p-col-12 p-md-2">
                    <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                </div>
                <div className="p-col-12 p-md-2">
                    <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                </div>
                <div className="p-col-12 p-md-2">
                    <Dropdown options={this.state.lfournisseur_rech} placeholder="Fournisseur" value={this.state.ridFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridFour: e.value })} autoWidth={false} />
                </div>
                <div className="p-col-12 p-md-2">
                    <Dropdown options={this.state.liste_statut_reglement} placeholder="Reglement" value={this.state.rstatutCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutCmde: e.value })} autoWidth={false} />
                </div>
                <div className="p-col-12 p-md-2">
                    <div className="p-inputgroup">
                        <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_cmde()} />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>
            </div>
        }
    }



    page_new_commande() {
        let disable_payement = false;
        if (this.get_type_cmde() === 'Retour') {
            disable_payement = true;
        }

        let items = [
            {
                label: 'Historique',
                icon: 'pi pi-search',
                command: (e) => {
                    alert('');
                }
            }
        ];
        if ((this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur' || this.state.userconnected.profilUser === 'Administrateur') && this.myservice.get_statut_offline() == 'false') {
            items.push(
                {
                    label: 'Nouveau ou ajuster',
                    icon: 'pi pi-plus',
                    command: (e) => {
                        this.show_dlg_article();
                    }
                }
            );
        }


        return <div className="p-grid" style={{ backgroundColor: "#ffffff" }}>
            <div className="p-col-12" style={{ marginBottom: '-10px' }}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date commande *</label>
                        </span>
                        <Calendar value={this.state.dateCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateCmde: e.value })} />
                    </div>
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date réception*</label>
                        </span>
                        <Calendar value={this.state.dateReceptionCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateReceptionCmde: e.value }, () => {
                            this.state.delaiRglCmde.setDate(this.state.dateCmde.getDate() + 45);
                        })} />
                    </div>
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Délai règlement *</label>
                        </span>
                        <Calendar value={this.state.delaiRglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiRglCmde: e.value })} />
                    </div>
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Boutique *</label>
                        </span>
                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                            this.setState({ idBoutiq: e.value }, () => {
                                this.get_liste_article(this.state.idBoutiq.value);
                            })
                        }
                        } autoWidth={false} />
                    </div>
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Fournisseur *</label>
                        </span>
                        <Dropdown options={this.state.lfournisseur} value={this.state.idFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                            onChange={(e) => {
                                this.setState({ idFour: e.value }, () => {
                                    this.setState({ tvaCmde: this.state.idFour.tvaFour });
                                });
                            }} autoWidth={false} itemTemplate={(option) => {
                                if (!option.value) {
                                    return option.label;
                                }
                                else {
                                    return (
                                        <div className="p-clearfix">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-8">
                                                    <label>{option.label}</label>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label>{option.typeFour}</label>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label>{option.tvaFour}</label>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }} panelStyle={{ width: '450px' }} />
                    </div>


                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">N° BC </label>
                        </span>
                        <InputText onKeyDown={this.handleKeyPress} value={this.state.numCmde} onChange={(e) => this.setState({ numCmde: e.target.value })} />
                    </div>
                </div>
            </div>


            <div className="p-grid">
                <div className="p-col-12 p-lg-3">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Panel style={{ minHeight: '600px' }} header="Ajout d'article">
                                <div className="p-col-12">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article *</label>
                                        <span className="p-inputgroup-addon" style={{ color: 'red', fontWeight: 'bold', marginLeft: 10 }}>{this.state.qte_stock}</span>
                                    </span>
                                    <div className="p-inputgroup">
                                        <SplitButton icon="pi pi-search" className="p-button-warning" style={{ width: 60 }} onClick={(e) => {
                                            this.op.toggle(e);
                                        }} model={items} />
                                        <span>
                                            <Dropdown id="new_dp_prod"
                                                style={{ width: '210px'}}
                                                value={this.state.idArticle}
                                                options={this.state.lprod}
                                                placeholder="Articles"
                                                panelStyle={{ width: '500px' }}
                                                onChange={(e) => {
                                                    this.setState({ idArticle: e.value }, () => {
                                                        if (this.get_type_cmde() === 'Retour') {
                                                            this.setState({ puLcmde: this.state.idArticle.prix_ref_vente });
                                                        } else {
                                                            this.setState({ puLcmde: this.state.idArticle.prix_ref_achat });
                                                        }
                                                        this.setState({ tvaLcmde: this.state.idArticle.tvaArticle });
                                                        this.setState({ purMajLcmde: this.state.idArticle.prix_ref_revient });
                                                        if (this.state.idArticle.prix_ref_revient === 0) {
                                                            this.setState({ purMajLcmde: this.state.idArticle.prix_ref_achat });
                                                        }
                                                        this.setState({ puvMajLcmde: this.state.idArticle.prix_ref_vente });
                                                        this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                                        this.setState({ qteLcmde: 1 });
                                                        this.setState({ qteReapLcmde: 1 });
                                                        this.setState({ remiseLcmde: 0 });
                                                    })
                                                }}
                                                optionLabel='label' filter={true} filterBy='label,cbArt' filterMatchMode='contains' itemTemplate={this.articleItemTemplate}
                                            />
                                        </span>
                                    </div>
                                </div>


                                <div className="p-col-12">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Quantité *</label>
                                    </span>
                                    <InputText onKeyDown={this.handleKeyPress} keyfilter="pint" value={this.state.qteLcmde} onChange={(e) => this.setState({ qteLcmde: e.target.value, qteReapLcmde: e.target.value })} />
                                </div>

                                {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">TVA *</label>
                                        </span>
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.tvaLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ tvaLcmde: e.target.rawValue })} className="p-inputtext" />
                                    </div> : null}

                                <div className="p-col-12">
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix d'achat *</label>
                                    </span>
                                    <Cleave onKeyDown={this.handleKeyPress} value={this.state.puLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                </div>

                                {this.get_type_cmde() !== 'Retour' && this.state.userconnected.idEtabl.domaineEtabl !== 'Pharmacie' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix de revient *</label>
                                        </span>
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.purMajLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ purMajLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                    </div>
                                    :
                                    null}


                                {this.get_type_cmde() !== 'Retour' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix de vente *</label>
                                        </span>
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.puvMajLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puvMajLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                    </div>
                                    :
                                    null}
                                {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' || this.state.userconnected.idEtabl.domaineEtabl === 'Restaurant' || this.state.userconnected.idEtabl.domaineEtabl === 'Alimentation générale' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date peremption *</label>
                                        </span>
                                        <Calendar onKeyDown={this.handleKeyPress} dateFormat="dd/mm/yy" value={this.state.dateExpLcmde} onChange={e => this.setState({ dateExpLcmde: e.value })}></Calendar>
                                    </div> : null}
                                {this.get_type_cmde() !== 'Retour' && this.state.userconnected.idEtabl.domaineEtabl !== 'Pharmacie' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Remise en montant *</label>
                                        </span>
                                        <Cleave onKeyDown={this.handleKeyPress} value={this.state.remiseLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                    </div>
                                    :
                                    null}
                                {this.get_type_cmde() !== 'Retour' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Qté à réapprovisionner *</label>
                                        </span>
                                        <InputText onKeyDown={this.handleKeyPress} keyfilter="pint" value={this.state.qteReapLcmde} onChange={(e) => this.setState({ qteReapLcmde: e.target.value })} />
                                    </div>
                                    :
                                    null}

                                {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' || this.state.userconnected.idEtabl.domaineEtabl === 'Restaurant' || this.state.userconnected.idEtabl.domaineEtabl === 'Alimentation générale' ?
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Statut *</label>
                                        </span>
                                        <Dropdown options={this.state.liste_statut_lcmde} value={this.state.statutLcmde} panelStyle={{ width: '500px' }} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutLcmde: e.value })} autoWidth={false} />
                                    </div> : null}


                                <div className="p-col-12">
                                    <Button label="Ajouter" style={{ width: '120px' }} icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-9">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Panel style={{ minHeight: '435px' }}>
                                <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="375px"
                                    onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                    responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                    editable={true}>
                                    <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                    <Column field="idArticle.libArticle" body={(rowData, column) => {
                                        let color = 'black';
                                        if (rowData.statutLcmde !== 'Recevable') {
                                            color = 'blue';
                                        }
                                        return <div>
                                            <span style={{ color: color, fontWeight: 'bold' }}>{rowData.idArticle.libArticle}</span>
                                        </div>
                                    }} header="Produit" style={{ width: '35%' }} />
                                    <Column field="qteLcmde" header="Quantité" style={{ width: '8%' }} editor={this.qteLcmdeEditor} />
                                    <Column field="puLcmde" body={(rowData, column) => {
                                        let color = 'black';
                                        if (rowData.qteLcmde < rowData.qteReapLcmde) {
                                            color = 'red';
                                        }
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.puLcmde)}</span>
                                        </div>
                                    }} header="Prix d'achat" style={{ width: '12%' }} editor={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG' ? null : this.puLcmdeEditor} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.qteLcmde * (rowData.puLcmde - rowData.remiseLcmde))}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '10%' }} />
                                    <Column field="puvMajLcmde" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000' }}>{this.formaterMontant(rowData.puvMajLcmde)}</span>
                                        </div>
                                    }} header="Prix de vente" style={{ width: '12%' }} editor={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG' ? null : this.puvMajLcmdeEditor} />
                                    <Column field="qteReapLcmde" body={(rowData, column) => {
                                        let color = 'black';
                                        if (rowData.qteLcmde < rowData.qteReapLcmde) {
                                            color = 'red';
                                        }
                                        return <div>
                                            <span style={{ color: color, fontWeight: 'bold' }}>{rowData.qteReapLcmde}</span>
                                        </div>
                                    }} header="Qté reap." style={{ width: '10%' }} editor={this.qteReapLcmdeEditor} />
                                    <Column field="dateExpLcmde" header="Peremption" style={{ width: '8%' }} />
                                </DataTable>
                            </Panel>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ marginBottom: '10px' }}>
                                        <div className="p-grid">
                                            <div className="p-col-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Montant payé *</label>
                                                </span>
                                                <Cleave onKeyDown={this.handleKeyPress} value={this.state.mtrglCmde} disabled={disable_payement} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtrglCmde: e.target.rawValue }, () => {
                                                    this.calculer_restant();
                                                })} className="p-inputtext" />
                                            </div>
                                            <div className="p-col-2">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Référence payement</label>
                                                </span>
                                                <InputText onKeyDown={this.handleKeyPress} value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                            </div>
                                            <div className="p-col-2">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Mode reglement</label>
                                                </span>
                                                <Dropdown placeholder="Mode reglement" disabled={disable_payement} options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                                    this.setState({ modeRgl: e.value })
                                                }
                                                } autoWidth={false} />
                                            </div>
                                            <div className="p-col-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Remise en montant *</label>
                                                </span>
                                                <Cleave onKeyDown={this.handleKeyPress} value={this.state.remiseCmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseCmde: e.target.rawValue }, () => {
                                                    this.calculer_total(this.state.liste_ligne);
                                                })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                            </div>
                                            <div className="p-col-2">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Charges *</label>
                                                </span>
                                                <Cleave value={this.state.fraisCharge} disabled={disable_payement} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ fraisCharge: e.target.rawValue })} className="p-inputtext" />
                                            </div>
                                        </div>

                                        <div className="p-grid" style={{ marginTop: 3, backgroundColor: '#f5f8f9', border: '1px solid #C2C4C4', marginBottom: '5px' }}>
                                            <div className="p-col-12 p-md-1">
                                                <label >TT brut</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.montant_brut_format}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label >TTC</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.mtapayer_format}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label >Restant</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontWeight: 'bold', fontSize: '16px', color: 'red' }}>{this.state.total_restant}</label>
                                            </div>
                                        </div>

                                        <center>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cmde_dlg()} /></div>
                                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_cmde()} /></div>
                                            </div>
                                        </center>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_cmde();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_lcmde = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_lcmde: false });
                    this.delete_lcmde();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_lcmde: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let mode = false;
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            if (this.props.match.params.typeOp === 'Nouvelle commande') {
                mode = true;
            } else {
                mode = false;
            }
        }

        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total_format} </label>


        let header_cmde = <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouvelle commande" style={{ width: 170, height: 28 }} onClick={this.show_new_cmde_dlg}></Button>;


        let header_rgl_cmde = <Button type="button" icon="pi pi-briefcase" iconPos="left" className="p-button-success" label="Règlement" style={{ width: 120, height: 28 }} onClick={this.show_dlg_reglement}></Button>;



        if (this.get_type_cmde() === 'Retour') {
            header_cmde = <div style={{ textAlign: 'left' }}>
                <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouveau retour" style={{ width: 170 }} onClick={this.show_new_cmde_dlg}></Button>
            </div>;
        }

        const header_lcmde = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un article" style={{ width: 200 }} onClick={this.show_lcmde_dlg}></Button>
        </div>;

        const formatOptionLabel = ({ value, label, prix_ref_vente_format, prix_ref_achat_format, qte_stock_format }) => (
            <div className="p-grid">
                <div className="p-col-7">
                    <label>{label}</label>
                </div>

                {this.state.userconnected.profilUser === 'PDG' ?
                    <div style={{ fontWeight: 'bold' }} className="p-col-2">
                        <label>{prix_ref_achat_format}</label>
                    </div>
                    : null}

                <div className="p-col-2">
                    <label>{prix_ref_vente_format}</label>
                </div>

                <div className="p-col-1" >
                    <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{qte_stock_format}</label>
                </div>

            </div>
        );

        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.idEtabl.domaineEtabl === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }
        const selectStyles = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                width: 310,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 600,
            }),
        };

        const customFilter = createFilter({ ignoreAccents: false });


        if (mode === true) {
            return <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-8">
                            <InputText value={this.state.rfiltreArticle} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    //this.op.toggle(e);
                                    document.getElementById("new_dp_prod").click();
                                    this.get_liste_article(this.state.idBoutiq.value);
                                }
                            }} onChange={(e) => this.setState({ rfiltreArticle: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                //this.op.toggle(e);
                                document.getElementById("new_dp_prod").click();
                                this.get_liste_article(this.state.idBoutiq.value);
                            }} style={{ width: '40px' }} />
                        </div>

                    </div>
                </OverlayPanel>
                <Dialog header="Inventaire" visible={this.state.visible_dlg_article} style={{ width: '40%' }} modal={false} onHide={(e) => {
                    this.get_liste_article(this.state.idBoutiq.value);
                    this.setState({ visible_dlg_article: false });
                }}>
                    <FormOptArticle idArticle={this.state.idArticle_opt} />
                </Dialog>

                {this.page_new_commande()}

            </div>
        } else return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-8">
                            <InputText value={this.state.rfiltreArticle} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    //this.op.toggle(e);
                                    document.getElementById("new_dp_prod").click();
                                    this.get_liste_article(this.state.idBoutiq.value);
                                    
                                }
                            }} onChange={(e) => this.setState({ rfiltreArticle: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                //this.op.toggle(e);
                                document.getElementById("new_dp_prod").click();
                                this.get_liste_article(this.state.idBoutiq.value);
                            }} style={{ width: '40px' }} />
                        </div>

                    </div>
                </OverlayPanel>
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_lcmde} style={{ width: '250px' }} modal={false} footer={dialogFooter_lcmde} onHide={(e) => this.setState({ visible_lcmde: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Nouveau retour" visible={this.state.visible_new_cmde_dlg} style={{ width: '95%' }} modal={false} onHide={(e) => this.setState({ visible_new_cmde_dlg: false })}>
                    {this.state.visible_new_cmde_dlg === true ?
                        this.page_new_commande()
                        : null}
                </Dialog>

                <Dialog header="Règlement en bloc" visible={this.state.visible_dlg_reglement} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reglement: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400, marginBottom: '10px', backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.lfournisseur_rech} placeholder="Fournisseur" value={this.state.ridFour} panelStyle={{ width: '500px' }} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridFour: e.value }, () => {
                                            this.get_liste_cmde_for_reglement();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.liste_type_fournisseur} placeholder="Type fournisseur" value={this.state.rtypeFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rtypeFour: e.value }, () => {
                                            this.get_liste_cmde_for_reglement();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.liste_type_cmde} placeholder="Type BC" value={this.state.rtypeCmde} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rtypeCmde: e.value }, () => {
                                            this.get_liste_cmde_for_reglement();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <div className="p-inputgroup">
                                            <Button icon='pi pi-search' label='Rechercher' style={{ width: '120px' }} onClick={(e) => {
                                                this.get_liste_cmde_for_reglement()
                                            }} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_cmde_for_reglement} scrollable={true} scrollHeight="300px"
                                    onSelectionChange={e => {
                                        this.setState({ total_reglement_format: '0' });
                                        this.setState({ liste_cmde_for_reglement_selecteds: e.value }, () => {
                                            let total = 0;
                                            if (this.state.liste_cmde_for_reglement_selecteds != null && this.state.liste_cmde_for_reglement_selecteds != undefined) {
                                                if (this.state.liste_cmde_for_reglement_selecteds.length > 0) {
                                                    for (var i = this.state.liste_cmde_for_reglement_selecteds.length; i--;) {
                                                        total += this.state.liste_cmde_for_reglement_selecteds[i].mt_restant;
                                                    }
                                                }
                                            }
                                            this.setState({ total_reglement_format: this.formaterMontant(total) });
                                        });
                                    }
                                    } selection={this.state.liste_cmde_for_reglement_selecteds}
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                                    <Column body={(rowData, column) => {
                                        let color = 'black';
                                        if (rowData.avoir.idcmde != null && rowData.avoir.idcmde != undefined) {
                                            color = 'blue';
                                        }
                                        return <div>
                                            <span style={{ color: color, fontWeight: 'bold' }}>{rowData.numCmde}</span> <br />
                                        </div>
                                    }} header="N° BC" style={{ width: '15%' }} />
                                    <Column field="dateCmde" header="Date" style={{ width: '10%' }} />
                                    <Column field="typeCmde" header="Type" style={{ width: '10%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.mt_format}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '15%' }} />
                                    <Column body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: 'red' }}>{rowData.mt_restant_format}</span>
                                        </div>
                                    }} header="Restant" style={{ width: '15%' }} />
                                    <Column field="idFour.nomFour" header="Fournisseur" style={{ width: '34%' }} />
                                </DataTable>

                            </div>
                            <div className="p-grid" style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.daterglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglCmde: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Montant total </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <label style={{ fontWeight: 'bold', fontSize: '18px', color: 'green' }}>{this.state.total_reglement_format} </label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Référence </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descParamCodif">Mode reglement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                        this.setState({ modeRgl: e.value })
                                    }
                                    } autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglCmde_bloc()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglCmde_bloc()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Règlement" visible={this.state.visible_dlg_reglCmde} style={{ width: '60%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reglCmde: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400, marginBottom: -8 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.daterglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglCmde: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Montant *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.mtrglCmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtrglCmde: e.target.rawValue }, () => {
                                            //
                                        })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Référence </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Modalité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                            this.setState({ modeRgl: e.value })
                                        }
                                        } autoWidth={false} />
                                    </div>
                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglCmde()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglCmde()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>


                                <DataTable value={this.state.liste_regler_cmde} scrollable={true} scrollHeight="220px"
                                    onRowClick={e => this.setState({ selectedReglCmde: e.data })}
                                    selectionMode="single" selection={this.state.selectedReglCmde}
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column header="#" body={this.actionTemplate_reglCmde.bind(this)} style={{ width: '20%' }} />
                                    <Column field="daterglCmde" header="Date" style={{ width: '16%' }} />
                                    <Column field="mtrglCmde" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.mtrglCmde)}</span>
                                        </div>
                                    }} header="Montant" style={{ width: '20%' }} />
                                    <Column field="modeRgl" header="Mode règl." style={{ width: '14%' }} />
                                    <Column field="refRgl" header="Référence" style={{ width: '24%' }} />
                                    <Column field="statutSyncRgl" body={(rowData, column) => {
                                        if (rowData.statutSyncRgl == 'Envoyé') {
                                            return <div>
                                                <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                            </div>
                                        } else {
                                            return <div>
                                                <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                            </div>
                                        }
                                    }} header="Sync" style={{ width: '6%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Modification commande" visible={this.state.visible_dlg_cmde} style={{ width: '550px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_cmde: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label >Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.dateCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateCmde: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Date réception *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.dateReceptionCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateReceptionCmde: e.value }, () => {
                                            this.state.delaiRglCmde.setDate(this.state.dateCmde.getDate() + 45);
                                        })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Délai règlement *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.delaiRglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiRglCmde: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >N° BC *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.numCmde} onChange={(e) => this.setState({ numCmde: e.target.value, qteReapLcmde: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >Boutique *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                            this.setState({ idBoutiq: e.value });
                                        }
                                        } autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >Fournisseur *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={this.state.lfournisseur} value={this.state.idFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                            onChange={(e) => {
                                                this.setState({ idFour: e.value });
                                            }} autoWidth={false} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="p-clearfix">
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-9">
                                                                    <label>{option.label}</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-3">
                                                                    <label>{option.typeFour}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Remise en montant *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Cleave value={this.state.remiseCmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseCmde: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label >TVA *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Cleave value={this.state.tvaCmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ tvaCmde: e.target.rawValue })} className="p-inputtext" />
                                    </div>

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="profile">Charges *</label>
                                        </div>
                                        : null}
                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-9">
                                            <Cleave value={this.state.fraisCharge} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ fraisCharge: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        : null}

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-3">
                                            <label htmlFor="profile">Pertes *</label>
                                        </div>
                                        : null}
                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-9">
                                            <Cleave value={this.state.fraisPerte} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ fraisPerte: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        : null}

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cmde()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_cmde()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Ajout/modificaton détails" visible={this.state.visible_dlg_lcmde} style={{ width: '550px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_lcmde: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Article *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        {this.state.modif_lcmde == false ?
                                            <>
                                                <span className="p-float-label">
                                                    <InputText value={this.state.rfiltreArticle} onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            this.get_liste_article(this.state.idBoutiq.value);
                                                            document.getElementById("new_dp_prod_modif").click();
                                                        }
                                                    }} onChange={(e) => this.setState({ rfiltreArticle: e.target.value })} />
                                                    <label htmlFor="in">Rechercher par code barre ou par désignation</label>
                                                </span>

                                                <Dropdown id="new_dp_prod_modif"
                                                    options={this.state.lprod}
                                                    value={this.state.idArticle}
                                                    placeholder="Articles"
                                                    onChange={(e) => {
                                                        this.setState({ idArticle: e.value }, () => {
                                                            if (this.state.selectedCmde.typeCmde === 'Retour') {
                                                                this.setState({ puLcmde: this.state.idArticle.prix_ref_vente });
                                                            } else {
                                                                this.setState({ puLcmde: this.state.idArticle.prix_ref_achat });
                                                            }
                                                            this.setState({ puvMajLcmde: this.state.idArticle.prix_ref_vente });
                                                            this.setState({ purMajLcmde: this.state.idArticle.prix_ref_revient });
                                                            if (this.state.idArticle.prix_ref_revient === 0) {
                                                                this.setState({ purMajLcmde: this.state.idArticle.prix_ref_achat });
                                                            }
                                                            if (this.state.modif_lcmde === false) {
                                                                this.setState({ qteLcmde: 1 });
                                                                this.setState({ qteReapLcmde: 1 });
                                                            }
                                                        })
                                                    }} style={{ width: '100%' }}
                                                    panelStyle={{ width: '550px' }}
                                                    optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                />
                                            </>
                                            :
                                            <label style={{ fontSize: 18, fontWeight: 'bold' }}>{this.state.libArticle}</label>
                                        }

                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Quantité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText keyfilter="pint" value={this.state.qteLcmde} onChange={(e) => this.setState({ qteLcmde: e.target.value, qteReapLcmde: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Prix d'achat *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Cleave value={this.state.puLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">TVA *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Cleave value={this.state.tvaLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ tvaLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                    </div>

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="profile">Prix de revient *</label>
                                        </div>
                                        : null}
                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.purMajLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ purMajLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                        </div>
                                        : null}

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="profile">Prix de vente *</label>
                                        </div>
                                        : null}

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.puvMajLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puvMajLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                        </div>
                                        : null}

                                    {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="profile">Date peremption  </label>
                                        </div>
                                        : null}

                                    {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                        <div className="p-col-12 p-md-8">
                                            <Calendar onKeyDown={this.handleKeyPress} dateFormat="dd/mm/yy" value={this.state.dateExpLcmde} onChange={e => this.setState({ dateExpLcmde: new Intl.DateTimeFormat('en-GB').format(e.target.value) })}></Calendar>
                                        </div> : null}

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="profile">Remise en montant * </label>
                                        </div>
                                        : null}

                                    {this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.remiseLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseLcmde: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                                        </div>
                                        : null}


                                    {this.state.modif_lcmde === false && this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="profile">Qté à réap. * </label>
                                        </div> : null}

                                    {this.state.modif_lcmde === false && this.get_type_cmde() !== 'Retour' ?
                                        <div className="p-col-12 p-md-8">
                                            <Cleave value={this.state.qteReapLcmde} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ qteReapLcmde: e.target.rawValue })} className="p-inputtext" />
                                        </div> : null}

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lcmde: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_lcmde()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    {this.option_recherche()}
                                </div>
                            </div>

                            <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="libCodif">Total cmde</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontSize: 18, fontWeight: 'bold' }} htmlFor="libCodif">{this.state.montant_total_format}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="libCodif">Total payé</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontSize: 18, fontWeight: 'bold', color: 'green' }} htmlFor="libCodif">{this.state.montant_paye_format}</label>
                                            </div>
                                            <div className="p-col-12 p-md-1">
                                                <label htmlFor="libCodif">Total restant</label>
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <label style={{ fontSize: 18, fontWeight: 'bold', color: 'red' }} htmlFor="libCodif">{this.state.montant_restant_format}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 650 }}>
                                                <DataTable value={this.state.liste_cmde} selectionMode="single" paginatorPosition="top" paginator={true} rows={7}
                                                    onRowClick={e => this.setState({ selectedCmde: e.data })} onRowSelect={e =>
                                                        this.setState({ selectedCmde: e.data }, () => {
                                                            this.get_liste_lcmde(this.state.selectedCmde.idCmde);
                                                            this.setState({ disable_add_prod: false });
                                                            if (this.state.selectedCmde.typeCmde === 'Transfert') {
                                                                this.setState({ disable_add_prod: true });
                                                            } else {
                                                                if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.selectedCmde.dateCmde !== moment(new Date()).format('DD/MM/YYYY')) {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            }
                                                        })
                                                    }
                                                    paginatorRight={header_rgl_cmde} paginatorLeft={header_cmde}
                                                    responsive={true} selection={this.state.selectedCmde} >
                                                    <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '12%', overflow: 'visible' }} />
                                                    <Column field="numCmde" body={(rowData, column) => {
                                                        let color = 'black';
                                                        if (rowData.avoir !== null && rowData.avoir !== undefined && rowData.avoir !== '') {
                                                            if ((rowData.avoir.idCmde !== '' && rowData.avoir.idCmde !== undefined && rowData.avoir.idCmde !== null) || rowData.typeCmde === 'Avoir') {
                                                                color = 'blue';
                                                            }
                                                        }
                                                        return <div>
                                                            <span style={{ color: color, fontWeight: 'bold' }}>{rowData.numCmde}</span> <br />
                                                            <span style={{ color: '#000000' }}>{rowData.dateCmde} </span>
                                                        </div>
                                                    }} header="N° BC" style={{ width: '43%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="statutCmde" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000' }}>{rowData.typeCmde}</span> <br />
                                                            <span style={{ color: '#000000' }}>{rowData.statutCmde}</span> <br />
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.mt_format}</span>
                                                        </div>
                                                    }} header="Statut" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="idFour.nomFour" header="Fournisseur" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.idFour.nomFour}</span> <br />
                                                            <span style={{ color: '#000000' }}>{rowData.idBoutiq.codeBoutiq}</span>
                                                            <br />
                                                            {this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie' ?
                                                                <span style={{ color: 'green', fontWeight: 'bold' }}>{rowData.marge_cmde_format}</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    }}  filter={true} filterMatchMode='contains' sortable={true} />

                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 650 }}>
                                                <DataTable value={this.state.liste_lcmde} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                    onRowClick={e => this.setState({ selectedLcmde: e.data })} onRowSelect={e => this.setState({ selectedLcmde: e.data })}
                                                    responsive={true} selection={this.state.selectedLcmde} header={header_lcmde} >
                                                    <Column header="#" body={this.actionTemplate_lcmde.bind(this)} style={{ width: '14%' }} />
                                                    <Column field="idArticle.libArticle" header="Article" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="qteLcmde" header="Qté" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                                    <Column body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.puLcmde)}</span>
                                                        </div>
                                                    }} header="Prix achat" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                                    <Column body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.qteLcmde * (rowData.puLcmde - rowData.remiseLcmde))}</span>
                                                        </div>
                                                    }} header="Montant" style={{ width: '14%' }} filter={true} filterMatchMode='contains' />
                                                    <Column body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: '#000' }}>{this.formaterMontant(rowData.puvMajLcmde)}</span>
                                                        </div>
                                                    }} header="Prix vente" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="dateExpLcmde" header="Date per." style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div >
        );
    }
}