import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import Numeral from 'numeral';
import './styles.css';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import Cleave from 'cleave.js/react';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';


export class FormEntreeFond extends Component {
    constructor() {
        super();
        this.state = {
            liste_entree_fond: [],
            liste_boutique: [],
            liste_boutique_srce: [],
            lboutique_rech: [],
            lboutique: [],
            lboutique_dest: [],
            selectedEntreeFond: null,
            idEf: '0',
            montant_total: '0',
            desEf: '',
            montantEf: 0,
            idBoutiq: null,
            idBoutiqSrce: null,
            libEf: 'Subvention',
            dateEf: new Date(),
            pd1: new Date(),
            pd2: new Date(),
            ridBoutiqSrce: null,
            ridBoutiqDest: null,
            rlibEf: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            liste_lib_ef: [
                { label: 'Subvention', value: 'Subvention' },
                { label: 'Surplus retour ', value: 'Surplus retour' },
                { label: 'Autre', value: 'Autre' },
                { label: 'Revenu camion', value: 'Revenu camion' },
                { label: 'Transfert fond', value: 'Transfert fond' },
                { label: 'Fond de caisse', value: 'Fond de caisse' },
                { label: 'Compensation banque', value: 'Compensation banque' },

            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.edit_entree_fond = this.edit_entree_fond.bind(this);
        this.conf_delete_entree_fond = this.conf_delete_entree_fond.bind(this);
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_entree_fond();
    }

    conf_print_recu(data) {
        this.xulaservice.generer_edition_recu_trf(data.idEf);
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Toutes les boutiques' }];
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                this.setState({ lboutique_dest: lboutique });
            }

            this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            this.setState({ idBoutiqSrce: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });

            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
                this.setState({ liste_boutique_srce: lboutique });
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    this.setState({ liste_boutique_srce: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    get_liste_entree_fond() {
        let rlib = 'Tous';
        let rid_boutique_srce = 'Tous';
        let rid_boutique_dest = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiqSrce !== undefined && this.state.ridBoutiqSrce !== null) {
            rid_boutique_srce = this.state.ridBoutiqSrce;
        }
        if (this.state.ridBoutiqDest !== undefined && this.state.ridBoutiqDest !== null) {
            rid_boutique_dest = this.state.ridBoutiqDest;
        }
        if (this.state.rlibEf !== undefined && this.state.rlibEf !== null) {
            rlib = this.state.rlibEf;
        }
        this.setState({ montant_total: '0' });
        this.xulaservice.get_liste_entree_fond(spd1, spd2, rlib, rid_boutique_srce, rid_boutique_dest).then(data => this.setState({ liste_entree_fond: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montantEf
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));

    }
    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_entree_fond();
            this.get_liste_entree_fond();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_entree_fond(data) {
        if (data != null && data != undefined) {
            if ((this.state.userconnected.profilUser !== 'PDG')) {

                if (data.etatEf === 'Validé') {
                    if (this.state.userconnected.idUser == '471_150356') {
                        if (data.dateEf !== moment(new Date()).format('DD/MM/YYYY')) {
                            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette ligne car déja validée et antérieure' });
                            return;
                        }
                    } else {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette ligne car déja validée' });
                        return;
                    }
                }
                /* if (data.statutSyncEf === 'Envoyé') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier ce mouvement car déja synchronisé' });
                    return;
                } */
            }
            this.setState({ idEf: data.idEf });
            this.setState({ desEf: data.desEf });
            this.setState({ montantEf: data.montantEf });
            this.setState({ libEf: data.libEf });
            this.setState({ idBoutiq: { label: data.idBoutiq.nomBoutiq, value: data.idBoutiq.idBoutiq } });
            this.setState({ idBoutiqSrce: { label: data.idBoutiqSrce.nomBoutiq, value: data.idBoutiqSrce.idBoutiq } });
            this.setState({ modif: true });
            this.setState({ dateEf: data.dateEf });
            this.setState({ selectedEntreeFond: data });
        }
    }

    annule_entree_fond() {
        this.setState({ desEf: '' });
        this.setState({ montantEf: '' });
        this.setState({ idEf: '0' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_entree_fond() {
        if (this.state.montantEf === "" || this.state.montantEf === 0 || this.state.montantEf === undefined || this.state.idBoutiq === undefined || this.state.idBoutiq === null || this.state.idBoutiqSrce === undefined || this.state.idBoutiqSrce === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let date = moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idEf: '0', statutSyncEf: this.xulaservice.get_statut_sync(), dateEf: date, etatEf: 'En attente', desEf: this.state.desEf, montantEf: this.state.montantEf, libEf: this.state.libEf, idBoutiq: { idBoutiq: this.state.idBoutiq.value }, idBoutiqSrce: { idBoutiq: this.state.idBoutiqSrce.value }, idUserSrce: this.state.userconnected, idUserDest: this.state.userconnected };
                this.xulaservice.enreg_entree_fond(data).then(rep => this.resultat(rep.code, rep.contenu));
            } else {
                //let date = moment(this.state.dateEf, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedEntreeFond;
                data.montantEf = this.state.montantEf;
                data.libEf = this.state.libEf;
                data.idBoutiq = this.state.idBoutiq.value;
                data.idBoutiqSrce = this.state.idBoutiqSrce.value;
                data.statutSyncEf = this.xulaservice.get_statut_sync();
                // data.dateEf = date;
                data.desEf = this.state.desEf;
                this.xulaservice.enreg_entree_fond(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        let items = [];
        if (rowData.statutSyncEf === 'Envoyé' && this.xulaservice.get_statut_offline() == 'true') {

        } else {
            if (rowData.etatEf === 'Validé') {
                items.push({
                    label: 'Reçu',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_recu(rowData);
                    }
                });
                if (this.state.userconnected.profilUser === 'PDG') {
                    items.push({
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_delete_entree_fond(rowData);
                        }
                    });
                    if (rowData.dateEtatEf === null || rowData.dateEtatEf === '') {
                        items.push({
                            label: 'Valider',
                            icon: 'pi pi-check',
                            command: (e) => {
                                rowData.etatEf = 'Validé';
                                this.update_etat_entree_fond(rowData);
                            }
                        });
                    }
                }
            }

            if (rowData.etatEf == 'En attente' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_entree_fond(rowData);
                    }
                });
                items.push({
                    label: 'Valider',
                    icon: 'pi pi-check',
                    command: (e) => {
                        rowData.etatEf = 'Validé';
                        this.update_etat_entree_fond(rowData);
                    }
                });
            } else if (rowData.etatEf == 'En attente' && rowData.idBoutiqSrce.idBoutiq === this.state.userconnected.matrPers.idBoutiq.idBoutiq) {
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_entree_fond(rowData);
                    }
                });
                items.push({
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_entree_fond(rowData);
                    }
                });
            } else if (rowData.etatEf == 'En attente' && rowData.idBoutiq.idBoutiq === this.state.userconnected.matrPers.idBoutiq.idBoutiq) {
                items.push({
                    label: 'Valider',
                    icon: 'pi pi-check',
                    command: (e) => {
                        rowData.etatEf = 'Validé';
                        this.update_etat_entree_fond(rowData);
                    }
                });
            }
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <SplitButton label="" onClick={(e) => this.edit_entree_fond(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

        /*
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_entree_fond(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_entree_fond(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
        */
    }


    delete_entree_fond() {
        if (this.state.selectedEntreeFond == null || this.state.selectedEntreeFond == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.delete_entree_fond(this.state.selectedEntreeFond).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_entree_fond(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedEntreeFond: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    } 

    update_etat_entree_fond(data) {
        data.etatEf = 'Validé';
        this.setState({ showindicator: true });
        this.xulaservice.valider_entree_fond(data).then(data => this.resultat(data.code, data.contenu));
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_entree_fond();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        let liste_lib_ef_rech = [{ value: 'Tous', label: 'Type' }];
        liste_lib_ef_rech = liste_lib_ef_rech.concat(this.state.liste_lib_ef);

        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>

        let header_print = <Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt.exportCSV()} />

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="montantEf">Type *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown id="libEf" options={this.state.liste_lib_ef} autoWidth={false} value={this.state.libEf} onChange={(e) => this.setState({ libEf: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="montantEf">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.montantEf} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ montantEf: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                {this.state.libEf === 'Transfert fond' ?
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="montantEf">Boutique débiteur *</label>
                                    </div>
                                    : null}
                                {this.state.libEf === 'Transfert fond' ?
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.lboutique} value={this.state.idBoutiqSrce} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                            onChange={(e) => {
                                                this.setState({ idBoutiqSrce: e.value });
                                            }} autoWidth={false} />
                                    </div>
                                    : null}

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="montantEf">Boutique bénéficiaire *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.lboutique_dest} value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                        onChange={(e) => {
                                            this.setState({ idBoutiq: e.value });
                                            if (this.state.libEf !== 'Transfert fond') {
                                                this.setState({ idBoutiqSrce: e.value });
                                            }
                                        }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="desEf">Commentaire </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="desEf" value={this.state.desEf} onChange={(e) => this.setState({ desEf: e.target.value, desEf: e.target.value })} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_entree_fond()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_entree_fond()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <div className="p-col-12">
                                <i className="fa fa-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>

                                <div className="p-grid" style={{ marginTop: 4 }}>
                                    <div className="p-col-12 p-md-2">
                                        <Calendar id="rdate1" placeholder="" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <Calendar id="rdate2" dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={liste_lib_ef_rech} autoWidth={false} value={this.state.rlibEf} placeholder='Type' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rlibEf: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.lboutique_rech} autoWidth={false} value={this.state.ridBoutiqSrce} placeholder='Boutique source' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiqSrce: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <Dropdown options={this.state.lboutique_rech} autoWidth={false} value={this.state.ridBoutiqDest} placeholder='Boutique destination' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiqDest: e.value })} />
                                    </div>

                                    <div className='p-col-12 p-md-2'>
                                        <div className="p-inputgroup">
                                            <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_entree_fond()} />
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <DataTable value={this.state.liste_entree_fond}
                                paginatorPosition="top" paginator={true} rows={10} selectionMode="single"
                                onRowClick={e => this.setState({ selectedEntreeFond: e.data })} rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                responsive={true} selection={this.state.selectedEntreeFond} paginatorRight={ptotal} paginatorLeft={header_print}
                                csvSeparator=";"
                                ref={(el) => { this.dt = el; }}
                            >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="dateEf" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="etatEf" body={(rowData, column) => {
                                    let etat = rowData.etatEf;
                                    if (etat === 'Validé') {
                                        etat = etat + ' le ' + rowData.dateEtatEf;
                                    }
                                    return <div>
                                        <span style={{ color: '#000000' }}>{etat}</span>
                                    </div>
                                }} header="Etat" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libEf" header="Type" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantEf" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.montantEf))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiqSrce.nomBoutiq" header="Débiteur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} />
                                <Column field="idBoutiq.nomBoutiq" header="Bénéficiaire" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} />
                                <Column field="desEf" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '21%' }} />
                                <Column field="statutSyncEf" body={(rowData, column) => {
                                    if (rowData.statutSyncEf == 'Envoyé') {
                                        return <div>
                                            <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                        </div>
                                    } else {
                                        return <div>
                                            <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                        </div>
                                    }
                                }} header="Sync" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />


                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}