import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { Panel } from 'primereact/components/panel/Panel';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Link } from 'react-router-dom'; 

export class DashboardV3 extends Component {

    constructor() {
        super();
        this.state = {
            total_dep: 0,
            total_encaisse: 0,
            solde_jrn: 0,
            total_credit: 0,
            total_du: 0,
            total_gain: 0,
            total_dep_anne: 0,
            total_vente_annee: 0,
            total_om: 0,
            total_momo: 0,
            total_non_livre_btq: '0',
            total_non_livre_intermag: '0',

            stotal_dep: '0',
            stotal_du_client: '0',
            stotal_encaisse: '0',
            ssolde_jrn: '0',
            ssolde_gnle: '0',
            stotal_credit: '0',
            color_soldegnle: 'white',
            color_rentabilite: 'black',
            disable_etabl: true,
            showindicator: false,

            visible_dlg_alerte: false,

            stotal_du: '0',
            stotal_gain: '0',

            stotal_rentabilite: '0',

            liste_article: [],
            liste_boutique: [],
            topNliste_article: [],
            liste_article_seuil: [],
            liste_non_regle: [],
            liste_peremption: [],
            liste_vente_mois: [],
            liste_situation_boutique: [],
            liste_st_dep: [],
            idBoutiq: { label: 'Toutes les boutiques', value: '-1' },
            liste_delai_pay_sortie: [],
            nbseuil_atteint: 0,
            valeur_marchand: 0,
            svaleur_marchand: '0',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

            stotal_credit_global: '0',
            stotal_gain_annee: '0',
            stotal_depense_annee: '0',
            stotal_vente_annee: '0',
            stotal_rgl_vente_annee: '0',
            stotal_cmde_annee: '0',
            staux_recouvrement_annee: '0',
            stotal_non_livre: '0',

            stotal_du_interne: '0',
            stotal_du_four: '0',
            stotal_credit_interne: '0',
            rannee: moment(new Date()).format('YYYY'),
            visible_dlg_detail: false,
            liste_data_detail: [],
            fm_detail: '',

        };
        this.xulaservice = new Xulaservice();
        this.show_dlg_detail = this.show_dlg_detail.bind(this);
        if (this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur') {
            this.setState({ disable_etabl: false });
        }
    }

    show_dlg_detail(fm_detail) {
        this.setState({ visible_dlg_detail: true });
        this.setState({ fm_detail: fm_detail });
        if (fm_detail == 'TT_RCV') {
            this.get_detail_recouvrement();
        } else if (fm_detail == 'TT_DU_CLI') {
            this.get_detail_ttdu_client();
        } else if (fm_detail == 'TT_NL_BTQ') {
            this.get_detail_tt_nl_btq();
        } else if (fm_detail == 'TT_NL_INTERMAG') {
            this.get_detail_tt_nl_intermag();
        } else if (fm_detail == 'TT_NL_ALL') {
            this.get_detail_tt_nl_all();
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_detail_tt_nl_btq() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'BTQ').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_tt_nl_intermag() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'INTERMAG').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_tt_nl_all() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_tt_non_livre(this.state.idBoutiq, 'Tous', 'Tous').then(data => this.setState({ liste_non_regle: data, showindicator: false }));
    }
    get_detail_ttdu_client() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_ttdu_client(this.state.idBoutiq, 'Tous').then(data => this.setState({ liste_data_detail: data, showindicator: false }));
    }

    get_detail_recouvrement() {
        this.setState({ showindicator: true });
        this.xulaservice.get_detail_recouvrement(this.state.idBoutiq, 'Tous').then(data => this.setState({ liste_data_detail: data, showindicator: false }));
    }

    compare(a, b) {
        const mtA = a.montant;
        const mtB = b.montant;
        let comparison = 0;
        if (mtA > mtB) {
            comparison = 1;
        } else if (mtA < mtB) {
            comparison = -1;
        }
        return comparison;
    }

    charger_dashboard(idBoutiq, annee, show) {
        this.setState({ stotal_credit: '0' });
        this.setState({ stotal_dep: '0' });
        this.setState({ ssolde_jrn: '0' });
        this.setState({ stotal_du: '0' });
        this.setState({ stotal_encaisse: '0' });
        this.setState({ stotal_gain: '0' });
        this.setState({ liste_article: [] });
        this.setState({ liste_article_seuil: [] });
        this.setState({ liste_delai_pay_sortie: [] });
        this.setState({ liste_vente_mois: [] });
        this.setState({ liste_peremption: [] });
        this.setState({ liste_st_dep: [] });
        this.setState({ liste_situation_boutique: [] });
        this.setState({ svaleur_marchand: '0' });
        this.setState({ stotal_credit_global: '0' });
        this.setState({ stotal_non_livre: '0' });
        this.setState({ staux_recouvrement_annee: '0' });
        this.setState({ stotal_cmde_annee: '0' });
        this.setState({ stotal_rgl_vente_annee: '0' });
        this.setState({ stotal_vente_annee: '0' });
        this.setState({ stotal_depense_annee: '0' });
        this.setState({ stotal_gain_annee: '0' });
        this.setState({ color_soldegnle: 'white' });
        this.setState({ stotal_du_interne: '0' });
        this.setState({ stotal_du_four: '0' });
        this.setState({ stotal_credit_interne: '0' });
        this.setState({ ssolde_gnle: '0' });
        this.setState({ stotal_du_client: '0' });
        this.setState({ stotal_rentabilite: '0' });
        this.setState({ color_rentabilite: 'black' });
        this.setState({ total_om: '0' });
        this.setState({ total_momo: '0' });
        this.setState({ total_non_livre_btq: '0' });
        this.setState({ total_non_livre_intermag: '0' });

        this.setState({ showindicator: true });
        this.xulaservice.get_dashboard(idBoutiq, annee).then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ stotal_credit: data.stotal_credit });
                this.setState({ stotal_dep: data.stotal_dep });
                this.setState({ ssolde_jrn: data.ssolde_jrn });
                this.setState({ ssolde_gnle: data.ssolde_gnle });
                this.setState({ stotal_du: data.stotal_du });
                this.setState({ stotal_encaisse: data.stotal_encaisse });
                this.setState({ stotal_gain: data.stotal_gain });
                this.setState({ liste_article: data.liste_article });
                this.setState({ liste_article_seuil: data.liste_article_seuil });
                this.setState({ liste_delai_pay_sortie: data.liste_delai_pay_sortie });
                this.setState({ liste_vente_mois: data.liste_vente_mois });
                this.setState({ liste_peremption: data.liste_peremption });
                this.setState({ liste_st_dep: data.liste_st_dep });
                this.setState({ liste_situation_boutique: data.liste_situation_boutique });
                this.setState({ svaleur_marchand: this.formaterMontant(parseFloat(data.valeur_marchand)) });
                this.setState({ stotal_credit_global: this.formaterMontant(parseFloat(data.total_credit_global)) });
                this.setState({ stotal_non_livre: this.formaterMontant(parseFloat(data.total_non_livre)) });
                this.setState({ staux_recouvrement_annee: data.taux_recouvrement_annee });
                this.setState({ stotal_cmde_annee: this.formaterMontant(parseFloat(data.total_cmde_annee)) });
                this.setState({ stotal_rgl_vente_annee: this.formaterMontant(parseFloat(data.total_rgl_vente_annee)) });
                this.setState({ stotal_vente_annee: this.formaterMontant(parseFloat(data.total_vente_annee)) });
                this.setState({ stotal_depense_annee: this.formaterMontant(parseFloat(data.total_depense_annee)) });
                this.setState({ stotal_gain_annee: this.formaterMontant(parseFloat(data.total_gain_annee)) });
                this.setState({ stotal_du_client: this.formaterMontant(parseFloat(data.total_du_client)) });
                this.setState({ total_om: this.formaterMontant(parseFloat(data.total_om)) });
                this.setState({ total_momo: this.formaterMontant(parseFloat(data.total_momo)) });
                this.setState({ total_non_livre_btq: this.formaterMontant(parseFloat(data.total_non_livre_btq)) });
                this.setState({ total_non_livre_intermag: this.formaterMontant(parseFloat(data.total_non_livre_intermag)) });

                if (data.liste_situation_boutique != null && data.liste_situation_boutique != undefined) {
                    let initialValue = 0;
                    let tt = data.liste_situation_boutique.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.rentabiliteBoutiq
                    }, initialValue);
                    this.setState({ stotal_rentabilite: this.formaterMontant(tt) });
                    if (tt > 0) {
                        this.setState({ color_rentabilite: 'green' });
                    } else {
                        this.setState({ color_rentabilite: 'red' });
                    }
                }

                let visible = false;
                if (data.liste_peremption !== undefined) {
                    if (data.liste_peremption.length > 0) {
                        visible = true;
                    }
                }
                if (data.liste_delai_pay_sortie !== undefined) {
                    if (data.liste_delai_pay_sortie.length > 0) {
                        visible = true;
                    }
                }
                if (data.liste_article_seuil !== undefined) {
                    if (data.liste_article_seuil.length > 0) {
                        visible = true;
                    }
                }
                if (visible == true && show == true) {
                    this.setState({ visible_dlg_alerte: visible });
                }

                if (data.solde_gnle < 0) {
                    this.setState({ color_soldegnle: '#ff0303' });
                } else if (data.solde_gnle > 0) {
                    this.setState({ color_soldegnle: '#35ab13' });
                }
                this.setState({ stotal_du_interne: this.formaterMontant(parseFloat(data.total_du_interne)) });
                this.setState({ stotal_du_four: this.formaterMontant(parseFloat(data.total_du_four)) });
                this.setState({ stotal_credit_interne: this.formaterMontant(parseFloat(data.total_credit_interne)) });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        let lboutique = [{ value: '-1', label: 'Toutes les boutiques' }];
        if (this.state.userconnected.profilUser == 'Directeur') {
            this.xulaservice.getListe_boutique_gerer().then(data => {
                this.setState({ liste_boutique: data }, () => {
                    if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                        let liste = this.state.liste_boutique.map(x => {
                            return { value: x.idBoutiq, label: x.nomBoutiq }
                        });
                        lboutique = lboutique.concat(liste);
                    }
                });
                this.setState({ lboutique: lboutique }, () => {
                    this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                });
            });
        } else {
            this.xulaservice.getListe_boutique().then(data => {
                this.setState({ liste_boutique: data }, () => {
                    if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                        let liste = this.state.liste_boutique.map(x => {
                            return { value: x.idBoutiq, label: x.nomBoutiq }
                        });
                        lboutique = lboutique.concat(liste);
                    }
                });
                this.setState({ lboutique: lboutique }, () => {
                    this.setState({ idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq }, () => {
                        this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true);
                    });
                });
            });
        }

    }


    show_detail() {
        if (this.state.fm_detail == 'TT_RCV') {
            return <DataTable value={this.state.liste_data_detail}
                paginatorPosition="top" paginator={true} rows={15} rowsPerPageOptions={[10, 15, 20]} paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtg.exportCSV()} />}
                responsive={true} csvSeparator=";" ref={(el) => { this.dtg = el; }} paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            >
                <Column field="nomCli" header="Client" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_entree" body={(rowData, column) => {
                    return <div>
                        <span>{this.formaterMontant(rowData.total_entree)}</span>
                    </div>
                }} header="Dépots" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_sortie" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(rowData.total_sortie)}</span>
                    </div>
                }} header="Retraits" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_rest_facture" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(rowData.total_rest_facture)}</span>
                    </div>
                }} header="Restant facture" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="balance" body={(rowData, column) => {
                    return <div>
                        <span style={{ fontWeight: 'bold', color: 'red' }}>{this.formaterMontant(rowData.balance)}</span>
                    </div>
                }} header="Balance" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>
        } else if (this.state.fm_detail == 'TT_DU_CLI') {
            return <DataTable value={this.state.liste_data_detail}
                paginatorPosition="top" paginator={true} rows={15} rowsPerPageOptions={[10, 15, 20]} paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtg.exportCSV()} />}
                responsive={true} csvSeparator=";" ref={(el) => { this.dtg = el; }} paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            >
                <Column field="nomCli" header="Client" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_depot" body={(rowData, column) => {
                    return <div>
                        <span>{this.formaterMontant(rowData.total_depot)}</span>
                    </div>
                }} header="Dépots" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_emprunt" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(rowData.total_emprunt)}</span>
                    </div>
                }} header="Emprunts" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_retrait" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(rowData.total_retrait)}</span>
                    </div>
                }} header="Retrait" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="total_rgl_facture" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(rowData.total_rgl_facture)}</span>
                    </div>
                }} header="Règlements" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="balance" body={(rowData, column) => {
                    return <div>
                        <span style={{ fontWeight: 'bold' }}>{this.formaterMontant(rowData.balance)}</span>
                    </div>
                }} header="Balance" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>
        } else {
            return <DataTable value={this.state.liste_non_regle} paginatorPosition="top" paginator={true} rows={5}
                rowsPerPageOptions={[5, 10, 20]} responsive={true} csvSeparator=";" ref={(el) => { this.dtg = el; }}
                paginatorLeft={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dtg.exportCSV()} />}
                paginatorRight={this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}>
                <Column field="numFactureSortie" header="Facture" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
                        <span >{rowData.datesortie}</span>
                    </div>
                }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="libArt" header="Désignation" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="nomCli" header="Client" body={(rowData, column) => {
                    return <div>
                        <span >{rowData.nomCli}</span><br />
                        <span style={{ color: '#45535F', fontWeight: 'bold' }}>{rowData.idBoutiq.codeBoutiq}</span>
                    </div>
                }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="prix_ref_vente" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(parseFloat(rowData.prix_ref_vente))}</span>
                    </div>
                }} header="Prix vente" filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="qte_a_livrer" header="Qté non livrée" filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="valeur_qte_a_livrer" body={(rowData, column) => {
                    return <div>
                        <span >{this.formaterMontant(parseFloat(rowData.valeur_qte_a_livrer))}</span>
                    </div>
                }} header="Valeur non livré" filter={true} filterMatchMode='contains' sortable={true} />

            </DataTable>
        }
    }

    render_situation() {
        if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Directeur') {
            if (this.state.liste_boutique !== undefined && this.state.liste_boutique !== null) {

                return <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Panel style={{ minHeight: '320px' }}>
                            <i className="fa fa-fw fa-bar-chart"></i>Situation générale
                            <hr />

                            <div className="p-grid">
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-shopping-cart" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>CA année</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: '#000', fontWeight: 'bold' }}>{this.state.stotal_vente_annee}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-briefcase" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Gain année</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }}>{this.state.stotal_gain_annee}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-credit-card" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Charges année</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }}>{this.state.stotal_depense_annee}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-briefcase" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Rentabilité année</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: this.state.color_rentabilite, fontWeight: 'bold' }}>{this.state.stotal_rentabilite}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-calculator" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Valeur marchande</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: '#000', fontWeight: 'bold' }}>{this.state.svaleur_marchand}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-bell" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total à recouvrer</label>
                                        </center>
                                        <center>
                                            <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_RCV')}><label style={{ fontSize: 18, color: 'red', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_credit_global}</label></Link>

                                        </center>
                                    </Panel>
                                </div>

                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-check-square-o" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total Non livré</label>
                                        </center>
                                        <center>
                                            <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_ALL')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_non_livre}</label></Link>
                                        </center>
                                    </Panel>
                                </div>


                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-home" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total dû client</label>
                                        </center>
                                        <center>
                                            <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_DU_CLI')}><label style={{ fontSize: 18, color: 'green', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_du_client}</label></Link>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-home" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Recouvr. interne</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }}>{this.state.stotal_credit_interne}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-home" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total dû interne</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }}>{this.state.stotal_du_interne}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total fournisseur</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }}>{this.state.stotal_du_four}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-briefcase" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Gain jour</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }}>{this.state.stotal_gain}</label>
                                        </center>
                                    </Panel>
                                </div>

                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-check-square-o" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Non livré boutique</label>
                                        </center>
                                        <center>
                                            <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_BTQ')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.total_non_livre_btq}</label></Link>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-check-square-o" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Non livré inter-MAG</label>
                                        </center>
                                        <center>
                                            <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_INTERMAG')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.total_non_livre_intermag}</label></Link>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total OM</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: '#ff7900', fontWeight: 'bold' }}>{this.state.total_om}</label>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="p-col-6 p-sm-3 p-md-3">
                                    <Panel >
                                        <center>
                                            <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total Momo</label>
                                        </center>
                                        <center>
                                            <label style={{ fontSize: 18, color: '#F5D004', fontWeight: 'bold' }}>{this.state.total_momo}</label>
                                        </center>
                                    </Panel>
                                </div>
                            </div>

                        </Panel>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Panel style={{ minHeight: '320px' }}>
                            <i className="fa fa-fw fa-calculator"></i>Situation des boutiques
                            <hr />
                            <DataTable value={this.state.liste_situation_boutique} scrollHeight="230px"
                                scrollable={true} >
                                <Column field="nomBoutiq" header="Boutique" />
                                <Column header="Non livré" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.vnlBoutiq)}</span>
                                    </div>
                                }} />
                                <Column header="Marges" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ttgainBoutiq)}</span>
                                    </div>
                                }} />
                                <Column header="Charges" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000' }}>{this.formaterMontant(rowData.ttdepenseBoutiq)}</span>
                                    </div>
                                }} />
                                <Column header="Rentabilité" body={(rowData, column) => {
                                    let color = 'green';
                                    if (parseFloat(rowData.rentabiliteBoutiq) < 0) {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color, fontWeight: 'bold' }}>{this.formaterMontant(rowData.rentabiliteBoutiq)}</span>
                                    </div>
                                }} />

                            </DataTable>

                        </Panel>
                    </div>
                </div>

            } else {
                return <div className="p-col-12">
                    <Panel style={{ minHeight: '70px' }}>
                        <i className="fa fa-fw fa-bar-chart"></i>Situation générale
                        <hr />

                        <div className="p-grid">
                            <div className="p-col-6 p-sm-6 p-md-3">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-shopping-cart" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>CA année</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.state.stotal_vente_annee}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-2">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-briefcase" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Gain année</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: 'green', fontWeight: 'bold' }}>{this.state.stotal_gain_annee}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-2">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-calculator" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Valeur marchande</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.state.svaleur_marchand}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-2">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-bell" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Total à recouvrer</label>
                                    </center>
                                    <center>
                                        <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_RCV')}><label style={{ fontSize: 18, color: 'red', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_credit_global}</label></Link>
                                    </center>
                                </Panel>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-2">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total Orange Money</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 18, color: '#ff7900', fontWeight: 'bold' }}>{this.state.total_om}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-2">
                                <Panel >
                                    <center>
                                        <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total Momo</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 18, color: '#F5D004', fontWeight: 'bold' }}>{this.state.total_momo}</label>
                                    </center>
                                </Panel>
                            </div>
                        </div>

                    </Panel>
                </div>
            }
        } else if (this.state.userconnected.profilUser === 'Magasinier') {
            return <>
            </>
        } else return <div className="p-col-12">
            <Panel >
                <i className="fa fa-fw fa-bar-chart"></i>Situation générale
                <hr />

                <div className="p-grid">

                    <div className="p-col-6 p-sm-6 p-md-3">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-bell" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Total à recouvrer</label>
                            </center>
                            <center>
                                <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_RCV')}><label style={{ fontSize: 18, color: 'red', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_credit_global}</label></Link>
                            </center>
                        </Panel>
                    </div>

                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-credit-card" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Charges année</label>
                            </center>
                            <center>
                                <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }}>{this.state.stotal_depense_annee}</label>
                            </center>
                        </Panel>
                    </div>

                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-home" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Recouvrement interne</label>
                            </center>
                            <center>
                                <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }}>{this.state.stotal_credit_interne}</label>
                            </center>
                        </Panel>
                    </div>
                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-home" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Total dû interne</label>
                            </center>
                            <center>
                                <label style={{ fontSize: 18, color: 'red', fontWeight: 'bold' }}>{this.state.stotal_du_interne}</label>
                            </center>
                        </Panel>
                    </div>

                    <div className="p-col-6 p-sm-6 p-md-3">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-users" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Total dù client</label>
                            </center>
                            <center>
                                <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_DU_CLI')}><label style={{ fontSize: 18, color: 'red', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_du_client}</label></Link>
                            </center>
                        </Panel>
                    </div>
                    <div className="p-col-6 p-sm-6 p-md-3">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-cube" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Total Non livré</label>
                            </center>
                            <center>
                                <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_ALL')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.stotal_non_livre}</label></Link>
                            </center>
                        </Panel>
                    </div>

                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-cube" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Non livré boutique</label>
                            </center>
                            <center>
                                <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_BTQ')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.total_non_livre_btq}</label></Link>
                            </center>
                        </Panel>
                    </div>
                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-cube" style={{ fontSize: 16, color: '#C9C9C8' }}></i><label style={{ fontSize: 16, color: '#C9C9C8' }}>Non livré interMAG</label>
                            </center>
                            <center>
                                <Link to='/dashboard' onClick={(e) => this.show_dlg_detail('TT_NL_INTERMAG')}><label style={{ fontSize: 18, color: 'black', fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.total_non_livre_intermag}</label></Link>
                            </center>
                        </Panel>
                    </div>
                    <div className="p-col-6 p-sm-6 p-md-2">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total OM</label>
                            </center>
                            <center>
                                <label style={{ fontSize: 18, color: '#ff7900', fontWeight: 'bold' }}>{this.state.total_om}</label>
                            </center>
                        </Panel>
                    </div>
                    <div className="p-col-6 p-sm-6 p-md-3">
                        <Panel >
                            <center>
                                <i className="fa fa-fw fa-user" style={{ fontSize: 14, color: '#C9C9C8' }}></i><label style={{ fontSize: 14, color: '#C9C9C8' }}>Total Momo</label>
                            </center>
                            <center>
                                <label style={{ fontSize: 18, color: '#F5D004', fontWeight: 'bold' }}>{this.state.total_momo}</label>
                            </center>
                        </Panel>
                    </div>


                </div>

            </Panel>
        </div>

    }

    render() {
        let liste_label_stat_vente = null;
        let liste_ds_stat_vente_a = null;
        let liste_ds_stat_vente_am1 = null;
        if (this.state.liste_vente_mois != null && this.state.liste_vente_mois != undefined) {
            if (this.state.liste_vente_mois.length > 0) {
                liste_label_stat_vente = this.state.liste_vente_mois.map(x => {
                    return x.lib
                });
                liste_ds_stat_vente_a = this.state.liste_vente_mois.map(x => {
                    return x.dval2
                });
                liste_ds_stat_vente_am1 = this.state.liste_vente_mois.map(x => {
                    return x.dval
                });
            }
        }

        const chart_data_stat_vente = {
            labels: liste_label_stat_vente,
            datasets: [
                {
                    label: 'Ventes année en cours',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: false,
                    data: liste_ds_stat_vente_a
                },
                {
                    label: 'Ventes année précédante',
                    backgroundColor: '#d87a3c',
                    borderColor: '#d87a3c',
                    fill: false,
                    data: liste_ds_stat_vente_am1
                }
            ]
        };

        let liste_label_top_prod = null;
        let liste_ds_top_prod = null;
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            if (this.state.liste_article.length > 0) {
                let liste = this.state.liste_article;
                liste.sort(this.compare);
                let items = liste.slice(0, 10);
                liste_label_top_prod = items.map(x => {
                    return x.libArt
                });

                liste_ds_top_prod = items.map(x => {
                    return x.montant
                });
            }
        }

        const chart_hdata_topProd = {
            labels: liste_label_top_prod,
            datasets: [
                {
                    label: 'Top 10 prdouits',
                    backgroundColor: '#4071a7',
                    data: liste_ds_top_prod
                }
            ]
        };
 

        return <div className="p-grid dashboard">
            <Dialog header="Détails" visible={this.state.visible_dlg_detail} style={{ width: '70%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_detail: false })}>
                <div className="card card-w-title" style={{ minHeight: '400px' }}>
                    {this.show_detail()}
                </div>
            </Dialog>

            <Dialog header="Alerte" visible={this.state.visible_dlg_alerte} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_alerte: false })} >
                <ScrollPanel style={{ width: '100%', height: '550px' }}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Panel >

                                {this.state.liste_peremption !== null && this.state.liste_peremption !== undefined ?
                                    <>
                                        {this.state.liste_peremption.length > 0 ?
                                            <div className="p-col-12" style={{ marginBottom: -15 }}>
                                                <i className="fa fa-fw fa-bell"></i>Alerte péremption
                                                <hr />
                                                <DataTable value={this.state.liste_peremption} scrollable={true} scrollHeight="150px"
                                                    responsive={true} style={{ marginBottom: 12 }} ref={(el) => { this.dt_exp = el; }}
                                                    header={<Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt_exp.exportCSV()} />} csvSeparator=";">
                                                    <Column field="libArt" header="Article" sortable={true} filter={true} filterMatchMode='contains' />
                                                    <Column field="date_exp_article" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.date_exp_article}</span>
                                                        </div>
                                                    }} header="Date perem." sortable={true} filter={true} filterMatchMode='contains' style={{ width: '14%' }} />
                                                    <Column field="qte_stock" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.qte_stock}</span>
                                                        </div>
                                                    }} header="Stock" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                                                    <Column fielXd="num_bc" header="BC" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold' }}>{rowData.num_bc}</span>
                                                            <br />
                                                            <span>{rowData.datecmde}</span>
                                                        </div>
                                                    }} sortable={true} filter={true} filterMatchMode='contains' style={{ width: '22%' }} />
                                                </DataTable>
                                            </div>
                                            : null}
                                    </>
                                    : null}

                                {this.state.liste_article_seuil !== null && this.state.liste_article_seuil !== undefined ?
                                    <>
                                        {this.state.liste_article_seuil.length > 0 ?
                                            <div className="p-col-12" style={{ marginBottom: -15 }}>
                                                <i className="fa fa-fw fa-bell"></i>Alerte rupture
                                                <hr />
                                                <DataTable value={this.state.liste_article_seuil} scrollable={true} scrollHeight="150px"
                                                    style={{ marginBottom: 12 }} >
                                                    <Column field="libArt" header="Article" />
                                                    <Column field="qte_stock" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.qte_stock}</span>
                                                        </div>
                                                    }} header="Stock" />
                                                    <Column field="seuil_article" header="Seuil" />
                                                </DataTable>


                                            </div>
                                            : null}
                                    </>
                                    : null}


                                {this.state.liste_delai_pay_sortie !== null && this.state.liste_delai_pay_sortie !== undefined ?
                                    <>
                                        {this.state.liste_delai_pay_sortie.length > 0 ?
                                            <div className="p-col-12" style={{ marginBottom: -15 }}>
                                                <i className="fa fa-fw fa-bell"></i>Alerte payement
                                                <hr />
                                                <DataTable value={this.state.liste_delai_pay_sortie} scrollable={true} scrollHeight="150px"
                                                    style={{ marginBottom: 12 }} >
                                                    <Column field="idCli.nomCli" header="Client" />
                                                    <Column field="numFactureSortie" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span>
                                                            <br />
                                                            <span style={{}}>{rowData.dateSortie_format}</span>
                                                        </div>
                                                    }} header="N° facture" />
                                                    <Column field="mt_restant" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format}</span>
                                                        </div>
                                                    }} header="Restant" />

                                                </DataTable>

                                            </div>
                                            : null}
                                    </>
                                    : null}





                            </Panel>
                        </div>
                    </div>
                </ScrollPanel> 
            </Dialog>

            <div className="p-col-12" style={{ marginTop: -10 }}>
                <div className="p-grid" style={{ float: "right", width: 350, marginBottom: -5, marginTop: -10 }}>
                    <div className="p-col">
                        <div className="p-inputgroup">
                            <Dropdown placeholder="Toutes les boutiques" style={{ width: 220, marginBottom: -8 }} value={this.state.idBoutiq} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.lboutique} onChange={(e) => {
                                this.setState({ idBoutiq: e.value }, () => {
                                    this.charger_dashboard(e.value, this.state.rannee, false);
                                });
                            }
                            } disabled={this.state.userconnected.profilUser != 'PDG' && this.state.userconnected.profilUser != 'Coordinateur' && this.state.userconnected.profilUser !== 'Directeur'} />
                            <Button type="button" style={{ marginBottom: -8, width: '30px' }} icon="pi pi-refresh" className="p-button-info" onClick={() => this.charger_dashboard(this.state.idBoutiq, this.state.rannee, true)} />
                            <Button id="bt_alerte" type="button" style={{ marginBottom: -8, width: '30px' }} icon="pi pi-bell" className="p-button-warning" onClick={() => this.setState({ visible_dlg_alerte: true })} />

                        </div>
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>

            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-2"><h1>Encaissements du jour</h1>
                    <div className="overview-value">{this.state.stotal_encaisse}</div>

                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-3">
                    <h1>Sortie de caisse du jour</h1>
                    <div className="overview-value">{this.state.stotal_dep}</div>

                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-1">
                    <h1>Solde journalier</h1>
                    <div className="overview-value">{this.state.ssolde_jrn}</div>

                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-4">
                    <h1>Solde général</h1>
                    <div className="overview-value" style={{ color: this.state.color_soldegnle }}>{this.state.ssolde_gnle}</div>
                    <img src="assets/layout/images/dashboard/graph-blue-2.png" alt="apollo-layout" />

                </div>
            </div>

            {this.render_situation()}

            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-shopping-cart"></i>Ventes du jour par article
                    <hr />
                    <DataTable value={this.state.liste_article} selectionMode="single" scrollable={true} scrollHeight="300px"
                    >
                        <Column field="libArt" filter={true} filterMatchMode='contains' sortable={true} header="Désignation" style={{ width: '57%' }} />
                        <Column field="qte" filter={true} filterMatchMode='contains' header="Qté" sortable={true} style={{ width: '18%' }} />
                        <Column field="smontant" header="Montant" filter={true} filterMatchMode='Montant' sortable={true} style={{ width: '25%' }} />
                    </DataTable>

                </Panel>
            </div>

            <div className="p-col-12 p-md-6">
                <Panel style={{ minHeight: '445px' }}>
                    <i className="fa fa-fw fa-bar-chart"></i>Top vente   dfdf article du jour
                    <hr />
                    <Chart type="horizontalBar" data={chart_hdata_topProd} height="160w" />
                </Panel>
            </div>

            {this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Directeur' ?
                <div className="p-col-12">
                    <Panel className="no-pad">
                        <i className="fa fa-fw fa-line-chart"></i>Statistiques des ventes par année
                        <hr />
                        <div className="content-section implementation">
                            <Chart responsive={true} type="line" data={chart_data_stat_vente} height="120w" />
                        </div>
                    </Panel>
                </div>
                : null}

        </div>
    }
}