import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';
import { ScrollPanel } from 'primereact/scrollpanel';

export class FormReap extends Component {
    constructor() {
        super();
        this.state = {
            libArticle: '',
            qte_restant: 0,
            idCmde: 0,
            qteReap: 0,
            liste_reap: [],
            pd1: new Date(),
            pd2: new Date(),
            dateReap: new Date(),
            ridArticle: null,
            ridBoutiq: null,
            rnum_cmde: null,
            liste_lcmde: [],
            liste_boutique: [],
            liste_article: [],
            lboutique_rech: [],
            selectedReap: null,
            selectedLcmde: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_new_reap: false,
            visible_dlg_modif_reap: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        //this.edit_reap = this.edit_reap.bind(this);
        this.conf_delete_reap = this.conf_delete_reap.bind(this);


        this.show_new_reap_dlg = this.show_new_reap_dlg.bind(this);
        this.show_modif_reap_dlg = this.show_modif_reap_dlg.bind(this);


        this.myservice = new Xulaservice();
    }

    getListe_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);

            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }


    componentDidMount() {
        this.getListe_boutique();
        this.get_liste_reap();
        this.get_liste_article(this.state.userconnected.matrPers.idBoutiq.idBoutiq);
        this.get_liste_lcmde_for_reap();
    }



    get_liste_reap() {
        let rid_article = 'Tous';
        let rid_boutique = 'Tous';
        let rnum = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridArticle != undefined || this.state.ridArticle != null) {
            rid_article = this.state.ridArticle.value;
        }

        if (this.state.rnum_cmde != undefined || this.state.rnum_cmde != null) {
            rnum = this.state.rnum_cmde;
        }
        this.myservice.get_liste_reap(spd1, spd2, rnum, rid_boutique, rid_article).then(data => this.setState({ liste_reap: data, showindicator: false }));
    }

    get_liste_article(idBoutiq) {

        this.myservice.get_inventaire('Tous', 'Tous', 'Tous', 'Tous', idBoutiq).then(data => this.setState({ liste_article: data }));
        /*  let lprod = null;
         this.myservice.get_inventaire('Tous', 'Tous', 'Tous', 'Tous', idBoutiq).then(data => this.setState({ liste_article: data }, () => {
             if (this.state.liste_article != null && this.state.liste_article != undefined) {
                 lprod = this.state.liste_article.map(x => {
                     return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: x.qte_stock_format, prix_ref_vente_format: x.prix_ref_vente_format, prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: x.prix_ref_achat_format, idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, prix_except: x.prix_except }
                 })
 
                 this.setState({ lprod: lprod });
             }
         })); */
    }


    get_liste_lcmde_for_reap() {
        this.myservice.get_liste_lcmde_for_reap().then(data => this.setState({ liste_lcmde: data }));
    }

    get_liste_boutique() {
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }));
    }



    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reap_dlg();
            this.setState({ liste_lcmde: [] });
            this.get_liste_reap();
            this.get_liste_lcmde_for_reap();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    annule_reap_dlg() {
        this.setState({ visible_dlg_new_reap: false });
    }


    enreg_reap() {
        if (this.state.selectedLcmde == null || this.state.selectedLcmde == undefined || this.state.qteReap == '' || this.state.qteReap == undefined || this.state.qteReap === '0') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif === false) {
                if (this.state.dateReap === null || this.state.dateReap === undefined || this.state.dateReap === '') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez indiquer la date' });
                    return;
                }
                let date = moment(this.state.dateReap).format('DD/MM/YYYY');
                let data = { idReap: '0', qteReap: this.state.qteReap, idLcmdeReap: { idLcmde: this.state.selectedLcmde.idlcmde }, dateReap: date, idBoutiq: { idBoutiq: this.state.selectedLcmde.idCmde.idBoutiq.idBoutiq } };
                this.myservice.enreg_reap(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedReap;
                data.qteReap = this.state.qteReap;
                this.myservice.enreg_reap(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate_reap(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_reap(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_new_reap(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.show_new_reap_dlg(rowData)} className="p-button-secondary" style={{ width: 25, height: 25 }} icon="fa fa-fw fa-arrow-right" /></div>
        </div>
    }

    delete_reap() {
        if (this.state.selectedReap == null || this.state.selectedReap == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_reap(this.state.selectedReap).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_reap(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedReap: data });
            this.setState({ msg: data.idLcmdeReap.idArticle.libArticle + ' , N° BC: ' + data.idLcmdeReap.idCmde.numCmde });
            this.setState({ visible: true });
        }
    }


    show_new_reap_dlg(data) {
        this.setState({ visible_dlg_new_reap: true });
        this.setState({ qteReap: data.qte_restant });
        this.setState({ modif: false });
    }

    show_modif_reap_dlg() {
        this.setState({ modif_lcmde: false }, () => {
            this.setState({ visible_dlg_lcmde: true });
            this.setState({ disable_prod: false });
            this.get_liste_article(this.state.selectedCmde.idBoutiq.idBoutiq);
        })
    }



    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_reap();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let lprod = [];
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            lprod = this.state.liste_article.map(x => {
                return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: x.qte_stock_format, prix_ref_vente_format: x.prix_ref_vente_format, prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: x.prix_ref_achat_format, idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, prix_except: x.prix_except }
            })
        }
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>



                <Dialog header="Nouvel appro" visible={this.state.visible_dlg_new_reap} style={{ width: '500px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_new_reap: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Article</label>
                                    </div>
                                    {this.state.selectedLcmde != null && this.state.selectedLcmde != undefined ?
                                        <div className="p-col-12 p-md-8">
                                            <label style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state.selectedLcmde.libArt}</label>
                                        </div> : null}


                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Quantité disponible </label>
                                    </div>

                                    {this.state.selectedLcmde != null && this.state.selectedLcmde != undefined ?
                                        <div className="p-col-12 p-md-8">
                                            <label style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>{this.state.selectedLcmde.qte_restant}</label>
                                        </div> : null}

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Quantité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText keyfilter="pint" value={this.state.qteReap} onChange={(e) => this.setState({ qteReap: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="profile">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Calendar value={this.state.dateReap} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateReap: e.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_new_reap: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reap()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >


                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 700 }}>
                                                <DataTable value={this.state.liste_lcmde} selectionMode="single" scrollable={true} scrollHeight="200px"
                                                    onRowClick={e => this.setState({ selectedLcmde: e.data })} onRowSelect={e =>
                                                        this.setState({ selectedLcmde: e.data })
                                                    }
                                                    responsive={true} selection={this.state.selectedLcmde} >
                                                    <Column header="#" body={this.actionTemplate_new_reap.bind(this)} style={{ width: '8%' }} />
                                                    <Column field="libArt" filter={true} filterMatchMode='contains' sortable={true} header="Article" style={{ width: '38%' }} />
                                                    <Column field="idCmde.numCmde" filter={true} filterMatchMode='contains' sortable={true} header="N° BC" style={{ width: '20%' }} />
                                                    <Column field="qte" header="Qté cmde" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="qte_restant" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'red' }}>{rowData.qte_restant}</span>
                                                        </div>
                                                    }} header="Qté rest." style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 700 }}>
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-4">
                                                                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                                            </div>
                                                            <div className="p-col-12 p-md-4">
                                                                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                                            </div>
                                                            <div className="p-col-12 p-md-4">
                                                                <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                                            </div>
                                                            <div className="p-col-12 p-md-4">
                                                                <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                                                            </div>
                                                            <div className="p-col-12 p-md-4">
                                                                <Dropdown options={lprod} placeholder="Article" value={this.state.ridArticle} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridArticle: e.value })} autoWidth={false} panelStyle={{ width: '450px' }}  />
                                                            </div>
                                                            <div className="p-col-12 p-md-4">
                                                                <div className="p-inputgroup">
                                                                    <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_reap()} />
                                                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ScrollPanel style={{ width: '100%' }}>
                                                    <DataTable value={this.state.liste_reap} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedReap: e.data })} onRowSelect={e => this.setState({ selectedReap: e.data })}
                                                        responsive={true} selection={this.state.selectedReap} style={{ width: '180%' }}>
                                                        <Column header="#" body={this.actionTemplate_reap.bind(this)} style={{ width: '4%' }} />
                                                        <Column field="dateReap" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idLcmdeReap.idArticle.libArticle" header="Article" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="qteReap" header="Qté" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idLcmdeReap.idCmde.numCmde" header="N° BC" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idLcmdeReap.idCmde.typeCmde" header="Type" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idLcmdeReap.idCmde.idFour.nomFour" header="Fournisseur" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idLcmdeReap.idCmde.idFour.typeFour" header="Source" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idBoutiq.nomBoutiq" header="Boutique" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    </DataTable>
                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div >
        );
    }
}