import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Cleave from 'cleave.js/react';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { InputTextarea } from 'primereact/inputtextarea';
import KeyboardEventHandler from 'react-keyboard-event-handler';


export class FormBon extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            debRegl: null,
            dateBon: new Date(),
            matrPers: null,
            rmatrPers: null,
            retatBon: null,
            idBon: '',
            idBoutiq: null,
            lboutique: [],
            lboutique_rech: [],
            liste_data: [],
            liste_personnel: [],
            selectedData: null,
            motifBon: '',
            trancheRegl: 0,
            mtBon: 0,
            liste_etat: [
                { label: 'Etat', value: 'Tous' },
                { label: 'Soldé', value: 'Oui' },
                { label: 'Non soldé', value: 'Non' },
            ],
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.conf_print_bon = this.conf_print_bon.bind(this);
        this.xulaservice = new Xulaservice();
    }

    conf_print_bon(data) {
        this.xulaservice.generer_fiche_bon(data.idBon);
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
            }
            if (this.state.userconnected != null && this.state.userconnected != undefined) {
                this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
            }
            if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                this.setState({ lboutique: lboutique });
                lboutique_rech = lboutique_rech.concat(lboutique);
            } else {
                let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                if (lboutique.length > 0) {
                    let liste = lboutique.filter(function (el) {
                        return el.value === id;
                    });
                    this.setState({ lboutique: liste });
                    lboutique_rech = lboutique_rech.concat(liste);
                }
            }
            this.setState({ lboutique_rech: lboutique_rech })
        }));
    }

    personnelItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label style={{ fontWeight: 'bold', color: 'red' }} >{option.salairePers_format}</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label>{option.nomBoutiq}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    get_liste_personnel() {
        this.xulaservice.get_liste_personnel().then(data => this.setState({ liste_personnel: data }));
    }

    componentDidMount() {
        this.get_liste_personnel();
        this.get_liste_data();
        this.get_liste_boutique();
    }



    get_liste_data() {
        let retat_bon = 'Tous';
        let rmatr_pers = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.rmatrPers != undefined && this.state.rmatrPers != null) {
            rmatr_pers = this.state.rmatrPers.value;
        }
        if (this.state.retatBon != undefined && this.state.retatBon != null && this.state.retatBon != '') {
            retat_bon = this.state.retatBon.value;
        }
        this.xulaservice.get_liste_bon(spd1, spd2, rmatr_pers, retat_bon).then(data => this.setState({ liste_data: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ idBon: data.idBon });
            this.setState({ dateBon: data.dateBon });
            this.setState({ trancheRegl: data.trancheRegl });
            this.setState({ matrPers: { value: data.matrPers.matrPers, label: data.matrPers.nomPers, nomBoutiq: data.matrPers.idBoutiq.nomBoutiq } });
            this.setState({ debRegl: data.debRegl });
            this.setState({ mtBon: data.mtBon });
            this.setState({ modif: true });
            this.setState({ motifBon: data.motifBon });
            this.setState({ selectedData: data });
        }
    }

    annule_data() {
        this.setState({ motifBon: '' });
        this.setState({ dateBon: '' });
        this.setState({ debRegl: '' });
        this.setState({ trancheRegl: 0 });
        this.setState({ mtBon: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_data() {
        if (this.state.mtBon === '' || this.state.mtBon === undefined || this.state.mtBon === 0 || this.state.dateBon === ''
            || this.state.dateBon === undefined || this.state.dateBon === null || this.state.matrPers === undefined || this.state.matrPers === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif === false) {
                if(this.state.idBoutiq === "" || this.state.idBoutiq === undefined || this.state.idBoutiq === null){
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la boutique ' });
                    return;
                }
                let date = moment(this.state.dateBon, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let deb = moment(this.state.debRegl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idBon: '0', trancheRegl: this.state.trancheRegl, motifBon: this.state.motifBon, mtBon: this.state.mtBon, debRegl: deb, dateBon: date, matrPers: { matrPers: this.state.matrPers.value },idBoutiq: { idBoutiq: this.state.idBoutiq }, iduEnreg: { idUser: this.state.userconnected.idUser } };
                this.xulaservice.enreg_bon(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let date = moment(this.state.dateBon, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let deb = moment(this.state.debRegl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedData;
                data.dateBon = date;
                data.debRegl = deb;
                data.mtBon = this.state.mtBon;
                data.trancheRegl = this.state.trancheRegl;
                data.motifBon = this.state.motifBon;
                //data.matrPers = { matrPers: this.state.matrPers.value };
                this.xulaservice.enreg_bon(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{  }}>
            <div className="p-col"><Button onClick={(e) => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_print_bon(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" /></div>
        </div>
    }


    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_bon(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    handleKeyPress(key) {
        if (key === 'Enter') {
            alert('Enter');
        }
    }



    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let lpersonnel_rech = [{ value: 'Tous', label: 'Personnel' }];
        let lpersonnel = null;
        if (this.state.liste_personnel != null && this.state.liste_personnel != undefined) {
            lpersonnel = this.state.liste_personnel.map(x => {
                return { value: x.matrPers, label: x.nomPers, salairePers: x.salairePers, salairePers_format: this.formaterMontant(x.salairePers), nomBoutiq: x.idBoutiq.nomBoutiq }
            });
        }
        lpersonnel_rech = lpersonnel_rech.concat(lpersonnel);
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <KeyboardEventHandler
                    handleKeys={['shift+n', 'enter']}
                    shiftKey
                    onKeyEvent={(key, e) => {
                        if (key === 'enter') {
                            this.enreg_data();
                        } else if (key === 'shift+n') {
                            this.annule_data();
                        }
                    }} />

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '700px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomCli">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.dateBon} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateBon: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Personnel *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.matrPers} options={lpersonnel} optionLabel='label' panelStyle={{ width: '500px' }} filter={true} filterBy='label' onChange={(e) => this.setState({ matrPers: e.value})} autoWidth={false} itemTemplate={this.personnelItemTemplate} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.mtBon} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtBon: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Tranche règlement </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.trancheRegl} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ trancheRegl: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomCli">Début règlement *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.debRegl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debRegl: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Boutique à débiter *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idBoutiq} options={this.state.lboutique} filter={true} filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value }, () => {
                                        // alert(this.state.idBoutiq);
                                    })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Motif</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputTextarea rows={2} cols={30} value={this.state.motifBon} onChange={(e) => this.setState({ motifBon: e.target.value })} />
                                </div>


                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_data()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <Dropdown options={lpersonnel_rech} placeholder="Personnel" value={this.state.rmatrPers} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rmatrPers: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_etat} placeholder="Statut" value={this.state.retatBon} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ retatBon: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data}
                                responsive={true} scrollable={true} scrollHeight="600px" selectionMode="single"
                                onRowClick={e => this.setState({ selectedData: e.data })}
                                selection={this.state.selectedData}> 
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="dateBon" header="Date" style={{ width: '8%' }}  filter={true} filterMatchMode='contains' sortable={true}  />
                                <Column field="mtBon" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtBon)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="matrPers.nomPers" header="Personnel" style={{ width: '18%' }} body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000'}}>{rowData.matrPers.nomPers}</span>
                                        <br/>
                                        <span style={{ color: '#F34C1F'}}>{rowData.matrPers.idBoutiq.nomBoutiq}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique débitée"  style={{ width: '12%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="debRegl" header="Début règl." style={{ width: '9%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="trancheRegl" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.trancheRegl)}</span>
                                    </div>
                                }} header="Tranche" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtBon_restant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtBon_restant)}</span>
                                    </div>
                                }} header="Restant" style={{ width: '10%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="motifBon" header="Motif" style={{ width: '15%' }}  filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}