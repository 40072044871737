import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import Numeral from 'numeral';
import { InputMask } from 'primereact/inputmask';
 

export class FormProduction extends Component {
    constructor() {
        super();
        this.state = {
            idProd: '0',
            libArticle: '',
            cmtProd: '',
            nbProd: 0,
            poidsProd: 0,
            nbAvarie: 0,
            hdebProd: '',
            hfinProd: '',
            dateProd: new Date(),
            idLprod: 0,
            qteLprod: 1,
            nbLprod: 0,
            num_ligne: 0,
            rnumBC: '',
            pd1: new Date(),
            pd2: new Date(),
            rstatutProd: null,
            idBoutiq: null,
            ridBoutiq: null,
            idArticle: null,
            idDet: null,
            idCprod: null,
            matrPers: null,
            liste_pers: [],
            liste_detail_reap: [],
            liste_production: [],
            liste_statut: [
                { label: 'Tout statut', value: 'Tous' },
                { label: 'Validé', value: 'Validé' },
                { label: 'En attente', value: 'En attente' }
            ],
            liste_prod: [],
            liste_cprod: [],
            liste_lprod: [],
            lboutique: [],
            liste_article: [],
            liste_ligne: [],
            lprod: [],
            selectedProduction: null,
            selectedLprod: null,
            selectedLigne: null,
            msg: '',
            modif: false,
            modif_lprod: false,
            visible: false,
            visible_ligne_production: false,
            visible_new_lprod_dlg: false,
            visible_dlg_production: false,
            visible_dlg_lprod: false,
            visible_new_production_dlg: false,
            showindicator: false,
            disable_new_prod: true,
            disable_add_prod: true,
            disable_prod: true,
            qteRest: 0,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_production = this.edit_production.bind(this);
        this.conf_delete_production = this.conf_delete_production.bind(this);

        this.edit_ligne_production = this.edit_ligne_production.bind(this);
        this.conf_delete_ligne_production = this.conf_delete_ligne_production.bind(this);

        this.show_new_production_dlg = this.show_new_production_dlg.bind(this);
        this.show_ligne_production_dlg = this.show_ligne_production_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);
        this.articleItemTemplate = this.articleItemTemplate.bind(this);

        this.qteLprodEditor = this.qteLprodEditor.bind(this);
        this.nbLprodEditor = this.nbLprodEditor.bind(this);
        this.conf_print_bs = this.conf_print_bs.bind(this);


        this.myservice = new Xulaservice();
    }



    conf_print_bl(data) {
        this.myservice.generer_edition_bl(data.numBl);
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }


    calculer_total(liste) {
        let tt = 0;
        if (liste != null) {
            for (var i = liste.length; i--;) {
                tt += liste[i].montantLtrs;
            }
        }
        this.setState({ total_transfert: this.formaterMontant(tt) });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    cprodItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <label>{option.label}</label>
                        </div>

                        <div className="p-col-12 p-md-4">
                            <label>{option.dateCprod}</label>
                        </div>

                    </div>
                </div>
            );
        }
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-10">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qteRest}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    nbLprodEditor(props) {
        return this.inputTextEditor(props, 'nbLprod');
    }

    qteLprodEditor(props) {
        return this.inputTextEditor(props, 'qteLprod');
    }


    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputText keyfilter="pnum" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    componentDidMount() {
        this.get_liste_production();
        this.get_liste_all_detail_reap();
        this.get_liste_personnel();
        this.get_liste_cprod_en_cours();
    }


    get_liste_all_detail_reap() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_all_detail_reap().then(data => {
            let liste = [];
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idDet, label: x.numDet }
                });
                this.setState({ liste_detail_reap: liste });
            }
        });
    }

    get_liste_personnel() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_personnel().then(data => {
            let liste = [];
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.matrPers, label: x.nomPers + ' ' + x.telPers }
                });
                this.setState({ liste_pers: liste });
            }
        });
    }

    get_liste_cprod_en_cours() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_cprod_en_cours().then(data => {
            let liste = [];
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idCprod, label: x.idCprod, dateCprod: x.dateCprod }
                });
                this.setState({ liste_cprod: liste });
            }
        });
    }


    get_liste_production() {
        let rid_boutique = 'Tous';
        let rstatut = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq !== undefined && this.state.ridBoutiq !== null) {
            rid_boutique = this.state.ridBoutiq.value;
        }

        if (this.state.rstatutProd !== undefined && this.state.rstatutProd !== null) {
            rstatut = this.state.rstatutProd.value;
        }

        this.setState({ showindicator: true });
        this.myservice.get_liste_production(spd1, spd2, rid_boutique, rstatut, this.state.rnumBC !== '' ? this.state.rnumBC : 'Tous').then(data => this.setState({ liste_production: data, showindicator: false }));
    }

    get_liste_ligne_production(idProd) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_ligne_production(idProd).then(data => this.setState({ liste_lprod: data, showindicator: false }));
    }




    get_liste_article(idCprod) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_article_for_prod(idCprod).then(data => {
            let lprod = null;
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                lprod = data.map(x => {
                    return { value: x.idArt, label: x.libArt, qteCmde: x.qte_a_livrer, qteRest: x.qte_stock }
                });
                this.setState({ liste_article: lprod });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_production();
            this.setState({ liste_lprod: [] });
            this.get_liste_production();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_ligne_production(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_ligne_production();
            this.get_liste_production();
            this.setState({ total_transfert: '0' });
            if (this.state.selectedProduction != null && this.state.selectedProduction != undefined) {
                this.get_liste_ligne_production(this.state.selectedProduction.idProd);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }



    conf_print_bs(data) {
        this.myservice.generer_edition_bs(data.idProd);
    }

    edit_production(data) {
        if (data != null && data != undefined) {
            if (data.statutProd == 'En attente') {
                this.setState({ idProd: data.idProd });
                this.setState({ dateProd: data.dateProd });
                this.setState({ matrPers: { value: data.matrPers.matrPers, label: data.matrPers.nomPers + ' ' + data.matrPers.telPers } });
                this.setState({ cmtProd: data.cmtProd });

                this.setState({ poidsProd: data.poidsProd });
                this.setState({ nbAvarie: data.nbAvarie });
                this.setState({ nbProd: data.nbProd });

                this.setState({ hdebProd: data.hdebProd });
                this.setState({ hfinProd: data.hfinProd });

                this.setState({ modif: true });
                this.setState({ visible_dlg_production: true });
                this.setState({ selectedProduction: data });
                window.scrollTo(0, 0);
                this.forceUpdate();
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette ligne, car déja validé' });
            }
        }
    }

    edit_ligne_production(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_lprod: true }, () => {
                this.setState({ idLprod: data.idLprod });
                this.setState({ qteLprod: data.qteLprod });
                this.setState({ nbLprod: data.nbLprod });
                this.setState({ visible_dlg_lprod: true });
                this.setState({ disable_prod: true });
                this.setState({ selectedLprod: data });
                this.setState({ libArticle: data.idArticle.libArticle });
                this.setState({ idArticle: { value: data.idArticle.idArticle, label: data.idArticle.libArticle } });
                window.scrollTo(0, 0);
                this.forceUpdate();
            });
        }
    }

    annule_production() {
        this.setState({ idProd: '0' });
        this.setState({ dateProd: '' });
        this.setState({ cmtProd: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_production: false });
        this.forceUpdate();
    }

    annule_production_dlg() {
        this.annule_production();
        this.setState({ liste_ligne: [] });
    }

    annule_ligne_production() {
        this.setState({ idLprod: '0' });
        this.setState({ qteLprod: 0 });
        this.setState({
            nbLprod: 0,
            idCprod: null,
            idArticle: null,
            liste_article: []
        });
        if (this.state.modif_lprod === true) {
            this.setState({ visible_dlg_lprod: false });
        }
        this.setState({ modif_lprod: false });
        this.setState({ liste_ligne: [] });

        this.forceUpdate();
    }



    enreg_production() {
        if (this.state.dateProd === undefined || this.state.dateProd === null || this.state.dateProd === '' ) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.idCprod === null  ) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la commande' });
                    return;
                }else if (this.state.idDet === null  ) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez sélectionner l'arrivage" });
                    return;
                }else if (this.state.matrPers === null  ) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le chef de production' });
                    return;
                }else if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                }
                let date = moment(this.state.dateProd, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idProd: '0', idCprod: { idCprod: this.state.idCprod.value }, dateProd: date, statutProd: 'En attente', poidsProd: this.state.poidsProd, nbAvarie: this.state.nbAvarie, cmtProd: this.state.cmtProd, matrPers: { matrPers: this.state.matrPers.value }, idDet: { idDet: this.state.idDet.value }, hfinProd: this.state.hfinProd, hdebProd: this.state.hdebProd, nbProd: this.state.nbProd, iduser: this.state.userconnected.idUser };

                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idProd = data;
                    this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_ligne_production_batch(this.state.liste_ligne).then(data => {
                    if (data != null && data != undefined) {
                        this.resultat_ligne_production(data.code, data.contenu);
                    }
                });

            } else {
                let data = this.state.selectedProduction;
                let date = moment(this.state.dateProd, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.cmtProd = this.state.cmtProd;
                data.nbProd = this.state.nbProd;
                data.poidsProd = this.state.poidsProd;
                data.nbAvarie = this.state.nbAvarie;
                data.hdebProd = this.state.hdebProd;
                data.hfinProd = this.state.hfinProd;
                data.dateProd = date;
                data.matrPers = { matrPers: this.state.matrPers.value };
                this.setState({ showindicator: true });
                this.myservice.enreg_production(data).then(data => this.resultat(data.code, data.contenu));
            }

        }
    }

    update_statut_production(data) {
        data.statutProd = 'Validé';
        this.setState({ showindicator: true });
        this.myservice.enreg_production(data).then(rep => {
            this.setState({ showindicator: false });
            if (rep.code === 200) {
                this.growl.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                this.setState({ liste_lprod: [] });
                this.get_liste_production();
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
            }
            this.setState({ showindicator: false });

        });
    }

    enreg_ligne_production() {
        if (this.state.selectedProduction == null || this.state.selectedProduction == undefined || this.state.nbLprod == '' || this.state.nbLprod == undefined || this.state.qteLprod == '' || this.state.qteLprod == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner un transfert' });
        } else {
            if (this.state.modif_lprod === false) {
                let data = { idLprod: '0', qteLprod: this.state.qteLprod, nbLprod: this.state.nbLprod, idProd: { idProd: this.state.selectedProduction.idProd }, idArticle: { idArticle: this.state.idArticle.value } };
                for (var i = this.state.liste_lprod.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_lprod[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce article existe déja dans le liste sélectionné, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_ligne_production(data).then(res => this.resultat_ligne_production(res.code, res.contenu));
            } else {
                let qte = parseFloat(this.state.qteLprod) - parseFloat(this.state.selectedLprod.qteLprod);
                if (qte > parseFloat(this.state.idArticle.qte_stock)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "La quantité saisie n'est pas disponible dans cette boutique" });
                    return;
                }
                let data = this.state.selectedLprod;
                data.nbLprod = this.state.nbLprod;
                data.qteLprod = this.state.qteLprod;
                this.setState({ showindicator: true });
                this.myservice.enreg_ligne_production(data).then(data => this.resultat_ligne_production(data.code, data.contenu));
            }
        }
    }

    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.calculer_total(this.state.liste_ligne);
        this.forceUpdate();
    }

    add_article() {

        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
        } else if (this.state.qteLprod == null || this.state.qteLprod == '' || this.state.qteLprod == 0 || this.state.qteLprod == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        } else if (this.state.nbLprod == null || this.state.nbLprod == '' || this.state.nbLprod == 0 || this.state.nbLprod === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le metrage ' });
        } else if (this.state.qteRest < this.state.qteLprod) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir une quantité correcte ' });
        } else {
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLprod: '0', qteLprod: this.state.qteLprod, nbLprod: this.state.nbLprod, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteLprod = parseFloat(this.state.liste_ligne[i].qteLprod) + parseFloat(this.state.qteLprod);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLprod: '0', qteLprod: this.state.qteLprod, nbLprod: this.state.nbLprod, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.forceUpdate();
        }
        this.setState({ disable_val_production: false });
        this.calculer_total(this.state.liste_ligne);
    }


    actionTemplate(rowData, column) {
        let edit = false;
        let items = [];

        if (rowData.statutProd == 'En attente' && (this.state.userconnected.profilUser === 'Administrateur' || this.state.userconnected.profilUser === 'Producteur' || this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur')) {
            items.push({
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_production(rowData);
                }
            });
            items.push({
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_production(rowData);
                }
            });

            items.push({
                label: 'Valider',
                icon: 'pi pi-check',
                command: (e) => {
                    this.update_statut_production(rowData, 'Validation');
                }
            });
            edit = true;
        }

        return <div>
            <SplitButton label='Modifier' onClick={(e) => {
                if (edit === true) {
                    this.edit_production(rowData);
                } else {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette production car déja validée' });
                }
            }} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

    }

    actionTemplate_ligne_production(rowData, column) {
        let disable_button = true;
        if (this.state.selectedProduction.statutProd === 'En attente') {
            disable_button = false;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_ligne_production(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_ligne_production(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_production() {
        if (this.state.selectedProduction == null || this.state.selectedProduction == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_production(this.state.selectedProduction).then(data => this.resultat(data.code, data.contenu));
        }
    }
    delete_ligne_production() {
        if (this.state.selectedLprod == null || this.state.selectedLprod == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_ligne_production(this.state.selectedLprod).then(data => this.resultat_ligne_production(data.code, data.contenu));
        }
    }


    conf_delete_production(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedProduction: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    conf_delete_ligne_production(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLprod: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_ligne_production: true });
        }
    }


    show_new_production_dlg() {
        this.setState({ visible_new_production_dlg: true });
        this.setState({ modif: false });
    }

    show_ligne_production_dlg() {
        this.get_liste_article(this.state.selectedProduction.idCprod.idCprod);
        this.setState({ visible_dlg_lprod: true });
        this.setState({ modif_lprod: false });
        this.setState({ disable_prod: false });
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_production();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_larv = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_ligne_production: false });
                    this.delete_ligne_production();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_ligne_production: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        const header_production = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouvelle production" style={{ width: 200 }} onClick={this.show_new_production_dlg}></Button>
        </div>;

        const header_larv = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un article" style={{ width: 200 }} onClick={this.show_ligne_production_dlg}></Button>
        </div>;


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_ligne_production} style={{ width: '250px' }} modal={false} footer={dialogFooter_larv} onHide={(e) => this.setState({ visible_ligne_production: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <Dialog header="Nouvelle production" visible={this.state.visible_new_production_dlg} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_new_production_dlg: false })}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">N° BC *</label>
                            </span>
                            <Dropdown options={this.state.liste_cprod} value={this.state.idCprod} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                this.setState({ idCprod: e.value }, () => {
                                    this.get_liste_article(this.state.idCprod.value);
                                })
                            }} autoWidth={false} itemTemplate={this.cprodItemTemplate} panelStyle={{ width: '400px' }} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Arrivage *</label>
                            </span>
                            <Dropdown options={this.state.liste_detail_reap} value={this.state.idDet} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                this.setState({ idDet: e.value })
                            }} autoWidth={false} />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Chef de production * </label>
                            </span>
                            <Dropdown options={this.state.liste_pers} value={this.state.matrPers} optionLabel='label' itemTemplate={this.userItemTemplate} filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                this.setState({ matrPers: e.value })
                            }
                            } autoWidth={false} panelStyle={{ width: '400px' }} />
                        </div>


                        <div className="p-col-12 p-md-1">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Heure début  </label>
                            </span>
                            <InputMask mask="99:99" value={this.state.hdebProd} onChange={(e) => this.setState({ hdebProd: e.value })} />
                        </div>

                        <div className="p-col-12 p-md-1">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Heure fin </label>
                            </span>
                            <InputMask mask="99:99" value={this.state.hfinProd} onChange={(e) => this.setState({ hfinProd: e.value })} />
                        </div>


                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Panel style={{ minHeight: '400px' }} header="Ajout d'article">
                                        <div className="p-grid">

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article *</label>
                                                </span>
                                                <div className="p-inputgroup">
                                                    <Dropdown options={this.state.liste_article} placeholder="Articles" value={this.state.idArticle} panelStyle={{ width: '400px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value }, () => {
                                                                this.setState({ nbLprod: 0, qteRest: this.state.idArticle.qteRest, qteLprod: this.state.idArticle.qteRest });
                                                            })
                                                        }
                                                        } autoWidth={false} style={{ width: '100%' }} />
                                                </div>
                                            </div>

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Qauntité *</label>
                                                </span>
                                                <InputText value={this.state.qteLprod} onChange={(e) => this.setState({ qteLprod: e.target.value })} />
                                            </div>

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Mettrage *</label>
                                                </span>
                                                <InputText value={this.state.nbLprod} onChange={(e) => this.setState({ nbLprod: e.target.value })} />
                                            </div>
                                        </div>
                                        <br />
                                        <center>
                                            <Button label="Ajouter >>" style={{ width: '170px' }} icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                        </center>
                                    </Panel>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Panel style={{ minHeight: '280px' }}>
                                        <div className="p-grid">
                                            <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="300px"
                                                onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                                responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                                editable={true} >
                                                <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '6%' }} />
                                                <Column field="idArticle.libArticle" header="Produit" />
                                                <Column field="qteLprod" header="Qté" style={{ width: '10%' }}
                                                //  editor={this.qteLprodEditor} 
                                                />
                                                <Column field="nbLprod" header="Metrage utilisé" style={{ width: '25%' }} editor={this.nbLprodEditor} />
                                            </DataTable>
                                        </div>
                                    </Panel>
                                    <div className="card card-w-title" style={{ marginBottom: '10px' }}>
                                        <div className="p-grid">

                                            {/* <div className="p-col-12 p-md-3">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Metrage produit * </label>
                                                </span>
                                                <InputText keyfilter="pnum" value={this.state.nbProd} onChange={(e) => this.setState({ nbProd: e.target.value })} />
                                            </div> */}

                                            <div className="p-col-12 p-md-4">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Metrage avaris </label>
                                                </span>
                                                <InputText keyfilter="pnum" value={this.state.nbAvarie} onChange={(e) => this.setState({ nbAvarie: e.target.value })} />
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Poids total  </label>
                                                </span>
                                                <InputText keyfilter="pnum" value={this.state.poidsProd} onChange={(e) => this.setState({ poidsProd: e.target.value })} />
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Commentaire </label>
                                                </span>
                                                <InputText value={this.state.cmtProd} onChange={(e) => this.setState({ cmtProd: e.target.value })} />
                                            </div>

                                        </div>

                                        <br />
                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_production_dlg()} /></div>
                                                <div className="p-col"><Button disabled={this.state.liste_ligne.length <= 0} label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_production()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Modification production" visible={this.state.visible_dlg_production} style={{ width: '500px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_production: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label >Date  *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Calendar value={this.state.dateProd} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateProd: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Heure début *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.hdebProd} onChange={(e) => this.setState({ hdebProd: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Heure fin *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.hfinProd} onChange={(e) => this.setState({ cmtProd: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Metrage produit *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.nbProd} onChange={(e) => this.setState({ nbProd: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Avaries *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.nbAvarie} onChange={(e) => this.setState({ nbAvarie: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label >Commentaire</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.cmtProd} disabled={this.state.disable_cmt} onChange={(e) => this.setState({ cmtProd: e.target.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_production()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_production()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Modification détail production" visible={this.state.visible_dlg_lprod} style={{ width: '570px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_lprod: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    {this.state.modif_lprod == false ?
                                        <>
                                            <div className="p-col-12 p-md-3">
                                                <label htmlFor="profile">Article *</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <div className="p-inputgroup">
                                                    <Dropdown options={this.state.liste_article} value={this.state.idArticle} panelStyle={{ width: '300px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value },()=>{
                                                                this.setState({ nbLprod: 0, qteRest: this.state.idArticle.qteRest, qteLprod: this.state.idArticle.qteRest });
                                                            })
                                                        }
                                                        } autoWidth={false} />
                                                </div>

                                            </div>
                                        </>
                                        : null}

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Qauntité produite *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.qteLprod} onChange={(e) => this.setState({ qteLprod: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Metrage  *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.nbLprod} onChange={(e) => this.setState({ nbLprod: e.target.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lprod: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_ligne_production()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_statut} placeholder="Statut" value={this.state.rstatutProd} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutProd: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rnumBC} placeholder="N° BC" onChange={(e) => this.setState({ rnumBC: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_production()} style={{ width: 170 }} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                                    <DataTable value={this.state.liste_production} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedProduction: e.data })} onRowSelect={e =>

                                                            this.setState({ selectedProduction: e.data }, () => {
                                                                this.get_liste_ligne_production(this.state.selectedProduction.idProd);
                                                                if (this.state.selectedProduction.statutProd == 'En attente' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Producteur' || this.state.userconnected.profilUser === 'Coordinateur' || this.state.userconnected.profilUser === 'Administrateur')) {
                                                                    this.setState({ disable_add_prod: false });
                                                                } else {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            })
                                                        }
                                                        header={header_production} style={{ width: '100%' }}
                                                        responsive={true} selection={this.state.selectedProduction} >
                                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '20%', overflow: 'visible' }} />
                                                        <Column field="dateProd" body={(rowData, column) => {
                                                            return <div>
                                                                <span style={{ color: '#000000' }}>{rowData.dateProd}</span>
                                                            </div>
                                                        }} header="Date" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="statutProd" header="Statut" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="nbProd" header="Metrage" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="nbAvarie" header="Avaris" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="idCprod.idCprod" header="N° BC" filter={true} filterMatchMode='contains' sortable={true} />

                                                    </DataTable>
                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <DataTable value={this.state.liste_lprod} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                    onRowClick={e => this.setState({ selectedLprod: e.data })} onRowSelect={e => this.setState({ selectedLprod: e.data })}
                                                    responsive={true} selection={this.state.selectedLprod} header={header_larv} >
                                                    <Column header="#" body={this.actionTemplate_ligne_production.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="idArticle.libArticle" header="Article" style={{ width: '43%' }} />
                                                    <Column field="qteLprod" header="Qté" style={{ width: '12%' }} />
                                                    <Column field="nbLprod" header="Metrage" style={{ width: '15%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}