import React, { Component } from 'react';
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { SampleDemo } from './components/SampleDemo';
import { FormVente } from './components/FormVente';
import { FormCommande } from './components/FormCommande';
import { FormReap } from './components/FormReap';
import { FormClient } from './components/FormClient';
import { FormTransfert_stock } from './components/FormTransfert_stock';
import { FormReglCmde } from './components/FormReglCmde';
import { FormReglSortie } from './components/FormReglSortie';
import { FormBoutique } from './components/FormBoutique';
import { FormEtabl } from './components/FormEtabl';
import { FormFournisseur } from './components/FormFournisseur';
import { FormEntreeFond } from './components/FormEntreeFond';
import { FormSeuilArticle } from './components/FormSeuilArticle';
import { FormPersonnel } from './components/FormPersonnel';
import { FormUsers } from './components/FormUsers';
import { FormTypeArticle } from './components/FormTypeArticle';
import { FormArticle } from './components/FormArticle';
import { FormPrime } from './components/FormPrime';
import { FormBon } from './components/FormBon';
import { FormPaye } from './components/FormPaye';
import { FormInventaire } from './components/FormInventaire';
import { FormOperationClient } from './components/FormOperationClient';
import { FormDepense } from './components/FormDepense';
import { FormChpwd } from './components/FormChpwd';
import { FormEdition_Inventaire } from './components/FormEdition_Inventaire';
import { FormEdition_vente } from './components/FormEdition_vente';
import { FormEdition_Balance_client } from './components/FormEdition_Balance_client';
import { FormEdition_Solde } from './components/FormEdition_Solde';
import { FormEdition_Commande } from './components/FormEdition_Commande';
import { FormSalle } from './components/FormSalle';
import { FormStat_vente } from './components/FormStat_vente';
import { FormStat_depense } from './components/FormStat_depense';
import { FormStat_vente_article } from './components/FormStat_vente_article';
import { FormEdition_Journal_appro } from './components/FormEdition_Journal_appro';
import { FormEdition_Journal_sortie_stock } from './components/FormEdition_Journal_sortie_stock';
import { FormEdition_reglSortie } from './components/FormEdition_reglSortie';
import { FormEdition_Journal_trs } from './components/FormEdition_Journal_trs';
import { FormEdition_Balance_four } from './components/FormEdition_Balance_four';
import { FormTrace } from './components/FormTrace';
import { FormMsg } from './components/FormMsg';
import { FormEdition_Balance_souscripteur } from './components/FormEdition_Balance_souscripteur';
import { FormEdition_Operationclient } from './components/FormEdition_Operationclient';
import { FormEdition_AlertePeremption } from './components/FormEdition_AlertePeremption';
import { FormStat_vente_agent } from './components/FormStat_vente_agent';
import { FormStat_VenteArticle_Client } from './components/FormStat_VenteArticle_Client';
import { FormCodif } from './components/FormCodif';
import { FormEdition_situation_agent } from './components/FormEdition_situation_agent';
import { FormPlanifPrix } from './components/FormPlanifPrix';
import { FormFidelisation } from './components/FormFidelisation';
import { FormCategorieFidelisation } from './components/FormCategorieFidelisation';
import { FormEdition_Journal_livraison } from './components/FormEdition_Journal_livraison';
import { FormEdition_situation_boutique } from './components/FormEdition_situation_boutique';
import { FormParamPrix } from './components/FormParamPrix';
import { FormEdition_dette } from './components/FormEdition_dette';
import { FormEdition_facturesechues } from './components/FormEdition_facturesechues';
import { Dashboardrh } from './components/Dashboardrh';
import { Dashboardrcv } from './components/Dashboardrcv';
import { Dashboardgas } from './components/Dashboardgas';
import { FormObjectifVente } from './components/FormObjectifVente';
import { FormPrevDepense } from './components/FormPrevDepense';
import { FormProduction } from './components/FormProduction';
import { FormArrivage } from './components/FormArrivage';
import { FormEdition_Situation_bobine } from './components/FormEdition_Situation_bobine';
import { FormCmdeProduction } from './components/FormCmdeProduction';
import { FormEdition_AlerteRupture } from './components/FormEdition_AlerteRupture';
import {FormSituationDette} from './components/FormSituationDette';
import {DashboardV3} from './components/DashboardV3';


/*
import {DashboardV3} from './components/DashboardV3';
*/
import { EmptyPage } from './components/EmptyPage';
import { Documentation } from './components/Documentation';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { ProgressBar } from 'primereact/components/progressbar/ProgressBar';
import 'primereact/resources/primereact.min.css';
import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';



class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'horizontal',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkTheme: false,
            menuActive: false,
            offline: localStorage.getItem('offline'),
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay' && !this.isMobile()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.changeStyleSheetUrl('layout-css', theme, 'layout');
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);

        if (value.indexOf('dark') !== -1) {
            this.setState({ darkTheme: true });
        } else {
            this.setState({ darkTheme: false });
        }
    }

    onThemeChange() {
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.changeTheme(themeName + '-' + newThemeMode);

    }

    createMenu() {
 
       

        var user = JSON.parse(localStorage.getItem('userconnected'));
        var val_offline = localStorage.getItem('offline');

        if (user !== null && user !== undefined && user !== '') {
            if (val_offline == 'true') {
                if (user.profilUser === 'PDG') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion production', icon: 'fa fa-fw fa-box', command: () => { window.location = '#/production' } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Mouvement de fonds', icon: 'fa fa-fw fa-exchange', command: () => { window.location = '#/mouvement_fonds' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                                { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                                { label: 'Plage de prix', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/paramprix" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [

                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Journal des appro.', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_appro" } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Journal de transfert', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_transfert" } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },
                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance souscripteur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance/souscripteur' } },
                                        { label: 'Balance des assurés', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance/assuré' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande/par_date" } },
                                { label: 'Alerte commande', icon: 'fa fa-fw fa-bell', command: () => { window.location = '#/editions/journal_commande/par_delai' } },
                                { label: 'Opérations client', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/operation_client" } },
                                { label: 'Journal des traces', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_trace" } },
                                { label: 'Situation agent', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situationagent" } },
                                { label: 'Situation boutiques', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_boutique" } }
                            ]
                        },
                        {
                            label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                            items: [
                                { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                                { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                                { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                                { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                                { label: 'Ventes par client', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente_client_article" } },
                            ]
                        },
                    ];
                } else if (user.profilUser === 'Coordinateur') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/production' } },

                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Mouvement de fonds', icon: 'fa fa-fw fa-exchange', command: () => { window.location = '#/mouvement_fonds' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Journal des appro.', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_appro" } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Journal de transfert', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_transfert" } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Opérations client', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/operation_client" } },
                                { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande" } },
                                { label: 'Situation agent', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situationagent" } },
                                { label: 'Situation boutiques', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_boutique" } }

                            ]
                        },
                        {
                            label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                            items: [
                                { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                                { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                                { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                                { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                                { label: 'Ventes par client', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente_client_article" } },

                            ]
                        },
                    ];


                } else if (user.profilUser === 'Administrateur') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                        { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/production' } },

                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },

                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                            ]
                        },
                        {
                            label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                            items: [
                                { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                                { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                                { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                                { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } }
                            ]
                        }
                    ];
                } else if (user.profilUser === 'Gérant') {
                    if (user.idEtabl.domaineEtabl === 'Pharmacie') {
                        this.menu = [
                            { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                            { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                            { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                            { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                            { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                            {
                                label: 'Opération', icon: 'fa fa-fw fa-coffee',
                                items: [
                                    { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                    { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                    { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                    { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                    { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                                ]
                            },
                            {
                                label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                                items: [
                                    { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                    { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },

                                ]
                            },
                            {
                                label: 'Editions', icon: 'fa fa-fw fa-print',
                                items: [
                                    {
                                        label: 'Stock', icon: 'fa fa-fw fa-print',
                                        items: [
                                            { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                            { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                            { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                            { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                        ]
                                    },
                                    {
                                        label: 'Balance', icon: 'fa fa-fw fa-print',
                                        items: [
                                            { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                            { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                        ]
                                    },
                                    { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                    { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande" } }
                                ]
                            }
                        ];

                    } else {
                        this.menu = [
                            { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                            { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                            { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                            { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                            { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                            {
                                label: 'Opération', icon: 'fa fa-fw fa-coffee',
                                items: [
                                    { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                    { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                    { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                    { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                    { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                                ]
                            },
                            {
                                label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                                items: [
                                    { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                    { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                                ]
                            },
                            {
                                label: 'Editions', icon: 'fa fa-fw fa-print',
                                items: [
                                    {
                                        label: 'Stock', icon: 'fa fa-fw fa-print',
                                        items: [
                                            { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                            { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                            { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                            { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                        ]
                                    },
                                    {
                                        label: 'Balance', icon: 'fa fa-fw fa-print',
                                        items: [
                                            { label: 'Balance client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                            { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                        ]
                                    },
                                    { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                    { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande" } }
                                ]
                            }
                        ];
                    }
                } else if (user.profilUser === 'Vendeur') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                    ];

                }

            } else if (user.profilUser === 'PDG' || user.profilUser === 'Directeur') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                    { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                    { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                    {
                        label: 'Opération', icon: 'fa fa-fw fa-coffee',
                        items: [
                            { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                            { label: 'Gestion des commandes', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion commande' } },
                            { label: 'Nouvelle commande', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Nouvelle commande' } },
                            { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                            { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                            { label: 'Gestion sortie pertes', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Perte'; this.forceUpdate() } },
                            { label: 'Mouvement de fonds', icon: 'fa fa-fw fa-exchange', command: () => { window.location = '#/mouvement_fonds' } },
                            { label: 'Regler commande', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_commande' } },
                            { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                            { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                            { label: 'Réapprovisionnement', icon: 'fa fa-fw fa-arrow-right', command: () => { window.location = '#/réapprovisionnement' } },
                            { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                            { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-calculator', command: () => { window.location = '#/inventaire' } },
                            { label: 'Gestion des BC', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/cmdprod' } },
                            { label: 'Méssagerie interne', icon: 'fa fa-fw fa-envelope', command: () => { window.location = '#/messagerie/interne' } },
                            // { label: 'Messagerie externe', icon: 'fa fa-fw fa-comment', command: () => { window.location = '#/messagerie/rappel' } }
                        ]
                    },
                    {
                        label: 'Gestion RH', icon: 'fa fa-fw fa-users',
                        items: [
                            { label: 'Gestion du personnel', icon: 'fa fa-fw fa-users', command: () => { window.location = '#/personnel' } },
                            { label: 'Gestion des primes', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/primes' } },
                            { label: 'Gestion des bons', icon: 'fa fa-fw fa-clipboard', command: () => { window.location = "#/bons" } },
                            { label: 'Gestion paie', icon: 'fa fa-fw fa-money', command: () => { window.location = "#/paye" } }
                        ]
                    },
                    {
                        label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                        items: [
                            { label: 'Type article', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/type_article' } },
                            {
                                label: 'Paramètres généraux', icon: 'fa fa-fw fa-cog',
                                items: [
                                    { label: 'Type dépense', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/formCodif' } },
                                    { label: 'Tables', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/tables' } },
                                    { label: 'Plage de prix', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/paramprix" } },
                                ]
                            },
                            { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                            { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                            { label: 'Utilisateurs', icon: 'fa fa-fw fa-user-secret', command: () => { window.location = "#/Utilisateurs" } },
                            { label: 'Fournisseurs', icon: 'fa fa-fw fa-align-left', command: () => { window.location = "#/fournisseurs" } },
                            { label: 'Boutiques', icon: 'fa fa-fw fa-bank', command: () => { window.location = "#/boutiques" } },
                            { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                            { label: 'Etablissement', icon: 'fa fa-fw fa-cog', command: () => { window.location = "#/établissement" } },
                            { label: 'Planification prix', icon: 'fa fa-fw fa-cog', command: () => { window.location = "#/planif" } },
                            { label: 'Catégories fidélité', icon: 'fa fa-fw fa-cog', command: () => { window.location = "#/categorisation" } },
                            { label: 'Gestion fidélisation', icon: 'fa fa-fw fa-diamond', command: () => { window.location = '#/fidelisation' } },
                            { label: 'Objectifs de vente', icon: 'fa fa-fw fa-hourglass', command: () => { window.location = '#/objectifs' } },
                            { label: 'Prévisions dépense', icon: 'fa fa-fw fa-bell', command: () => { window.location = '#/budget' } },


                        ]
                    },
                   /*  { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                    { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } }, */
                    {
                        label: 'Editions', icon: 'fa fa-fw fa-print',
                        items: [
                            {
                                label: 'Stock', icon: 'fa fa-fw fa-print',
                                items: [

                                    { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                    { label: 'Journal des appro.', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_appro" } },
                                    { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                    { label: 'Journal de transfert', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_transfert" } },
                                    { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                    { label: 'Journal des livraisons', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_livraison" } },
                                    { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                ]
                            },
                            {
                                label: 'Balance', icon: 'fa fa-fw fa-print',
                                items: [
                                    { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                    { label: 'Balance souscripteur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance/souscripteur' } },
                                    { label: 'Balance des assurés', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance/assuré' } },
                                    { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    { label: 'Situation dettes', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/situationdette' } },
                                ]
                            },
                            { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                            { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                            { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande/par_date" } },
                            { label: 'Alerte commande', icon: 'fa fa-fw fa-bell', command: () => { window.location = '#/editions/journal_commande/par_delai' } },
                            { label: 'Opérations client', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/operation_client" } },
                            { label: 'Journal des traces', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_trace" } },
                            { label: 'Situation agent', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situationagent" } },
                            { label: 'Situation boutiques', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_boutique" } },
                            { label: 'Journal des dettes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journaldette" } },
                            { label: 'Journal factures échues', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journalfactureechu" } }
                        ]
                    },
                    {
                        label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                        items: [
                            { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                            { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                            { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                            { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                            { label: 'Ventes par client', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente_client_article" } },

                        ]
                    },
                ];
            } else if (user.profilUser === 'Coordinateur') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                    { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                    { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                    {
                        label: 'Opération', icon: 'fa fa-fw fa-coffee',
                        items: [
                            { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                            { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                            { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                            { label: 'Gestion sortie pertes', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Perte'; this.forceUpdate() } },
                            { label: 'Mouvement de fonds', icon: 'fa fa-fw fa-exchange', command: () => { window.location = '#/mouvement_fonds' } },
                            { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                            { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                            { label: 'Réapprovisionnement', icon: 'fa fa-fw fa-arrow-right', command: () => { window.location = '#/réapprovisionnement' } },
                            { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                            { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            { label: 'Inventaire', icon: 'fa fa-fw fa-calculator', command: () => { window.location = '#/inventaire' } },
                            { label: 'Méssagerie interne', icon: 'fa fa-fw fa-envelope', command: () => { window.location = '#/messagerie/interne' } },
                            { label: 'Messagerie externe', icon: 'fa fa-fw fa-comment', command: () => { window.location = '#/messagerie/rappel' } },
                            { label: 'Gestion des BC', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/cmdprod' } },
                        ]
                    },
                    {
                        label: 'Gestion RH', icon: 'fa fa-fw fa-users',
                        items: [
                            { label: 'Gestion des primes', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/primes' } },
                            { label: 'Gestion des bons', icon: 'fa fa-fw fa-clipboard', command: () => { window.location = "#/bons" } },
                            { label: 'Gestion paie', icon: 'fa fa-fw fa-money', command: () => { window.location = "#/paye" } }
                        ]
                    },
                    {
                        label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                        items: [
                            { label: 'Type article', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/type_article' } },
                            {
                                label: 'Paramètres généraux', icon: 'fa fa-fw fa-cog',
                                items: [
                                    { label: 'Type dépense', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/formCodif' } },
                                ]
                            },
                            { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                            { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                            { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                            { label: 'Etablissement', icon: 'fa fa-fw fa-cog', command: () => { window.location = "#/établissement" } },
                            { label: 'Gestion fidélisation', icon: 'fa fa-fw fa-diamond', command: () => { window.location = '#/fidelisation' } },
                        ]
                    },
                    { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                    { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } },
                    {
                        label: 'Editions', icon: 'fa fa-fw fa-print',
                        items: [
                            {
                                label: 'Stock', icon: 'fa fa-fw fa-print',
                                items: [
                                    { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                    { label: 'Journal des appro.', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_appro" } },
                                    { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                    { label: 'Journal de transfert', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_transfert" } },
                                    { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                    { label: 'Journal des livraisons', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_livraison" } },
                                    { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                ]
                            },
                            {
                                label: 'Balance', icon: 'fa fa-fw fa-print',
                                items: [
                                    { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                    { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    { label: 'Situation dettes', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/situationdette' } },
                                ]
                            },
                            { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                            { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                            { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                            { label: 'Opérations client', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/operation_client" } },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande/par_date" } },
                            { label: 'Alerte commande', icon: 'fa fa-fw fa-bell', command: () => { window.location = '#/editions/journal_commande/par_delai' } },
                            { label: 'Journal des traces', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_trace" } },
                            { label: 'Situation agent', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situationagent" } },
                            { label: 'Situation boutiques', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_boutique" } },
                            { label: 'Journal des dettes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journaldette" } },
                            { label: 'Journal factures échues', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journalfactureechu" } }

                        ]
                    },
                    {
                        label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                        items: [
                            { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                            { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                            { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                            { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                            { label: 'Ventes par client', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente_client_article" } },


                        ]
                    },
                ];
                if (user.matrPers.matrPers !== '471/859' && user.matrPers.matrPers !== '471/1882S071050621') {
                    for (var i = this.menu.length; i--;) {
                        if (this.menu[i].label === 'Gestion RH' || this.menu[i].value === 'Editions') {
                            this.menu.splice(i, 1);
                        }
                        if (this.menu[i].label === 'Editions') {
                            this.menu[i].items.splice(8, 1);
                            this.menu[i].items.splice(7, 1);
                        }
                        if (this.menu[i].label === 'Opération') {
                            this.menu[i].items.splice(4, 1);
                        }
                    }
                }
            } else if (user.profilUser === 'Administrateur') {
                if (user.matrPers.idBoutiq.typeBoutiq === 'Usine') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                                { label: 'Inventaire', icon: 'fa fa-fw fa-calculator', command: () => { window.location = '#/inventaire' } },
                                { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                                { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } },
                                { label: 'Gestion des BC', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/cmdprod' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                                { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Journal des livraisons', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_livraison" } },
                                        { label: 'Situation des bobines', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_bobine" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal factures échues', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journalfactureechu" } }

                            ]
                        },
                        {
                            label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                            items: [

                                { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                                { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                                { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                                { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                            ]
                        }
                    ];
                } else {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                                { label: 'Inventaire', icon: 'fa fa-fw fa-calculator', command: () => { window.location = '#/inventaire' } },
                                { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                                { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Articles et services', icon: 'fa fa-fw fa-cubes', command: () => { window.location = '#/articles_services' } },
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                                { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Journal des livraisons', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_livraison" } },
                                        { label: 'Situation des bobines', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/situation_bobine" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Journal des règlements', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_reglement" } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal factures échues', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journalfactureechu" } }

                            ]
                        },
                        {
                            label: 'Statistiques', icon: 'fa fa-fw fa-line-chart',
                            items: [

                                { label: 'Stat. des ventes', icon: 'fa fa-fw fa-line-chart', command: () => { window.location = "#/statistiques/stat_vente" } },
                                { label: 'Stat. des dépenses', icon: 'fa fa-fw fa-pie-chart', command: () => { window.location = "#/statistiques/stat_depense" } },
                                { label: 'Ventes par article', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_article" } },
                                { label: 'Ventes par agent', icon: 'fa fa-fw fa-bar-chart', command: () => { window.location = "#/statistiques/stat_vente_agent" } },
                            ]
                        }
                    ];
                }

            } else if (user.profilUser === 'Gérant') {
                if (user.idEtabl.domaineEtabl === 'Pharmacie') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                        { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des commandes', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion commande' } },
                                { label: 'Nouvelle commande', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Nouvelle commande' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Réapprovisionnement', icon: 'fa fa-fw fa-arrow-right', command: () => { window.location = '#/réapprovisionnement' } },
                                { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Journal des livraisons', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_livraison" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance gnle client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande" } },
                            ]
                        }
                    ];

                } else if (user.matrPers.idBoutiq.typeBoutiq === 'Usine') {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                        { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                { label: 'Balance client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                            ]
                        }
                    ];
                } else {
                    this.menu = [
                        { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                        { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                        { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                        { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                        { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                        {
                            label: 'Opération', icon: 'fa fa-fw fa-coffee',
                            items: [
                                { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                                { label: 'Gestion des proformas', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Proforma'; this.forceUpdate() } },
                                { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                                { label: 'Dépenses', icon: 'fa fa-fw fa-credit-card', command: () => { window.location = '#/depenses' } },
                                { label: 'Gestion des retours', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion retour' } },
                                { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                                { label: 'Compte client', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/compte_client' } },
                                { label: 'Mouvement de fonds', icon: 'fa fa-fw fa-exchange', command: () => { window.location = '#/mouvement_fonds' } },

                            ]
                        },
                        {
                            label: 'Paramétrages', icon: 'fa fa-fw fa-cog',
                            items: [
                                { label: 'Clients', icon: 'fa fa-fw fa-users', command: () => { window.location = "#/clients" } },
                                { label: 'Seuils et exceptions', icon: 'fa fa-fw fa-bell', command: () => { window.location = "#/seuils" } },
                            ]
                        },
                        {
                            label: 'Editions', icon: 'fa fa-fw fa-print',
                            items: [
                                {
                                    label: 'Stock', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Inventaire', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/inventaire' } },
                                        { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                                        { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                                        { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },

                                    ]
                                },
                                {
                                    label: 'Balance', icon: 'fa fa-fw fa-print',
                                    items: [
                                        { label: 'Balance client', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_client' } },
                                        { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                                    ]
                                },
                                { label: 'Journal des ventes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_vente" } },
                                { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande" } },
                                { label: 'Journal des soldes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_solde" } },
                                { label: 'Journal factures échues', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journalfactureechu" } }
                            ]
                        }
                    ];
                }
            } else if (user.profilUser === 'Vendeur') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboard' } },
                    { label: 'Gestion vente', icon: 'fa fa-fw fa-list', command: () => { window.location = '#/gestion/Facture' } },
                    { label: 'Nouvelle vente', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/gestion/Nouvelle vente' } },
                    { label: 'Regler vente', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_facture' } },
                    { label: 'Alerte péremption', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alerte" } },
                ];
            } else if (user.profilUser === 'DRH') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardrh' } },
                    { label: 'Gestion du personnel', icon: 'fa fa-fw fa-users', command: () => { window.location = '#/personnel' } },
                    { label: 'Gestion des primes', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/primes' } },
                    { label: 'Gestion des bons', icon: 'fa fa-fw fa-clipboard', command: () => { window.location = "#/bons" } },
                    { label: 'Gestion paie', icon: 'fa fa-fw fa-money', command: () => { window.location = "#/paye" } },
                ];
            } else if (user.profilUser === 'Recouvreur') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardrcv' } },
                    { label: 'Balance générale client', icon: 'fa fa-fw fa-users', command: () => { window.location = '#/editions/balance_client' } },
                    { label: 'Journal des factures échues', icon: 'fa fa-fw fa-calendar', command: () => { window.location = "#/editions/journalfactureechu" } },
                    { label: 'Messagerie', icon: 'fa fa-fw fa-comment', command: () => { window.location = '#/messagerie/rappel' } }
                ];
            } else if (user.profilUser === 'Producteur') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardrcv' } },
                    { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                    { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } },
                    { label: 'Gestion des BC', icon: 'fa fa-fw fa-shopping-cart', command: () => { window.location = '#/cmdprod' } },
                    { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                ];
            } else if (user.profilUser === 'Magasinier') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardgas' } },
                    { label: 'Gestion production', icon: 'fa fa-fw fa-shopping-bag', command: () => { window.location = '#/production' } },
                    { label: 'Gestion arrivage', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/arrivage' } },
                    { label: 'Transfert stock', icon: 'fa fa-fw fa-external-link', command: () => { window.location = '#/transfert' } },
                    { label: 'Inventaire', icon: 'fa fa-fw fa-calculator', command: () => { window.location = '#/inventaire' } },
                    { label: 'Journal des sorties', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_sortie_stock" } },
                    { label: 'Alerte stock', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/alertestock" } },
                ];
            } else if (user.profilUser === 'Appro') {
                this.menu = [
                    { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardappro' } },
                    { label: 'Gestion des commandes', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Gestion commande' } },
                    { label: 'Nouvelle commande', icon: 'fa fa-fw fa-arrow-circle-down', command: () => { window.location = '#/commandes/Nouvelle commande' } },
                    { label: 'Reglement des commandes', icon: 'fa fa-fw fa-briefcase', command: () => { window.location = '#/reglement_commande' } },
                    {
                        label: 'Editions', icon: 'fa fa-fw fa-print',
                        items: [
                            { label: 'Journal des appro.', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_appro" } },
                            { label: 'Balance fournisseur', icon: 'fa fa-fw fa-print', command: () => { window.location = '#/editions/balance_fournisseur' } },
                            { label: 'Journal des commandes', icon: 'fa fa-fw fa-print', command: () => { window.location = "#/editions/journal_commande/par_date" } },
                            { label: 'Alerte commande', icon: 'fa fa-fw fa-bell', command: () => { window.location = '#/editions/journal_commande/par_delai' } },
                        ]
                    },
                ]; 
            }
            if (user.idEtabl.domaineEtabl === 'Alimentation générale' || user.idEtabl.domaineEtabl === 'Restaurant') {
                this.menu.splice(4, 0,
                    { label: 'Restaurant', icon: 'fa fa-fw fa-sitemap', command: () => { window.location = '#/gestion/Restaurant' } },
                );
            }

            if (user.idEtabl.domaineEtabl === 'Pharmacie') {
                this.menu[0]={ label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', command: () => { window.location = '#/dashboardv3' } };
            }

            if (this.menu !== null && this.menu !== undefined && user.idEtabl.domaineEtabl !== 'Pharmacie' && user.profilUser === 'PDG') {
                for (var i = this.menu.length; i--;) {
                    if (this.menu[i].label === 'Editions') {
                        this.menu[i].items[1].items[1].label = 'Journal balance client';
                        this.menu[i].items[1].items.splice(2, 1);
                        break;
                    }
                    if (val_offline == 'false') {
                        if (this.menu[i].label === 'Paramétrages') {
                            this.menu[i].items[1].items.splice(1, 1);
                            break;
                        }
                    }
                }
            }

            if (val_offline == 'false') {
                if (this.menu !== null && this.menu !== undefined && user.idEtabl.domaineEtabl !== 'Restaurant' && user.idEtabl.domaineEtabl !== 'Alimentation générale' && user.profilUser === 'PDG') {
                    for (var i = this.menu.length; i--;) {
                        if (this.menu[i].label === 'Paramétrages') {
                            this.menu[i].items[1].items.splice(1, 1);
                            break;
                        }
                    }
                }
            }



        }
    }

    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-overlay-active': this.state.overlayMenuActive
        });
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar darkTheme={this.state.darkTheme} onThemeChange={this.onThemeChange}
                        topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className='layout-menu-container' onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="layout-menu-content">
                                <div className="layout-menu-title">MENU</div>
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                                <div className="layout-menu-footer">
                                    <div className="layout-menu-footer-title">TASKS</div>

                                    <div className="layout-menu-footer-content">
                                        <ProgressBar value={50} showValue={false}></ProgressBar>
                                        Today
                                        <ProgressBar value={80} showValue={false}></ProgressBar>
                                        Overall
                                    </div>
                                </div>
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-content">
                        <AppBreadCrumbWithRouter />

                        <div className="layout-content-container">

                            <Route exact path="/editions/balance/:typeOp" component={FormEdition_Balance_souscripteur} />
                            <Route exact path="/statistiques/stat_vente_article" component={FormStat_vente_article} />
                            <Route exact path="/statistiques/stat_vente_agent" component={FormStat_vente_agent} />
                            <Route path="/statistiques/stat_vente_client_article" component={FormStat_VenteArticle_Client} />
                            <Route exact path="/statistiques/stat_vente" component={FormStat_vente} />
                            <Route exact path="/statistiques/stat_depense" component={FormStat_depense} />
                            <Route path="/dashboard" exact component={Dashboard} />
                            <Route path="/dashboardrh" exact component={Dashboardrh} />
                            <Route path="/dashboardrcv" exact component={Dashboardrcv} />
                            <Route path="/dashboardgas" exact component={Dashboardgas} />
                            <Route path="/dashboardv3" exact component={DashboardV3} />
                            

                            <Route path="/sample" component={SampleDemo} />
                            <Route path="/Utilisateurs" component={FormUsers} />
                            <Route path="/mouvement_fonds" component={FormEntreeFond} />
                            <Route path="/seuils" component={FormSeuilArticle} />
                            <Route path="/empty" component={EmptyPage} />
                            <Route path="/commandes/:typeOp" component={FormCommande} />
                            <Route path="/formcodif" component={FormCodif} />
                            <Route path="/fournisseurs" component={FormFournisseur} />
                            <Route path="/personnel" component={FormPersonnel} />
                            <Route path="/planif" component={FormPlanifPrix} />
                            <Route path="/fidelisation" component={FormFidelisation} />
                            <Route path="/categorisation" component={FormCategorieFidelisation} />
                            <Route path="/paramprix" component={FormParamPrix} />
                            

                            <Route path="/établissement" component={FormEtabl} />
                            <Route path="/clients" component={FormClient} />
                            <Route path="/boutiques" component={FormBoutique} />
                            <Route path="/réapprovisionnement" component={FormReap} />
                            <Route path="/reglement_commande" component={FormReglCmde} />
                            <Route path="/reglement_facture" component={FormReglSortie} />
                            <Route path="/bons" component={FormBon} />
                            <Route path="/transfert" component={FormTransfert_stock} />
                            <Route path="/messagerie/:typeOp" component={FormMsg} />
                            <Route exact path="/gestion/:typeSortie" component={FormVente} />
                            <Route exact path="/type_article" component={FormTypeArticle} />
                            <Route exact path="/articles_services" component={FormArticle} />
                            <Route exact path="/primes" component={FormPrime} />
                            <Route exact path="/paye" component={FormPaye} />
                            <Route exact path="/inventaire" component={FormInventaire} />
                            <Route exact path="/compte_client" component={FormOperationClient} />
                            <Route exact path="/depenses" component={FormDepense} />
                            <Route exact path="/changer_mot_de_pass" component={FormChpwd} />
                            <Route exact path="/tables" component={FormSalle} />
                            <Route exact path="/editions/operation_client" component={FormEdition_Operationclient} />
                            <Route exact path="/editions/journal_trace" component={FormTrace} />
                            <Route exact path="/editions/balance_fournisseur" component={FormEdition_Balance_four} />
                            <Route exact path="/editions/journal_transfert" component={FormEdition_Journal_trs} />
                            <Route exact path="/editions/journal_reglement" component={FormEdition_reglSortie} />
                            <Route exact path="/editions/journal_sortie_stock" component={FormEdition_Journal_sortie_stock} />
                            <Route exact path="/editions/journal_appro" component={FormEdition_Journal_appro} />
                            <Route exact path="/editions/inventaire" component={FormEdition_Inventaire} />
                            <Route exact path="/editions/journal_vente" component={FormEdition_vente} />
                            <Route exact path="/editions/balance_client" component={FormEdition_Balance_client} />
                            <Route exact path="/editions/journal_solde" component={FormEdition_Solde} />
                            <Route exact path="/editions/journal_commande/:dateCible" component={FormEdition_Commande} />
                            <Route exact path="/editions/alerte" component={FormEdition_AlertePeremption} />
                            <Route exact path="/editions/alertestock" component={FormEdition_AlerteRupture} />

                            <Route exact path="/editions/situationagent" component={FormEdition_situation_agent} />
                            <Route exact path="/editions/journal_livraison" component={FormEdition_Journal_livraison} />
                            <Route exact path="/editions/situation_boutique" component={FormEdition_situation_boutique} />
                            <Route exact path="/editions/journaldette" component={FormEdition_dette} />
                            <Route exact path="/editions/journalfactureechu" component={FormEdition_facturesechues} />
                            <Route exact path="/editions/situationdette" component={FormSituationDette} />



                            


                            <Route exact path="/editions/situation_bobine" component={FormEdition_Situation_bobine} />


                            <Route path="/objectifs" component={FormObjectifVente} />
                            <Route path="/budget" component={FormPrevDepense} />
                            <Route path="/production" component={FormProduction} />
                            <Route path="/arrivage" component={FormArrivage} />
                            <Route path="/cmdprod" component={FormCmdeProduction} />





                        </div>

                        <AppFooter />

                        {this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
