import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import Numeral from 'numeral';
import { SplitButton } from 'primereact/splitbutton';


export class FormDepense extends Component {
    constructor() {
        super();
        this.state = {
            liste_depense: [],
            liste_boutique: [],
            lboutique_rech: [],
            lboutique: [],
            liste_type: [],
            selectedDep: null,
            montant_total: '0',
            iddep: '',
            motifDep: '',
            mtDep: 0,
            ficJoint: 'NA',
            typeDep: null,
            dateDep: new Date(),
            retatDep: null,
            file: null,
            msg: '',
            modif: false,
            visible: false,
            idBoutiq: null,
            ridBoutiq: null,
            pd1: new Date(),
            pd2: new Date(),
            rtypeDep: null,
            liste_etat: [
                { label: 'Etat', value: 'Tous' },
                { label: 'En attente', value: 'En attente' },
                { label: 'Validé', value: 'Validé' },
                { label: 'Annulé', value: 'Annulé' }
            ],
            items: [
                {
                    label: 'Par date validation',
                    icon: 'pi pi-search',
                    command: (e) => {
                        this.get_listeDepense('ETAT');
                    }
                },
                {
                    label: 'Exporter en CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_depense = this.edit_depense.bind(this);
        this.conf_delete_depense = this.conf_delete_depense.bind(this);
        this.enreg_dep = this.enreg_dep.bind(this);
        this.onChange = this.onChange.bind(this)
        this.xulaservice = new Xulaservice();
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    fileUpload(file, namefile) {
        this.xulaservice.upload_file(file, namefile, 'doc').then(data => {
            if (data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_listeDepense('dateDep');
        this.xulaservice.getListe_codif('TYPE_DEPENSE').then(data => {
            this.setState({ liste_type: data });
        });
    }

    get_liste_boutique() {
        let lboutique = null;
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];

        if (this.state.userconnected.profilUser == 'Directeur') {
            this.xulaservice.getListe_boutique_gerer().then(data2 => {
                if (data2 != null && data2 != undefined) {
                    let liste = data2.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    lboutique_rech = lboutique_rech.concat(liste);
                    this.setState({ lboutique_rech: lboutique_rech })
                    this.setState({ lboutique: liste });
                }
            });
        } else {
            this.xulaservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
                if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                    lboutique = this.state.liste_boutique.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                }
                if (this.state.userconnected != null && this.state.userconnected != undefined) {
                    this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } });
                }
                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                    this.setState({ lboutique: lboutique });
                    lboutique_rech = lboutique_rech.concat(lboutique);

                } else {
                    let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                    if (lboutique.length > 0) {
                        let liste = lboutique.filter(function (el) {
                            return el.value === id;
                        });
                        this.setState({ lboutique: liste });
                        lboutique_rech = lboutique_rech.concat(liste);
                    }
                }
                this.setState({ lboutique_rech: lboutique_rech })
            }));
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_listeDepense(rdate_rech) {
        let retat = 'Tous';
        let rtype = 'Tous';
        let rid_boutique = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq;
        }
        if (this.state.retatDep != undefined && this.state.retatDep != null && this.state.retatDep != '') {
            retat = this.state.retatDep;
        }
        if (this.state.rtypeDep != undefined && this.state.rtypeDep != null && this.state.rtypeDep != '') {
            rtype = this.state.rtypeDep;
        }
        this.setState({ montant_total: '0' });
        this.xulaservice.get_liste_depense(spd1, spd2, retat, rtype, rid_boutique, rdate_rech).then(data => {
            this.setState({ liste_depense: data, showindicator: false }, () => {
                if (data != null && data != undefined) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtDep
                    }, initialValue);
                    this.setState({ montant_total: this.formaterMontant(tt) });
                }
            })
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_dep();
            this.get_listeDepense();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_depense(data) {
        if (data != null && data != undefined) {
            if (data.etatDep === 'Validé') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette dépense car déja validée' });
                return;
            }
            if (data.statutSyncDep === 'Envoyé' && this.xulaservice.get_statut_offline() == 'true') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette dépense en mode offline car déja synchronisée' });
                return;
            }
            this.setState({ iddep: data.iddep });
            this.setState({ motifDep: data.motifDep });
            this.setState({ mtDep: data.mtDep });
            this.setState({ typeDep: data.typeDep.idCodif });
            this.setState({ ficJoint: data.ficJoint });
            // this.setState({ idBoutiq: { idBoutiq: data.idBoutiq.idBoutiq, nomBoutiq: data.idBoutiq.nomBoutiq } });

            this.setState({ idBoutiq: data.idBoutiq.idBoutiq });

            this.setState({ dateDep: data.dateDep });
            this.setState({ etatDep: data.etatDep });
            this.setState({ selectedDep: data });
            this.setState({ modif: true });
        }
    }

    annule_dep() {
        this.setState({ iddep: '0' });
        this.setState({ ficJoint: 'NA' });
        this.setState({ motifDep: '' });
        this.setState({ mtDep: 0 });
        this.setState({ dateDep: null });
        this.setState({ selectedDep: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_dep() {
        if (this.state.motifDep === "" || this.state.mtDep === "" || this.state.typeDep === null || this.state.typeDep == undefined || this.state.idBoutiq === "" || this.state.idBoutiq === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let date = moment(new Date()).format('DD/MM/YYYY');
            let nomfic = this.state.ficJoint;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.userconnected.idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            if (this.state.modif == false) {
                let data = {
                    iddep: '0',
                    motifDep: this.state.motifDep,
                    ficJoint: nomfic,
                    mtDep: this.state.mtDep, dateDep: date,
                    typeDep: this.state.typeDep, etatDep: 'En attente',
                    iduDep: { idUser: this.state.userconnected.idUser },
                    idBoutiq: { idBoutiq: this.state.idBoutiq },
                    statutSyncDep: this.xulaservice.get_statut_sync()
                }
                this.xulaservice.enreg_depense(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let data = this.state.selectedDep;
                data.motifDep = this.state.motifDep;
                data.mtDep = this.state.mtDep;
                data.ficJoint = nomfic;
                data.typeDep = { idCodif: this.state.typeDep };
                data.idBoutiq = { idBoutiq: this.state.idBoutiq };
                this.xulaservice.enreg_depense(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_dep();
        }
    }

    update_etat_depense(data,etat) {
        data.etatDep = etat;
        this.xulaservice.valider_depense(data).then(data => this.resultat(data.code, data.contenu));
    }

    actionTemplate(rowData) {
        let disabled = false;
        if (rowData.ficJoint === 'NA' || rowData.ficJoint === '' || rowData.ficJoint === null || rowData.ficJoint === undefined) {
            disabled = true;
        }
        let items = [{
            label: 'Fichier joint',
            icon: 'pi pi-download',
            disabled: disabled,
            command: (e) => {
                if (disabled === true) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun fichier joint à télécharger' });
                } else {
                    this.xulaservice.download_fichier_joint(rowData.ficJoint, 'doc');
                }
            }
        }];

        if(rowData.etatDep === 'Validé') {
            if (this.state.userconnected.profilUser === 'PDG') {
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_depense(rowData);
                    }
                });
                if (rowData.dateEtat === null || rowData.dateEtat === '') {
                    items.push({
                        label: 'Valider',
                        icon: 'pi pi-check',
                        command: (e) => {
                            this.update_etat_depense(rowData,'Validé');
                        }
                    });
                }
            }
        } else if (rowData.etatDep == 'En attente' && this.state.userconnected.profilUser === 'PDG') {
            items.push({
                label: 'Annuler',
                icon: 'pi pi-times',
                command: (e) => {
                    this.update_etat_depense(rowData,'Annulé');
                }
            });
            items.push({
                label: 'Valider',
                icon: 'pi pi-check',
                command: (e) => {
                    this.update_etat_depense(rowData,'Validé');
                }
            });
        } else if (rowData.etatDep == 'En attente' && rowData.iduDep.idUser === this.state.userconnected.idUser) {
            items.push({
                label: 'Annuler',
                icon: 'pi pi-times',
                command: (e) => {
                    this.update_etat_depense(rowData,'Annulé');
                }
            });
            items.push({
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_depense(rowData);
                }
            });
        }else if(rowData.etatDep === 'Annulé') {
            if (this.state.userconnected.profilUser === 'PDG'||this.state.userconnected.profilUser === 'Coordinateur') {
                items.push({
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_depense(rowData);
                    }
                });
            }
        }

        return <div className="p-grid" style={{ width: 90 }}>
            <SplitButton label="" onClick={(e) => this.edit_depense(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
        </div>
    }


    delete_depense() {
        if (this.state.selectedDep == null || this.state.selectedDep == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_depense(this.state.selectedDep).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_depense(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedDep: data });
            this.setState({ msg: ' la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }



    render() {

        let ltype_rech = [{ value: 'Tous', label: 'Type' }, { value: 'Les charges', label: 'Les charges réelles' }];
        let ltype = null;
        if (this.state.liste_type != null && this.state.liste_type != undefined) {
            ltype = this.state.liste_type.map(x => {
                return { value: x.idCodif, label: x.libCodif }
            });
            ltype_rech = ltype_rech.concat(ltype);
        }

        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>

        let header_print = <Button icon='pi pi-print' className="p-button-warning" label='Exporter' style={{ width: '140px', }} onClick={(e) => this.dt.exportCSV()} />

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depense();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '800px' }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="motifDep">Motif dépense *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="motifDep" value={this.state.motifDep} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ motifDep: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="mtDep">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.mtDep} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtDep: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="typeDep">Type dépense *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.typeDep} options={ltype} filter={true} filterBy='label' onChange={(e) => this.setState({ typeDep: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="ficJoint">Joindre un fichier</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="idBoutiq">Boutique *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idBoutiq} options={this.state.lboutique} filter={true} filterBy='label' onChange={(e) => this.setState({ idBoutiq: e.value }, () => {
                                        // alert(this.state.idBoutiq);
                                    })} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_dep()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_dep()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                            <div className="p-grid">
                                <i className="fa fa-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-6 p-md-2">
                                            <Calendar dateFormat="dd/mm/yy" placeholder='Date début' value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Calendar dateFormat="dd/mm/yy" placeholder='Date fin' value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Dropdown autoWidth={false} options={this.state.liste_etat} placeholder="Etat" value={this.state.retatDep} onChange={e => this.setState({ retatDep: e.value })} />
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Dropdown autoWidth={false} placeholder="Type" options={ltype_rech} value={this.state.rtypeDep} onChange={e => this.setState({ rtypeDep: e.value })} filter={true} filterBy='label' filterMatchMode='contains' />
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Dropdown autoWidth={false} options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} onChange={e => this.setState({ ridBoutiq: e.value })} filter={true} filterBy='label' filterMatchMode='contains' />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_listeDepense('DateDep')} icon="pi pi-search" model={this.state.items}></SplitButton>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_depense} selectionMode="single"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedDep: e.data })}
                                responsive={true} selection={this.state.selectedDep} paginatorLeft={header_print} paginatorRight={ptotal} paginatorPosition="top" paginator={true} rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100, 200]} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="dateDep" header="Date" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="motifDep" header="Motif dépense" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtDep" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtDep)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="typeDep.libCodif" header="Type" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="etatDep" body={(rowData, column) => {
                                    let etat = rowData.etatDep;
                                    if (etat === 'Validé') {
                                        etat = etat + ' le ' + rowData.dateEtat;
                                    }
                                    return <div>
                                        <span style={{ color: '#000000' }}>{etat}</span>
                                    </div>
                                }} header="Etat" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idBoutiq.nomBoutiq" header="Boutique" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="statutSyncDep" body={(rowData, column) => {
                                    if (rowData.statutSyncDep == 'Envoyé') {
                                        return <div>
                                            <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                        </div>
                                    } else {
                                        return <div>
                                            <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                        </div>
                                    }
                                }} header="Sync" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />





                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}