import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormSituation_client } from './FormSituation_client';
import { components, createFilter } from 'react-windowed-select';
import WindowedSelect from "react-windowed-select";
import Cleave from 'cleave.js/react';
import { SplitButton } from 'primereact/splitbutton';

export class FormClient extends Component {
    constructor() {
        super();
        this.state = {
            liste_client: [],
            lclient: [],
            selectedClient: null,
            idBoutiq: null,
            idCli_dest: null,
            idCli_situation: null,
            typeCli: { value: 'Particulier', label: 'Particulier' },
            idCli: '0',
            nomCli: '',
            adrCli: '',
            telCli: '',
            emailCli: '',
            numPieceCli: '',
            typePieceCli: { value: 'CNI', label: 'CNI' },
            capturePieceCli: 'NA',
            tvaCli: 0,
            precompteCli: 0,
            photoCli: 'nophoto.png',
            file: null,
            photofile: null,
            visible_situation_client: false,
            visible_dlg_mvt: false,
            remiseCli: 0,
            seuilCreditCli: -1,
            liste_type_cli: [
                { label: 'Default', value: 'Default' },
                { label: 'Particulier', value: 'Particulier' },
                { label: 'Client garanti', value: 'Client garanti' },
                { label: 'Assurance', value: 'Assurance' },
                { label: 'Organisation', value: 'Organisation' },
                { label: 'Administration publique', value: 'Administration publique' }
            ],

            liste_type_piece: [
                { label: 'Garanti', value: 'Garanti' },
                { label: 'CNI', value: 'CNI' },
                { label: 'Passeport', value: 'Passeport' },
                { label: 'Carte electeur', value: 'Carte electeur' },
                { label: 'Carte militaire', value: 'Carte militaire' },
                { label: 'RCCM', value: 'RCCM' },
                { label: 'Inconnu', value: 'Inconnu' },
            ],
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_client = this.edit_client.bind(this);
        this.conf_delete_client = this.conf_delete_client.bind(this);
        this.onChange = this.onChange.bind(this)
        this.show_situation_client_dlg = this.show_situation_client_dlg.bind(this);
        this.xulaservice = new Xulaservice();
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }


    show_situation_client_dlg(x) {
        let rowData = { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli }
        this.setState({ idCli_situation: rowData }, () => {
            if (this.state.idCli_situation != null && this.state.idCli_situation != undefined) {
                if (this.state.idCli_situation.value != 'Tous') {
                    this.setState({ visible_situation_client: true });
                } else {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
                }
            } else {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un client' });
            }
        });
    }

    fileUpload(file, namefile, destination) {
        this.xulaservice.upload_file(file, namefile, destination).then(data => {
            if (data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }


    componentDidMount() {
        this.setState({ showindicator: true });
        this.xulaservice.getliste_client_light('Tous').then(data => {
            this.setState({ liste_client: data, showindicator: false }, () => {
                let lclient = this.state.liste_client.map(x => {
                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, typeCli: x.typeCli, label_rech: x.nomCli + ' ' + x.idCli, precompteCli: x.precompteCli }
                });
                this.setState({ lclient: lclient });
            });

        });
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_client();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_client(data) {
        if (data != null && data != undefined) {
            this.setState({ idCli: data.idCli });
            this.setState({ nomCli: data.nomCli });
            this.setState({ adrCli: data.adrCli });
            this.setState({ modif: true });
            this.setState({ emailCli: data.emailCli });
            this.setState({ telCli: data.telCli });
            this.setState({ numPieceCli: data.numPieceCli });
            this.setState({ typeCli: { value: data.typeCli, label: data.typeCli } });
            this.setState({ typePieceCli: { value: data.typePieceCli, label: data.typePieceCli } });
            this.setState({ capturePieceCli: data.capturePieceCli });
            this.setState({ remiseCli: data.remiseCli });
            this.setState({ tvaCli: data.tvaCli });
            this.setState({ precompteCli: data.precompteCli });
            this.setState({ seuilCreditCli: data.seuilCreditCli });
            this.setState({ selectedClient: data });
            window.scrollTo(0, 0);
        }
    }

    annule_client() {
        this.setState({ nomCli: '' });
        this.setState({ adrCli: '' });
        this.setState({ modif: false });
        this.setState({ emailCli: '' });
        this.setState({ telCli: '' });
        this.setState({ numPieceCli: '' });
        this.setState({ capturePieceCli: 'NA' });
        this.setState({ photoCli: 'nophoto.png' });
        this.setState({ remiseCli: 0,seuilCreditCli: -1 });
        this.setState({ tvaCli: 0, precompteCli: 0 });
        window.scrollTo(0, 0);
        this.forceUpdate();
    }

    enreg_client() {
        if (this.state.nomCli === "" || this.state.nomCli === undefined || this.state.nomCli === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let nomfic = this.state.capturePieceCli;
            let upload = false;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.userconnected.idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }

            let uploadPhoto = false;
            let nomPhoto = this.state.photoCli;
            if (this.state.photofile != null && this.state.photofile != undefined) {
                let name = this.state.photofile.name;
                nomPhoto = this.state.userconnected.idUser + 'PC' + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadPhoto = true;
            }

            if (this.state.modif == false) {
                let data = { idCli: '0', telCli: this.state.telCli, statutSyncCli: this.xulaservice.get_statut_sync(), capturePieceCli: nomfic, photoCli: nomPhoto, nomCli: this.state.nomCli, adrCli: this.state.adrCli, typeCli: this.state.typeCli.value, typePieceCli: this.state.typePieceCli.value, emailCli: this.state.emailCli, remiseCli: this.state.remiseCli, tvaCli: this.state.tvaCli, precompteCli: this.state.precompteCli, numPieceCli: this.state.numPieceCli, idBoutiq: this.state.userconnected.matrPers.idBoutiq,seuilCreditCli: this.state.seuilCreditCli };
                this.xulaservice.enreg_client(data).then(data => {
                    this.resultat(data.code, data.contenu);
                    if (upload === true) {
                        this.fileUpload(this.state.file, nomfic, 'doc');
                    }
                    if (uploadPhoto === true) {
                        this.fileUpload(this.state.photofile, nomPhoto, 'photos');
                    }
                });
            } else {
                let data = this.state.selectedClient;
                data.telCli = this.state.telCli;
                data.nomCli = this.state.nomCli;
                data.adrCli = this.state.adrCli;
                data.emailCli = this.state.emailCli;
                data.photoCli = nomPhoto;
                data.remiseCli = this.state.remiseCli;
                data.precompteCli = this.state.precompteCli;
                data.numPieceCli = this.state.numPieceCli;
                data.typeCli = this.state.typeCli.value;
                data.typePieceCli = this.state.typePieceCli.value;
                data.tvaCli = this.state.tvaCli;
                data.capturePieceCli = nomfic;
                data.statutSyncCli = this.xulaservice.get_statut_sync();
                data.seuilCreditCli=this.state.seuilCreditCli;
                this.xulaservice.enreg_client(data).then(data => {

                    if (upload === true) {
                        this.fileUpload(this.state.file, nomfic, 'doc');
                    }
                    if (uploadPhoto === true) {
                        this.fileUpload(this.state.photofile, nomPhoto, 'photos');
                    }
                    this.resultat(data.code, data.contenu);
                });
            }
        }
    }

    mv_client() {
        this.setState({ showindicator: true });
        this.xulaservice.mv_client(this.state.selectedClient.idCli, this.state.idCli_dest.value).then(data => {
            this.resultat(data.code, data.contenu);
            this.setState({ visible_dlg_mvt: false });
        });
    }

    show_dlg_mvt(rowData) {
        this.setState({ visible_dlg_mvt: true });
    }


    actionTemplate(rowData, column) {
        let disable = false;
        let disable2 = false;
        if (this.xulaservice.get_statut_offline() === 'true') {
            disable2 = true;
        }
        if (rowData.typeCli === 'Default' || rowData.typeCli === 'Client interne') {
            disable = true;
        }
        let items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_client(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_client(rowData);
                }
            }, 
            {
                label: 'Détail balance',
                icon: 'pi pi-search',
                command: (e) => {
                    this.show_situation_client_dlg(rowData);
                }
            }, 
            {
                label: 'Transférer compte',
                icon: 'pi pi-arrow-right',
                command: (e) => {
                    this.show_dlg_mvt(rowData);
                }
            }
        ]
        if (rowData.capturePieceCli !== 'NA') {
            items.push(
                {
                    label: 'Télécharger dossier',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.xulaservice.download_fichier_joint(rowData.capturePieceCli, 'doc');
                    }
                }
            );
        }
        
        if(disable==true){
            for (var i = items.length; i--;) {
                if (items[i].label === 'Supprimer' || items[i].label === 'Modifier' || items[i].label === 'Transférer compte') {
                    items.splice(i, 1);
                }
            }
        }
        /* return <div className="p-grid" style={{ width: 150 }}>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.edit_client(rowData)} className="p-button-info" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.conf_delete_client(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.show_situation_client_dlg(rowData)} className="p-button-success" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-search" /></div>
            <div className="p-col"><Button disabled={disable2} onClick={() => this.show_dlg_mvt(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -10 }} icon="fa fa-fw fa-exchange" /></div>
        </div> */

        return  <SplitButton label="Modifier" disabled={disable}  onClick={(e) => this.edit_client(rowData)} icon="pi pi-pencil" model={items}/>
    }


    delete_client() {
        if (this.state.selectedClient == null || this.state.selectedClient == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_client(this.state.selectedClient).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_client(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedClient: data });
            this.setState({ msg: data.nomCli });
            this.setState({ visible: true });
        }
    }


    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_client();
        }
    }



    render() {

        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.idEtabl.domaineEtabl === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }
        const selectStyles = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                width: 240,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 600,
            }),
        };

        const selectStyles_client = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 400,
            }),
        };

        const customFilter = createFilter({ ignoreAccents: false });


        const formatOptionLabel_client = ({ value, label, soldeCli_format, remiseCli }) => (
            <div className="p-grid">
                <div className="p-col-9">
                    <label>{label}</label>
                </div>
                <div className="p-col-3">
                    <label style={{ fontWeight: 'bold' }}>{soldeCli_format}</label>
                </div>
            </div>
        );



        const dialogFooter_situation = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_situation_client: false, visible_dlg_reglement: false, visible_dlg_depot: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_client();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Mouvement client" visible={this.state.visible_dlg_mvt} style={{ width: '30%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_mvt: false })}>
                    <div className="p-grid" >
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ marginBottom: '-7px' }}>
                                {this.state.visible_dlg_mvt == true ?
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">client à maintenir *</label>
                                            </span>
                                            <WindowedSelect id="new_dp_client"
                                                ref={input => this.inputElement = input}
                                                formatOptionLabel={formatOptionLabel_client}
                                                isClearable={false}
                                                filterOption={customFilter}
                                                options={this.state.lclient}
                                                styles={selectStyles_client}
                                                value={this.state.idCli_dest}
                                                placeholder="Client"
                                                onChange={(value) => {
                                                    this.setState({ idCli_dest: value })
                                                }}
                                            />


                                        </div>
                                    </div>
                                    : null}
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => {
                                            this.setState({ visible_dlg_mvt: false });
                                        }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.mv_client()} /></div>
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={false} footer={dialogFooter_situation} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomCli">Nom client *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomCli" onKeyDown={this.handleKeyPress} value={this.state.nomCli} onChange={(e) => this.setState({ nomCli: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Téléphone</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.telCli} onChange={(e) => this.setState({ telCli: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Type client </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_type_cli} value={this.state.typeCli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ typeCli: e.value }) }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Adresse</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.adrCli} onChange={(e) => this.setState({ adrCli: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Email</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.emailCli} onChange={(e) => this.setState({ emailCli: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">TVA *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText onKeyDown={this.handleKeyPress} id="adrCli" value={this.state.tvaCli} onChange={(e) => this.setState({ tvaCli: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Remise *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText keyfilter="pnumber" onKeyDown={this.handleKeyPress} value={this.state.remiseCli} onChange={(e) => this.setState({ remiseCli: e.target.value })} />
                                </div>

                                {/* <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">N° pièce </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.numPieceCli} onChange={(e) => this.setState({ numPieceCli: e.target.value })} />
                                </div> */}

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Type pièce </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_type_piece} value={this.state.typePieceCli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ typePieceCli: e.value }) }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Précompte </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText onKeyDown={this.handleKeyPress} id="precompteCli" value={this.state.precompteCli} onChange={(e) => this.setState({ precompteCli: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="mtDep">Montant seuil de crédit *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.seuilCreditCli} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ seuilCreditCli: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.profilUser!=='PDG' && this.state.userconnected.profilUser!=='Directeur' && this.state.userconnected.profilUser!=='Coordinateur'} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Copie pièces / documents</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={this.onChange} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="photosous">Photo</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={(e) => this.setState({ photofile: e.target.files[0] })} />
                                </div>

                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_client()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_client()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <DataTable value={this.state.liste_client} selectionMode="single"
                                responsive={true} paginatorPosition="top" paginator={true} rows={20}
                                onRowClick={e => this.setState({ selectedClient: e.data })} selection={this.state.selectedClient}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="nomCli" header="Nom client" filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="typeCli" header="Type" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="tvaCli" header="TVA" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                <Column field="remiseCli" header="Remise" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                <Column field="adrCli" header="Adresse" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="seuilCreditCli" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.seuilCreditCli)}</span>
                                    </div>
                                }} header="Seuil crédit" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="telCli" header="Téléphone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '9%' }} />
                                <Column field="emailCli" header="Email" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '17%' }} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}