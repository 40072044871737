import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class FormArrivage extends Component {
    constructor() {
        super();
        this.state = {
            numDet: '',
            poidsDet: 0,
            largeurDet: 0,
            nbDet: '',
            cmtDet: '',
            libArticle: '',
            etatDet: 'En cours',
            qteReap: 0,
            liste_reap: [],
            pd1: new Date(),
            pd2: new Date(),
            ridArticle: null,
            ridBoutiq: null,
            rnum_cmde: null,
            liste_detail_reap: [],
            liste_boutique: [],
            liste_article: [],
            lboutique_rech: [],
            liste_ligne: [],
            selectedReap: null,
            selectedDetailReap: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_new_detail_reap: false,
            visible_dlg_modif_detail_reap: false,
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_detail_reap = this.edit_detail_reap.bind(this);
        this.conf_delete_detail_reap = this.conf_delete_detail_reap.bind(this);
        this.show_new_detail_reap_dlg = this.show_new_detail_reap_dlg.bind(this);
        this.show_modif_reap_dlg = this.show_modif_reap_dlg.bind(this);

        this.nbDetEditor = this.nbDetEditor.bind(this);
        this.largeurDetEditor = this.largeurDetEditor.bind(this);
        this.poidsDetEditor = this.poidsDetEditor.bind(this);
        this.cmtDetEditor = this.cmtDetEditor.bind(this);
        this.numDetEditor = this.numDetEditor.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.myservice = new Xulaservice();
    }

    edit_detail_reap(data) {
        if (data != null && data != undefined) {
            this.setState({ modif: true }, () => {
                this.setState({ numDet: data.numDet });
                this.setState({ poidsDet: data.poidsDet });
                this.setState({ cmtDet: data.cmtDet });
                this.setState({ nbDet: data.nbDet });
                this.setState({ largeurDet: data.largeurDet });
                this.setState({ etatDet: {value: data.etatDet,label: data.etatDet} });
                this.setState({ visible_dlg_modif_detail_reap: true });
                this.setState({ selectedDetailReap: data });
                window.scrollTo(0, 0);
                this.forceUpdate();
            });
        }
    }
    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_reap();
        this.get_liste_article();
    }



    get_liste_reap() {
        let rid_article = 'Tous';
        let rid_boutique = 'Tous';
        let rnum = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined || this.state.ridBoutiq != null) {
            rid_boutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridArticle != undefined || this.state.ridArticle != null) {
            rid_article = this.state.ridArticle.value;
        }

        if (this.state.rnum_cmde != undefined || this.state.rnum_cmde != null) {
            rnum = this.state.rnum_cmde;
        }
        this.myservice.liste_reap_usine(spd1, spd2, rnum, rid_boutique, rid_article).then(data => this.setState({ liste_reap: data, showindicator: false }));
    }

    get_liste_article() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_article_light().then(data => {
            let lprod = null;
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                lprod = data.map(x => {
                    return { value: x.idArticle, label: x.libArticle }
                });
                this.setState({ liste_article: lprod });
            }
        });
    }

    get_liste_boutique() {
        let lboutique = [];
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique != null && this.state.liste_boutique != undefined) {
                lboutique = this.state.liste_boutique.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                lboutique_rech = lboutique_rech.concat(lboutique);
                this.setState({ lboutique_rech: lboutique_rech })
                this.setState({ lboutique: lboutique });
            }
            let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
            if (lboutique.length > 0) {
                let liste = lboutique.filter(function (el) {
                    return el.value === id;
                });
                this.setState({ lboutique_srce: liste });
            }
        }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reap_dlg();
            this.setState({ liste_detail_reap: [] });
            this.get_liste_reap();
            this.get_liste_detail_reap(this.state.selectedReap);
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    annule_reap_dlg() {
        this.setState({ visible_dlg_new_detail_reap: false, visible_dlg_modif_detail_reap: false });
    }

    get_liste_detail_reap(rowData) {
        this.setState({ showindicator: true, liste_detail_reap: [] });
        this.myservice.get_liste_detail_reap(rowData.idReap).then(data => this.setState({ showindicator: false, liste_detail_reap: data }));
    }


    enreg_detail_reap() {
        if (this.state.selectedReap == null || this.state.selectedReap == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif === false) {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.liste_ligne[i].poidsDet == '' || this.state.liste_ligne[i].poidsDet == '0' || this.state.liste_ligne[i].nbDet == '' || this.state.liste_ligne[i].nbDet == '0' || this.state.liste_ligne[i].numDet == '') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veillez renseinger tous les champs obligatoires (poids, metrage, référence)' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_detail_reap_batch(this.state.liste_ligne).then(data => this.resultat(data.code, data.contenu));
            } else {
                if (this.state.poidsDet == '' || this.state.poidsDet == '0' || this.state.nbDet == '' || this.state.nbDet == '0') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                    return;
                }
                let data = this.state.selectedDetailReap;
                data.numDet = this.state.numDet;
                data.poidsDet = this.state.poidsDet;
                data.nbDet = this.state.nbDet;
                data.etatDet=this.state.etatDet.value;
                data.cmtDet = this.state.cmtDet;
                data.largeurDet = this.state.largeurDet;
                this.setState({ showindicator: true });
                this.myservice.enreg_detail_reap(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate_reap(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.show_new_detail_reap_dlg(rowData)} className="p-button-warning" style={{ width: 25, height: 25 }} icon="pi pi-sitemap" /></div>
        </div>
    }

    actionTemplate_new_detail(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.show_new_detail_reap_dlg(rowData)} className="p-button-secondary" style={{ width: 25, height: 25 }} icon="fa fa-fw fa-arrow-right" /></div>
        </div>
    }

    delete_detail_reap() {
        if (this.state.selectedDetailReap == null || this.state.selectedDetailReap == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_detail_reap(this.state.selectedDetailReap).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_detail_reap(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedDetailReap: data });
            this.setState({ msg: data.numDet });
            this.setState({ visible: true });
        }
    }


    show_new_detail_reap_dlg(rowData) {
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        this.setState({ showindicator: true, liste_ligne: [] });
        this.myservice.get_liste_detail_reap(rowData.idReap).then(data => this.setState({ showindicator: false }, () => {
            let nbdet = 0;
            if (data !== null && data !== undefined) {
                nbdet = data.length;
            }
            let nb = rowData.qteReap - nbdet;
            if (nb <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez renseigner les détails de cette ligne' });
                return;
            } else {
                if (this.state.liste_ligne.length > 0) {
                    let rang = this.state.liste_ligne.length + 1;
                    this.state.liste_ligne.insert(rang,
                        {
                            idDet: rang,
                            numDet: '',
                            poidsDet: 0,
                            nbDet: 1,
                            largeurDet: 0,
                            etatDet: 'En cours',
                            cmtDet: '',
                            idReap: this.state.selectedReap,
                            iduser: this.state.userconnected.idUser
                        }
                    );
                } else {
                    let liste = [];
                    for (let i = 0; i < nb; i++) {
                        liste.insert(liste + 1,
                            {
                                idDet: i + 1,
                                numDet: '',
                                poidsDet: 0,
                                nbDet: 0,
                                largeurDet: 0,
                                etatDet: 'En cours',
                                cmtDet: '',
                                idReap: this.state.selectedReap,
                                iduser: this.state.userconnected.idUser
                            }
                        );
                    }
                    this.setState({ liste_ligne: liste });
                }
                this.forceUpdate();
                this.setState({ visible_dlg_new_detail_reap: true });
                this.setState({ modif: false });
            }
        }));


    }

    show_modif_reap_dlg() {
        this.setState({ modif_lcmde: false }, () => {
            this.setState({ visible_dlg_lcmde: true });
            this.setState({ disable_prod: false });
            this.get_liste_article(this.state.selectedCmde.idBoutiq.idBoutiq);
        })
    }


    actionTemplate_detail_reap(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.edit_detail_reap(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_detail_reap(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    nbDetEditor(props) {
        return this.inputNumberEditor(props, 'nbDet');
    }

    largeurDetEditor(props) {
        return this.inputNumberEditor(props, 'largeurDet');
    }

    poidsDetEditor(props) {
        return this.inputNumberEditor(props, 'poidsDet');
    }

    cmtDetEditor(props) {
        return this.inputTextEditor(props, 'cmtDet');
    }

    numDetEditor(props) {
        return this.inputTextEditor(props, 'numDet');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputNumberEditor(props, field) {
        return <InputText keyfilter="pnum" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_detail_reap();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Modification détail arrivage" visible={this.state.visible_dlg_modif_detail_reap} style={{ width: '450px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_modif_detail_reap: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Référence  </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.numDet} onChange={(e) => this.setState({ numDet: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Poids *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.poidsDet} onChange={(e) => this.setState({ poidsDet: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Metrage * </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.nbDet} onChange={(e) => this.setState({ nbDet: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Largeur * </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.largeurDet} onChange={(e) => this.setState({ largeurDet: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Statut *  </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={[{value: 'En cours',label:'En cours'},{value: 'Consommé',label:'Consommé'}]} placeholder="Statut" value={this.state.etatDet} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ etatDet: e.value })} autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Commentaire  </label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.cmtDet} onChange={(e) => this.setState({ cmtDet: e.target.value })} />
                                    </div>
                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_modif_detail_reap: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_detail_reap()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Détails arrivage" visible={this.state.visible_dlg_new_detail_reap} style={{ width: '650px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_new_detail_reap: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="300px"
                                    onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                    responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                    editable={true} >
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '8%' }} />
                                    <Column field="numDet" header="Référence" style={{ width: '20%' }} editor={this.numDetEditor} />
                                    <Column field="poidsDet" header="Poids" style={{ width: '15%' }} editor={this.poidsDetEditor} />
                                    <Column field="nbDet" header="Metrage" style={{ width: '15%' }} editor={this.nbDetEditor} />
                                    <Column field="largeurDet" header="Largeur" style={{ width: '15%' }} editor={this.largeurDetEditor} />
                                    <Column field="cmtDet" header="Commentaire" editor={this.cmtDetEditor} />
                                </DataTable>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_new_detail_reap: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_detail_reap()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_article} placeholder="Article" value={this.state.ridArticle} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridArticle: e.value })} autoWidth={false} panelStyle={{ width: '350px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_reap()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 700 }}>
                                                <DataTable value={this.state.liste_reap} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                    onRowClick={e => this.setState({ selectedReap: e.data })} onRowSelect={e => this.setState({ selectedReap: e.data }, () => {
                                                        this.get_liste_detail_reap(this.state.selectedReap);
                                                    })}
                                                    responsive={true} selection={this.state.selectedReap} style={{ width: '100%' }}>
                                                    <Column header="#" body={this.actionTemplate_reap.bind(this)} style={{ width: '4%' }} />
                                                    <Column field="dateReap" header="Date" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="idLcmdeReap.idArticle.libArticle" header="Article" filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="qteReap" header="Qté" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="idLcmdeReap.idCmde.numCmde" header="N° BC" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="idBoutiq.nomBoutiq" header="Boutique" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 700 }}>
                                                <DataTable value={this.state.liste_detail_reap} selectionMode="single" scrollable={true} scrollHeight="550px"
                                                    onRowClick={e => this.setState({ selectedDetailReap: e.data })} onRowSelect={e => this.setState({ selectedDetailReap: e.data })}
                                                    responsive={true} selection={this.state.selectedDetailReap} style={{ width: '100%' }}>
                                                    <Column header="#" body={this.actionTemplate_detail_reap.bind(this)} style={{ width: '20%' }} />
                                                    <Column field="numDet" header="Référence" filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="poidsDet" header="Poids" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="nbDet" header="Metrage" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="largeurDet" header="Largeur" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div >
        );
    }
}