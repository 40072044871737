import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import Numeral from 'numeral';


export class FormCmdeProduction extends Component {
    constructor() {
        super();
        this.state = {
            idProd: null,
            libArticle: '',
            cmtCprod: '',

            idLcprod: 0,
            qteLcprod: 1,
            num_ligne: 0,

            pd1: new Date(),
            pd2: new Date(),
            rstatutProd: null,
            idBoutiq: null,
            ridBoutiq: null,
            idArticle: null,
            liste_production: [],
            liste_cprod: [],
            liste_statut: [
                { label: 'Tout statut', value: 'Tous' },
                { label: 'En attente', value: 'En attente' },
                { label: 'Démarré', value: 'Démarré' },
                { label: 'Terminé', value: 'Terminé' },
            ],
            liste_prod: [],
            liste_lcprod: [],
            lboutique: [],
            liste_article: [],
            liste_ligne: [],
            lprod: [],
            selectedCprod: null,
            selectedLcprod: null,
            selectedLigne: null,
            msg: '',
            modif: false,
            modif_lcprod: false,
            visible: false,
            visible_ligne_cprod: false,
            visible_new_lprod_dlg: false,
            visible_dlg_cprod: false,
            visible_dlg_lprod: false,
            visible_new_cprod_dlg: false,
            showindicator: false,
            disable_new_cprod: true,
            disable_add_cprod: true,
            disable_prod: true,

            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_cprod = this.edit_cprod.bind(this);
        this.conf_delete_cprod = this.conf_delete_cprod.bind(this);

        this.edit_ligne_cprod = this.edit_ligne_cprod.bind(this);
        this.conf_delete_ligne_cprod = this.conf_delete_ligne_cprod.bind(this);

        this.show_new_cprod_dlg = this.show_new_cprod_dlg.bind(this);
        this.show_ligne_cprod_dlg = this.show_ligne_cprod_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);
        this.articleItemTemplate = this.articleItemTemplate.bind(this);

        this.qteLcprodEditor = this.qteLcprodEditor.bind(this);
        this.conf_print_bs = this.conf_print_bs.bind(this);


        this.myservice = new Xulaservice();
    }



    conf_print_bl(data) {
        this.myservice.generer_edition_bl(data.numBl);
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }


    calculer_total(liste) {
        let tt = 0;
        if (liste != null) {
            for (var i = liste.length; i--;) {
                tt += liste[i].montantLtrs;
            }
        }
        this.setState({ total_transfert: this.formaterMontant(tt) });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-7">
                            <label>{option.label}</label>
                        </div>

                        {this.state.userconnected.profilUser === 'PDG' ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_achat_format}</label>
                            </div>
                            : null}

                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }


    qteLcprodEditor(props) {
        return this.inputTextEditor(props, 'qteLcprod');
    }


    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputText keyfilter="pnum" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    componentDidMount() {
        this.get_liste_cprod();
        this.get_liste_article();
    }

    get_liste_cprod() {
        let rid_boutique = 'Tous';
        let rstatut = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq !== undefined && this.state.ridBoutiq !== null) {
            rid_boutique = this.state.ridBoutiq.value;
        }

        if (this.state.rstatutProd !== undefined && this.state.rstatutProd !== null) {
            rstatut = this.state.rstatutProd.value;
        }

        this.setState({ showindicator: true });
        this.myservice.get_liste_cprod(spd1, spd2, rid_boutique, rstatut).then(data => this.setState({ liste_cprod: data, showindicator: false }));
    }

    get_liste_ligne_cprod(idProd) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_ligne_cprod(idProd).then(data => this.setState({ liste_lcprod: data, showindicator: false }));
    }




    get_liste_article() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_article_light().then(data => {
            let lprod = null;
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                lprod = data.map(x => {
                    return { value: x.idArticle, label: x.libArticle }
                });
                this.setState({ liste_article: lprod });
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_cprod();
            this.setState({ liste_lcprod: [] });
            this.get_liste_cprod();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_ligne_cprod(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_ligne_cprod();
            this.setState({ total_transfert: '0' });
            if (this.state.selectedCprod != null && this.state.selectedCprod != undefined) {
                this.get_liste_ligne_cprod(this.state.selectedCprod.idCprod);
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }



    conf_print_bs(data) {
        this.myservice.generer_edition_bs(data.idProd);
    }

    edit_cprod(data) {
        if (data != null && data != undefined) {
            this.setState({ idCprod: data.idCprod });
            this.setState({ cmtCprod: data.cmtCprod });
            this.setState({ modif: true });
            this.setState({ visible_dlg_cprod: true });
            this.setState({ selectedCprod: data });
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }

    edit_ligne_cprod(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_lcprod: true }, () => {
                this.setState({ idLcprod: data.idLcprod });
                this.setState({ qteLcprod: data.qteLcprod });
                this.setState({ visible_dlg_lprod: true });
                this.setState({ disable_prod: true });
                this.setState({ selectedLcprod: data });
                this.setState({ libArticle: data.idArticle.libArticle });
                this.setState({ idArticle: { value: data.idArticle.idArticle, label: data.idArticle.libArticle } });
                window.scrollTo(0, 0);
                this.forceUpdate();
            });
        }
    }

    annule_cprod() {
        this.setState({ idCprod: '0' });
        this.setState({ cmtCprod: '' });
        this.setState({ modif: false });
        this.setState({ visible_dlg_cprod: false });
        this.forceUpdate();
    }

    annule_cprod_dlg() {
        this.annule_cprod();
        this.setState({ liste_ligne: [] });
    }

    annule_ligne_cprod() {
        this.setState({ idLcprod: '0' });
        this.setState({ qteLcprod: 0 });
        this.setState({ nbLprod: 0 });
        if (this.state.modif_lcprod === true) {
            this.setState({ visible_dlg_lprod: false });
        }
        this.setState({ modif_lcprod: false });
        this.setState({ liste_ligne: [] });

        this.forceUpdate();
    }



    enreg_cprod() {
        if (this.state.modif == false) {
            if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                return;
            } else if (this.state.liste_ligne.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                return;
            }
            let data = { idCprod: '0', cmtCprod: this.state.cmtCprod,statutCprod:"En attente", idBoutiq: this.state.userconnected.matrPers.idBoutiq, iduser: this.state.userconnected.idUser };

            for (var i = this.state.liste_ligne.length; i--;) {
                this.state.liste_ligne[i].idCprod = data;
                this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
            }
            this.setState({ showindicator: true });
            this.myservice.enreg_ligne_cprod_batch(this.state.liste_ligne).then(data => {
                if (data != null && data != undefined) {
                    this.resultat_ligne_cprod(data.code, data.contenu);
                }
            });

        } else {
            let data = this.state.selectedCprod;
            data.cmtCprod = this.state.cmtCprod;
            this.setState({ showindicator: true });
            this.myservice.enreg_cprod(data).then(data => this.resultat(data.code, data.contenu));
        }
    }


    enreg_ligne_cprod() {
        if (this.state.selectedCprod == null || this.state.selectedCprod == undefined ||  this.state.qteLcprod == '' || this.state.qteLcprod == undefined || this.state.idArticle == null || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_lcprod === false) {
                let data = { idLcprod: '0', qteLcprod: this.state.qteLcprod, idCprod: { idCprod: this.state.selectedCprod.idCprod }, idArticle: { idArticle: this.state.idArticle.value } };
                for (var i = this.state.liste_lcprod.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_lcprod[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce article existe déja dans la liste sélectionnée, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_ligne_cprod(data).then(data => this.resultat_ligne_cprod(data.code, data.contenu));
            } else {
                let data = this.state.selectedLcprod;
                data.qteLcprod = this.state.qteLcprod;
                this.setState({ showindicator: true });
                this.myservice.enreg_ligne_cprod(data).then(data => this.resultat_ligne_cprod(data.code, data.contenu));
            }
        }
    }



    conf_delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.calculer_total(this.state.liste_ligne);
        this.forceUpdate();
    }

    add_article() {

        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        let trouve = false;

        if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un article' });
        } else if (this.state.qteLcprod == null || this.state.qteLcprod == '' || this.state.qteLcprod == 0 || this.state.qteLcprod == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
        } else {
            this.setState({ num_ligne: this.state.num_ligne + 1 });
            if (this.state.liste_ligne.length == 0) {
                this.state.liste_ligne.insert(this.state.num_ligne, { idLcprod: '0', qteLcprod: this.state.qteLcprod, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
            } else {
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                        trouve = true;
                        this.state.liste_ligne[i].qteLcprod = parseFloat(this.state.liste_ligne[i].qteLcprod) + parseFloat(this.state.qteLcprod);
                    }
                }
                if (trouve == false) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLcprod: '0', qteLcprod: this.state.qteLcprod, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label } });
                }
            }
            this.forceUpdate();
        }
        this.setState({ disable_val_production: false });
        this.calculer_total(this.state.liste_ligne);
    }


    actionTemplate(rowData, column) {
        let disable_button = false;
        let items = [];

        items.push({
            label: 'Modifier',
            icon: 'pi pi-pencil',
            command: (e) => {
                this.edit_cprod(rowData);
            }
        });
        items.push({
            label: 'Supprimer',
            icon: 'pi pi-times',
            command: (e) => {
                this.conf_delete_cprod(rowData);
            }
        });
        if (rowData.statutCprod == 'Démarré') {
            disable_button = true;
        }

        return <div>
            <SplitButton label='...' onClick={(e) => {
                if (disable_button === false) {
                    this.edit_cprod(rowData);
                } else {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette commande car la production a déja démarré' });
                }
            }} icon="pi pi-pencil" model={items}></SplitButton>
        </div>

    }

    actionTemplate_ligne_production(rowData, column) {
        let disable_button = true;
        if (this.state.selectedCprod.statutCprod === 'En attente') {
            disable_button = false;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_ligne_cprod(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_ligne_cprod(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -5 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_cprod() {
        if (this.state.selectedCprod == null || this.state.selectedCprod == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_cprod(this.state.selectedCprod).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_ligne_cprod() {
        if (this.state.selectedLcprod == null || this.state.selectedLcprod == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_ligne_cprod(this.state.selectedLcprod).then(data => this.resultat_ligne_cprod(data.code, data.contenu));
        }
    }


    conf_delete_cprod(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedCprod: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    conf_delete_ligne_cprod(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLcprod: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_ligne_cprod: true });
        }
    }


    show_new_cprod_dlg() {
        this.setState({ visible_new_cprod_dlg: true });
        this.setState({ modif: false });
    }

    show_ligne_cprod_dlg() {
        this.setState({ visible_dlg_lprod: true });
        this.setState({ modif_lcprod: false });
        this.setState({ disable_prod: false });
    }


    render() {
        let disable_add_cprod=true;
        if((this.state.userconnected.profilUser=='PDG'||this.state.userconnected.profilUser=='Administrateur'||this.state.userconnected.profilUser=='Coordinateur')&&this.state.selectedCprod!==null){
            if(this.state.selectedCprod.statutCprod=='En attente'){
                disable_add_cprod=false;
            }
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_cprod();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_ligne_cprod = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_ligne_cprod: false });
                    this.delete_ligne_cprod();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_ligne_cprod: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        const header_production = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouveau BC" style={{ width: 120 }} onClick={this.show_new_cprod_dlg}></Button>
        </div>;

        const header_larv = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={disable_add_cprod} icon="pi pi-plus" iconPos="left" label="Ajouter un article" style={{ width: 200 }} onClick={this.show_ligne_cprod_dlg}></Button>
        </div>;


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_ligne_cprod} style={{ width: '250px' }} modal={false} footer={dialogFooter_ligne_cprod} onHide={(e) => this.setState({ visible_ligne_cprod: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <Dialog header="Nouvelle commande" visible={this.state.visible_new_cprod_dlg} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_new_cprod_dlg: false })}>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Panel style={{ minHeight: '400px' }} header="Ajout d'article">
                                        <div className="p-grid">

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article *</label>
                                                </span>
                                                <div className="p-inputgroup">
                                                    <Dropdown options={this.state.liste_article} placeholder="Articles" value={this.state.idArticle} panelStyle={{ width: '400px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value }, () => {
                                                                this.setState({ nbLprod: 0 });
                                                            })
                                                        }
                                                        } autoWidth={false} style={{ width: '100%' }} />
                                                </div>
                                            </div>

                                            <div className="p-col-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Qauntité *</label>
                                                </span>
                                                <InputText value={this.state.qteLcprod} onChange={(e) => this.setState({ qteLcprod: e.target.value })} />
                                            </div>

                                        </div>
                                        <br />
                                        <center>
                                            <Button label="Ajouter >>" style={{ width: '170px' }} icon="pi pi-plus" onClick={(e) => this.add_article()} />
                                        </center>
                                    </Panel>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid">
                                <div className="p-col-12">

                                    <Panel style={{ minHeight: '280px' }}>
                                        <div className="p-grid">
                                            <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="300px"
                                                onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                                responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }}
                                                editable={true} >
                                                <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '6%' }} />
                                                <Column field="idArticle.libArticle" header="Produit" />
                                                <Column field="qteLcprod" header="Qté" style={{ width: '10%' }} editor={this.qteLcprodEditor} />
                                            </DataTable>
                                        </div>
                                    </Panel>
                                    <div className="card card-w-title" style={{ marginBottom: '10px' }}>
                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-12">
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Commentaire </label>
                                                </span>
                                                <InputText value={this.state.cmtCprod} onChange={(e) => this.setState({ cmtCprod: e.target.value })} />
                                            </div>



                                        </div>

                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cprod_dlg()} /></div>
                                                <div className="p-col"><Button disabled={this.state.liste_ligne.length <= 0} label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_cprod()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Modification commande" visible={this.state.visible_dlg_cprod} style={{ width: '500px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_cprod: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label >Commentaire</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <InputText value={this.state.cmtCprod} disabled={this.state.disable_cmt} onChange={(e) => this.setState({ cmtCprod: e.target.value })} />
                                    </div>

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_cprod()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_cprod()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog header="Modification détail commande" visible={this.state.visible_dlg_lprod} style={{ width: '570px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_lprod: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Article *</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <div className="p-inputgroup">
                                            <Dropdown options={this.state.liste_article} value={this.state.idArticle} panelStyle={{ width: '300px' }} optionLabel='label' filter={true} filterBy='label' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                onChange={(e) => {
                                                    this.setState({ idArticle: e.value })
                                                }
                                                } autoWidth={false} />
                                        </div>

                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label htmlFor="profile">Qauntité  *</label>
                                    </div>

                                    <div className="p-col-12 p-md-9">
                                        <InputText keyfilter="pnum" value={this.state.qteLcprod} onChange={(e) => this.setState({ qteLcprod: e.target.value })} />
                                    </div>


                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lprod: false }) }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_ligne_cprod()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>



                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <Dropdown options={this.state.liste_statut} placeholder="Statut" value={this.state.rstatutProd} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rstatutProd: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_cprod()} style={{ width: 170 }} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-lg-7">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <ScrollPanel style={{ width: '100%', height: '590px' }}>
                                                    <DataTable value={this.state.liste_cprod} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                                        onRowClick={e => this.setState({ selectedCprod: e.data })} onRowSelect={e =>
                                                            this.setState({ selectedCprod: e.data }, () => {
                                                                this.get_liste_ligne_cprod(this.state.selectedCprod.idCprod);
                                                                if (this.state.selectedCprod.statutProd == 'En attente' && (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Producteur' || this.state.userconnected.profilUser === 'Coordinateur' || this.state.userconnected.profilUser === 'Administrateur')) {
                                                                    this.setState({ disable_add_cprod: false });
                                                                } else {
                                                                    this.setState({ disable_add_cprod: true });
                                                                }
                                                            })
                                                        }
                                                        header={header_production} style={{ width: '100%' }}
                                                        responsive={true} selection={this.state.selectedCprod} >
                                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '12%', overflow: 'visible' }} />
                                                        <Column field="idCprod" header="N° BC" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="dateCprod" header="Date" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="statutCprod" header="Statut" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                        <Column field="cmtCprod" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} />

                                                    </DataTable>
                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-5">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 600 }}>
                                                <DataTable value={this.state.liste_lcprod} selectionMode="single" scrollable={true} scrollHeight="460px"
                                                    onRowClick={e => this.setState({ selectedLcprod: e.data })} onRowSelect={e => this.setState({ selectedLcprod: e.data })}
                                                    responsive={true} selection={this.state.selectedLcprod} header={header_larv} >
                                                    <Column header="#" body={this.actionTemplate_ligne_production.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="idArticle.libArticle" header="Article" style={{ width: '43%' }} />
                                                    <Column field="qteLcprod" header="Qté" style={{ width: '12%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}